import { Checkbox, Table } from '@pankod/refine-antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React from 'react'
import {
  AccountUserLicense,
  AccountUserLicenseTableColumnData,
  AccountUserLicenseTableData,
  CheckStateByAccountUser,
  checkStateBySponsor,
} from 'types'

type AccountUserLicenseMatrixProps = {
  checkedLicenses: any
  updateCheckedLicenses: (licenses: AccountUserLicense[]) => void
  checkedAllByAccount: CheckStateByAccountUser[]
  checkedAll: boolean
  updateCheckedAll: (v: boolean) => void
  updateCheckedAllByAccount: (rows: any) => void
  checkedAllBySponsor: checkStateBySponsor[]
  updateCheckedAllBySponsor: (rows: any) => void
  tableColumnData: AccountUserLicenseTableColumnData[]
  tableData: AccountUserLicenseTableData[]
}

export const AccountUserLicenseMatrix: React.FC<AccountUserLicenseMatrixProps> = (props) => {
  const {
    checkedLicenses,
    updateCheckedLicenses,
    tableData,
    tableColumnData,
    checkedAll,
    updateCheckedAll,
    updateCheckedAllByAccount,
    checkedAllByAccount,
    updateCheckedAllBySponsor,
    checkedAllBySponsor,
  } = props

  if (checkedAllBySponsor.length === 0 || checkedAllByAccount.length === 0) return <></>
  /**
   * 権限マトリクスのチェックボックスが押下された際にステートを更新する
   * @param checked チェック後の真偽値
   * @param accountUserId チェック対象のアカウントユーザID
   * @param index チェック対象の広告主インデックス
   */
  const onChangeLicense = (checked: boolean, accountUserId: string, index: number) => {
    const newLicenses = checkedLicenses?.map((record: AccountUserLicense) => {
      let new_record: AccountUserLicense = JSON.parse(JSON.stringify(record))
      if (record.accountUserId === accountUserId) {
        new_record.licenses[index].checked = checked
      }
      return new_record
    })
    if (newLicenses) updateCheckedLicenses(newLicenses)
  }

  /**
   * アカウントごとの「全て」チェックボックスが押下された際にステートを更新する
   * @param checked チェック後の真偽値
   * @param accountUserId チェック対象のアカウントユーザID
   */
  const onChangeAllLicensesByAccount = (checked: boolean, accountUserId: string) => {
    const newCheckedAllByAccount = checkedAllByAccount.map((record: any) => {
      if (record.accountUserId === accountUserId) {
        return { accountUserId: record.accountUserId, checked: checked }
      }
      return record
    })
    updateCheckedAllByAccount(newCheckedAllByAccount)

    const newLicenses = checkedLicenses?.map((record: AccountUserLicense) => {
      let new_record: AccountUserLicense = JSON.parse(JSON.stringify(record))
      if (record.accountUserId === accountUserId) {
        if (checked) {
          const new_licenses = new_record.licenses.map((item: any) => {
            return { sponsorId: item.sponsorId, checked: checked }
          })
          new_record.licenses = new_licenses
        } else {
          const new_licenses = new_record.licenses.map((item: any) => {
            const checkedAll = checkedAllBySponsor.filter((v: any) => v.sponsorId === item.sponsorId)[0].checked
            if (!checkedAll) {
              return { sponsorId: item.sponsorId, checked: checked }
            }
            return item
          })
          new_record.licenses = new_licenses
        }
      }
      return new_record
    })
    if (newLicenses) updateCheckedLicenses(newLicenses)
  }

  /**
   * 広告主ごとの「全て」チェックボックスが押下された際にステートを更新する
   * @param checked チェック後の真偽値
   * @param sponsorId チェック対象の広告主ID
   */
  const onChangeLicenseAllBySponsor = (checked: boolean, sponsorId: string) => {
    const newCheckedAllBySponsor = checkedAllBySponsor.map((row: any) => {
      if (row.sponsorId === sponsorId) {
        row.checked = checked
      }
      return row
    })
    updateCheckedAllBySponsor(newCheckedAllBySponsor)

    const newLicenses = checkedLicenses?.map((record: AccountUserLicense) => {
      let new_record: AccountUserLicense = JSON.parse(JSON.stringify(record))
      if (checked) {
        record.licenses.forEach((state, index) => {
          if (state.sponsorId === sponsorId) {
            new_record.licenses[index].checked = checked
          }
        })
      } else {
        record.licenses.forEach((state, index) => {
          if (state.sponsorId === sponsorId) {
            const checkedAll = checkedAllByAccount.filter((v: any) => v.accountUserId === record.accountUserId)[0]
              .checked
            if (!checkedAll) {
              new_record.licenses[index].checked = checked
            }
          }
        })
      }
      return new_record
    })
    if (newLicenses) updateCheckedLicenses(newLicenses)
  }

  /**
   * 縦×横全体に対しての「全て」チェックボックスが押下された際にステートを更新する
   * @param checked チェック後の真偽値
   */
  const onChangeLicenseAll = (checked: boolean) => {
    const newCheckedAllBySponsor = checkedAllBySponsor.map((row: any) => {
      row.checked = checked
      return row
    })
    updateCheckedAllBySponsor(newCheckedAllBySponsor)

    const newCheckedAllByAccount = checkedAllByAccount.map((row: any) => {
      row.checked = checked
      return row
    })
    updateCheckedAllByAccount(newCheckedAllByAccount)

    const newLicenses = checkedLicenses?.map((record: AccountUserLicense) => {
      let new_record: AccountUserLicense = JSON.parse(JSON.stringify(record))
      record.licenses.forEach((_, index) => {
        new_record.licenses[index].checked = checked
      })
      return new_record
    })
    if (newLicenses) updateCheckedLicenses(newLicenses)
  }

  const isCheckedSponsorLicense = (accountUserId: string, index: number) => {
    const exists = checkedLicenses?.filter((record: any) => record.accountUserId === accountUserId)
    if (exists.length === 0) return false
    return exists[0].licenses[index].checked
  }

  const isCheckedAllByAccount = (accountUserId: string) => {
    const exists = checkedAllByAccount.filter((record: any) => record.accountUserId === accountUserId)
    if (exists.length === 0) return false
    return exists[0].checked
  }

  const isCheckedAllBySponsor = (sponsorId: string) => {
    const exists = checkedAllBySponsor.filter((record: any) => record.sponsorId === sponsorId)
    if (exists.length === 0) return false
    return exists[0].checked
  }

  const isDisabledRow = (accountUserId: string, sponsorId: string) => {
    const existsCheckedAllBySponsor = checkedAllBySponsor.filter((record: any) => record.sponsorId === sponsorId)
    const existsCheckedAllByAccount = checkedAllByAccount.filter(
      (record: any) => record.accountUserId === accountUserId,
    )
    if (existsCheckedAllBySponsor.length === 0 || existsCheckedAllByAccount.length === 0) return false
    const allCheckedBySponsor = existsCheckedAllBySponsor[0].checked
    const allCheckedByAccount = existsCheckedAllByAccount[0].checked
    return allCheckedByAccount || allCheckedBySponsor
  }

  const sponsorColumns = tableColumnData.map((sponsor, index) => {
    return {
      title: (
        <>
          {sponsor.sponsorName}
          <br />
          <Checkbox
            data-testid={'cb-test-allSponsor'}
            key={index}
            checked={isCheckedAllBySponsor(sponsor.id)}
            disabled={checkedAll}
            onChange={(event: CheckboxChangeEvent) => onChangeLicenseAllBySponsor(event.target.checked, sponsor.id)}
          >
            全て
          </Checkbox>
        </>
      ),
      dataIndex: sponsor.id,
      key: sponsor.id,
      render: (_: any, record: { key: React.Key; accountUserId: string }) => (
        <Checkbox
          data-testid={'cb-test-accountUser'}
          key={record.key}
          disabled={isDisabledRow(record.accountUserId, sponsor.id)}
          checked={isCheckedSponsorLicense(record.accountUserId, index)}
          onChange={(event: CheckboxChangeEvent) => {
            onChangeLicense(event.target.checked, record.accountUserId, index)
          }}
        ></Checkbox>
      ),
    }
  })

  const columns = [
    {
      title: 'アカウント',
      dataIndex: 'loginId',
      key: 'loginId',
      render: (_: any, record: { loginId: string }) => {
        return <b>{record.loginId}</b>
      },
    },
    {
      title: (
        <Checkbox
          data-testid={'cb-test-licenseAll'}
          checked={checkedAll}
          onChange={(event: CheckboxChangeEvent) => {
            updateCheckedAll(event.target.checked)
            onChangeLicenseAll(event.target.checked)
          }}
        >
          全て
        </Checkbox>
      ),
      dataIndex: 'all',
      key: 'all',
      render: (_: any, record: { key: React.Key; accountUserId: string }) => (
        <Checkbox
          data-testid={'cb-test-allaccount'}
          key={record.key}
          disabled={checkedAll}
          checked={isCheckedAllByAccount(record.accountUserId)}
          onChange={(event: CheckboxChangeEvent) => {
            onChangeAllLicensesByAccount(event.target.checked, record.accountUserId)
          }}
        >
          全て
        </Checkbox>
      ),
    },
    ...sponsorColumns,
  ]

  return (
    <>
      <p style={{ marginTop: '5px' }}>アカウントごとに利用可能とするキャンペーンの広告主を設定してください</p>
      <Table
        bordered
        pagination={false}
        size="small"
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}
