import { Edit, Form, Input, InputNumber, ListButton, useForm } from '@pankod/refine-antd'
import { IResourceComponentsProps, usePermissions, useNavigation, useRouterContext } from '@pankod/refine-core'
import { IFaqGroup } from 'interfaces'
import React from 'react'
import { FaqEdit } from '../../components/faqs'
import { required, maxLength, frontBlankTrim, rearBlankTrim, pattern } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const FaqGroupEdit: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  const { formProps, saveButtonProps, queryResult } = useForm<IFaqGroup>({
    metaData: {
      fields: ['id', 'faqGroupName', 'displayOrder', { faqs: ['id', 'question', 'answer', 'displayOrder'] }],
    },
  })

  const refetch = () => {
    queryResult?.refetch()
  }

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('faqGroups')
  const record = queryResult?.data?.data

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('FAQ_GROUP') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  return (
    <>
      <Helmet>
        <title>{`FAQ | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`FAQ | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="faqGroups" />,
        }}
      >
        <Form {...formProps} layout="inline">
          <Form.Item
            label="グループ名"
            name="faqGroupName"
            style={{ width: '600px' }}
            rules={[required(), maxLength(200), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={200} showCount placeholder="グループ名" />
          </Form.Item>
          <Form.Item label="表示順" name="displayOrder" rules={[required(), pattern('^[1-9][0-9]*$')]}>
            <InputNumber min={1} max={999999999} maxLength={9} placeholder="表示順" />
          </Form.Item>
        </Form>
      </Edit>
      {record && <FaqEdit faqGroupId={record.id} faqs={record.faqs} refetch={refetch} />}
    </>
  )
}
