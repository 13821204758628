import { ListButton, Create, Form, Input, useForm } from '@pankod/refine-antd'
import { IResourceComponentsProps, usePermissions } from '@pankod/refine-core'
import { IIpAddress } from 'interfaces'
import { required, maxLength, frontBlankTrim, rearBlankTrim, isIpAddressEnabled } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const IpAddressCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IIpAddress>()

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('IP_ADDRESS')) {
    return <ErrorComponent />
  }

  return (
    <>
      <Helmet>
        <title>{`IPアドレス管理 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`IPアドレス管理 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="ipAddresses" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 3, lg: 4, md: 4, sm: 4 }}
          wrapperCol={{ xxl: 8, xl: 10, lg: 20, md: 20, sm: 20 }}
          colon={false}
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item
            label="企業名称"
            name="companyName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder="企業名称" maxLength={100} showCount />
          </Form.Item>
          <Form.Item
            label={'IPアドレス'}
            name={'ipAddress'}
            rules={[
              required(),
              // カスタムバリデーション
              () => ({
                validator(_, value) {
                  if (value === '' || isIpAddressEnabled(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(<span style={{ color: 'red' }}>IPアドレスの形式で入力してください</span>)
                },
              }),
            ]}
          >
            <Input placeholder="xxx.xxx.xxx.xxx" maxLength={20} />
          </Form.Item>
          <Form.Item name="isActive" hidden></Form.Item>
        </Form>
      </Create>
    </>
  )
}
