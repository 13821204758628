import { Create, Form, Input, ListButton, useForm } from '@pankod/refine-antd'
import { IResourceComponentsProps, useList, usePermissions } from '@pankod/refine-core'
import { SelectSponsor } from 'components/form'
import { IBrand } from 'interfaces'
import React, { useState } from 'react'
import { frontBlankTrim, maxLength, pattern, rearBlankTrim, required } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const BrandCreate: React.FC<IResourceComponentsProps> = () => {
  const [selectedSponsorId, setSelectedSponsorId] = useState<string>()
  const [duplicatedBrandCd, setDuplicatedBrandCd] = useState(false)
  const [brandName, setBrandName] = useState('')
  const { form, formProps, saveButtonProps } = useForm<IBrand>()
  const { data: permissions } = usePermissions()
  const brandQueryResult = useList<IBrand>({
    resource: 'brands',
    metaData: {
      fields: ['brandCd'],
      pageSize: 9999999,
    },
  })

  const existsBrandCd = brandQueryResult.data?.data.map((brand) => brand.brandCd)

  if (!permissions?.includes('BRAND')) {
    return <ErrorComponent />
  }

  const onClickSave = () => {
    if (duplicatedBrandCd) {
      return
    }
    form?.submit()
  }

  return (
    <>
      <Helmet>
        <title>{`銘柄 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`銘柄 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={{ ...saveButtonProps, onClick: onClickSave }}
        pageHeaderProps={{
          onBack: () => {
            window.history.length === 1 ? window.close() : window.history.back()
          },
          extra: <ListButton data-testid="show-list-button" resourceName="brands" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 8, xl: 10, lg: 20, md: 20, sm: 20 }}
          colon={false}
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item
            label="銘柄コード"
            name="brandCd"
            validateStatus={duplicatedBrandCd ? 'error' : 'success'}
            help={duplicatedBrandCd ? <span style={{ color: 'red' }}>この銘柄コードは登録済です</span> : null}
            rules={[
              required(),
              pattern(/^[a-zA-Z0-9]{8}$/, '8文字の半角英数字で入力してください'),
              frontBlankTrim(),
              rearBlankTrim(),
            ]}
          >
            <Input
              maxLength={8}
              placeholder="銘柄コード"
              onChange={(e: any) => {
                if (e.target.value !== '' && existsBrandCd?.includes(e.target.value)) {
                  setDuplicatedBrandCd(true)
                } else {
                  setDuplicatedBrandCd(false)
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="銘柄"
            name="brandName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input
              placeholder="銘柄"
              maxLength={100}
              value={brandName}
              onChange={(v: any) => {
                setBrandName(v.target.value)
              }}
              showCount
            />
          </Form.Item>
          <SelectSponsor onChangeSponsor={setSelectedSponsorId} selectedSponsorId={selectedSponsorId} />
          <Form.Item name="isActive" hidden></Form.Item>
        </Form>
      </Create>
    </>
  )
}
