import { Edit, ListButton, Form, Select, Input, useForm, useSelect } from '@pankod/refine-antd'
import { usePermissions, IResourceComponentsProps, useList, useNavigation, useRouterContext } from '@pankod/refine-core'
import { IBrand, ISponsor } from 'interfaces'
import React, { useState } from 'react'
import { required, pattern, maxLength, frontBlankTrim, rearBlankTrim, validatePasteValueLength } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const BrandEdit: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  const [duplicatedBrandCd, setDuplicatedBrandCd] = useState(false)
  const { form, formProps, saveButtonProps, queryResult } = useForm<IBrand>({
    metaData: {
      fields: ['id', 'brandCd', 'brandName', { sponsor: ['id', 'sponsorName'] }],
    },
  })

  const record = queryResult?.data?.data

  const { selectProps: sponsorSelectProps } = useSelect<ISponsor>({
    resource: 'sponsors',
    optionLabel: 'sponsorName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'sponsorName'],
      pageSize: 9999999,
    },
  })

  const brandQueryResult = useList<IBrand>({
    resource: 'brands',
    metaData: {
      fields: ['brandCd'],
      pageSize: 9999999,
    },
  })

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('brands')

  const existsBrandCd = brandQueryResult.data?.data
    .filter((brand) => brand.brandCd !== record?.brandCd)
    .map((brand) => brand.brandCd)

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('BRAND') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  const onClickSave = () => {
    if (duplicatedBrandCd) {
      return
    }
    form?.submit()
  }

  return (
    <>
      <Helmet>
        <title>{`銘柄 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`銘柄 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={{ ...saveButtonProps, onClick: onClickSave }}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="brands" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 8, xl: 10, lg: 18, md: 18, sm: 18 }}
          colon={false}
        >
          <Form.Item
            label="銘柄コード"
            name="brandCd"
            validateStatus={duplicatedBrandCd ? 'error' : 'success'}
            help={duplicatedBrandCd ? <span style={{ color: 'red' }}>この銘柄コードは登録済です</span> : null}
            rules={[
              required(),
              pattern(/^[a-zA-Z0-9]{8}$/, '8文字の半角英数字で入力してください'),
              frontBlankTrim(),
              rearBlankTrim(),
            ]}
          >
            <Input
              maxLength={8}
              placeholder="銘柄コード"
              onChange={(e: any) => {
                if (e.target.value !== '' && existsBrandCd?.includes(e.target.value)) {
                  setDuplicatedBrandCd(true)
                } else {
                  setDuplicatedBrandCd(false)
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="銘柄"
            name="brandName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={100} showCount placeholder="銘柄" />
          </Form.Item>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
            <Form.Item
              label={'広告主'}
              name={'sponsorId'}
              initialValue={record?.sponsor?.id}
              rules={[required('選択してください')]}
            >
              <Select {...sponsorSelectProps} showArrow placeholder={'部分一致/選択'} />
            </Form.Item>
          </div>
        </Form>
      </Edit>
    </>
  )
}
