import datadogLogs from 'logger'
import {
  Icons,
  Row,
  Col,
  Spin,
  Modal,
  Space,
  Button,
  Card,
  Checkbox,
  Form,
  Select,
  Typography,
} from '@pankod/refine-antd'
import { useNotification } from '@pankod/refine-core'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'

const { Option } = Select
const { Text, Title } = Typography

export const Conditions: React.FC<{ selectedCampaignIds: string[] }> = ({ selectedCampaignIds }) => {
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true)
  const [checkedIndexList, setCheckedIndexList] = useState<CheckboxValueType[]>([
    'reach',
    'avg_count',
    'total_count',
    'cpm',
    'cpr',
    'ratio_reach',
    'ratio_total_count',
  ])
  const [checkedIndexTypeList, setCheckedIndexTypeList] = useState<CheckboxValueType[]>([
    'total',
    'tv',
    'yt',
    'tv_incremental',
    'overlap',
    'yt_incremental',
  ])
  const [checkedDeviceList, setCheckedDeviceList] = useState<CheckboxValueType[]>([
    'mobile',
    'desktop',
    'tablet',
    'connected_tv',
    'other',
  ])
  const [checkedEstimatePatternList, setCheckedEstimatePatternList] = useState<CheckboxValueType[]>(['all', 'ctv'])

  const [indeterminateIndex, setIndeterminateIndex] = useState(false)
  const [indeterminateIndexType, setIndeterminateIndexType] = useState(false)
  const [indeterminateDevice, setIndeterminateDevice] = useState(false)
  const [indeterminateEstimatePattern, setIndeterminateEstimatePattern] = useState(false)

  const [checkAllIndex, setCheckAllIndex] = useState(true)
  const [checkAllIndexType, setCheckAllIndexType] = useState(true)
  const [checkAllDevice, setCheckAllDevice] = useState(true)
  const [checkAllEstimatePattern, setCheckAllEstimatePattern] = useState(true)

  const [reachCount, setReachCount] = useState('1')
  const [unit, setSelectedUnit] = useState('pop,ratio')
  const [basePopulation, setBasePopulation] = useState('tv_pop')
  const [coViewing, setCoViewing] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [errors, setErrors] = useState({
    index: false,
    indexType: false,
    device: false,
    estimatePattern: false,
  })
  const cancelled = useRef(false)
  const { open } = useNotification()
  const controller = new AbortController()

  const indexOptions = [
    { label: 'リーチ', value: 'reach' },
    { label: '平均接触回数', value: 'avg_count' },
    { label: '延べ接触人数', value: 'total_count' },
    { label: 'CPM', value: 'cpm' },
    { label: 'CPR', value: 'cpr' },
    { label: 'リーチ構成割合', value: 'ratio_reach' },
    { label: '延べ接触人数構成割合', value: 'ratio_total_count' },
  ]

  const indexTypeOptions = [
    { label: 'トータル', value: 'total' },
    { label: 'TV', value: 'tv' },
    { label: 'YouTube', value: 'yt' },
    { label: 'TVインクリメンタル', value: 'tv_incremental' },
    { label: 'オーバーラップ', value: 'overlap' },
    { label: 'YouTubeインクリメンタル', value: 'yt_incremental' },
  ]

  const deviceOptions = [
    { label: 'モバイル', value: 'mobile' },
    { label: 'デスクトップ', value: 'desktop' },
    { label: 'タブレット', value: 'tablet' },
    { label: 'コネクテッドTV', value: 'connected_tv' },
    { label: 'その他', value: 'other' },
  ]

  const reachCounts = [
    { key: '1', name: '1回以上' },
    { key: '2', name: '2回以上' },
    { key: '3', name: '3回以上' },
    { key: '4', name: '4回以上' },
    { key: '5', name: '5回以上' },
    { key: '6', name: '6回以上' },
    { key: '7', name: '7回以上' },
    { key: '8', name: '8回以上' },
    { key: '9', name: '9回以上' },
    { key: '10', name: '10回以上' },
  ]

  const estimatePatternOptions = [
    { label: 'TV × YouTube（全体）', value: 'all' },
    { label: 'TV × YouTube（CTV）', value: 'ctv' },
  ]

  const onChangeIndex = (list: CheckboxValueType[]) => {
    setCheckedIndexList(list)
    setIndeterminateIndex(!!list.length && list.length < indexOptions.length)
    setCheckAllIndex(list.length === indexOptions.length)
  }

  const onCheckAllChangeIndex = (e: CheckboxChangeEvent) => {
    setCheckedIndexList(e.target.checked ? indexOptions.map((opt) => opt.value) : [])
    setIndeterminateIndex(false)
    setCheckAllIndex(e.target.checked)
  }

  const onChangeIndexType = (list: CheckboxValueType[]) => {
    setCheckedIndexTypeList(list)
    setIndeterminateIndexType(!!list.length && list.length < indexTypeOptions.length)
    setCheckAllIndexType(list.length === indexTypeOptions.length)
  }

  const onCheckAllChangeIndexType = (e: CheckboxChangeEvent) => {
    setCheckedIndexTypeList(e.target.checked ? indexTypeOptions.map((opt) => opt.value) : [])
    setIndeterminateIndexType(false)
    setCheckAllIndexType(e.target.checked)
  }

  const onChangeDevice = (e: CheckboxChangeEvent) => {
    const value = e.target.value
    const isChecked = e.target.checked
    const newDeviceList = isChecked
      ? [...checkedDeviceList, value]
      : checkedDeviceList.filter((device) => device !== value)
    setCheckedDeviceList(newDeviceList)
    setIndeterminateDevice(newDeviceList.length > 0 && newDeviceList.length < deviceOptions.length)
    setCheckAllDevice(newDeviceList.length === deviceOptions.length)
  }

  const onCheckAllChangeDevice = (e: CheckboxChangeEvent) => {
    setCheckedDeviceList(e.target.checked ? deviceOptions.map((opt) => opt.value) : [])
    setIndeterminateDevice(false)
    setCheckAllDevice(e.target.checked)
  }

  const onChangeEstimatePattern = (list: CheckboxValueType[]) => {
    setCheckedEstimatePatternList(list)
    setIndeterminateEstimatePattern(!!list.length && list.length < estimatePatternOptions.length)
    setCheckAllEstimatePattern(list.length === estimatePatternOptions.length)
  }

  const onCheckAllChangeEstimatePattern = (e: CheckboxChangeEvent) => {
    setCheckedEstimatePatternList(e.target.checked ? estimatePatternOptions.map((opt) => opt.value) : [])
    setIndeterminateEstimatePattern(false)
    setCheckAllEstimatePattern(e.target.checked)
  }

  const validateParams = () => {
    let isValid = true
    const defaultErrors = { index: false, indexType: false, device: false, estimatePattern: false }
    setErrors({
      ...defaultErrors,
      index: checkedIndexList.length === 0,
      indexType: checkedIndexTypeList.length === 0,
      device: checkedDeviceList.length === 0,
      estimatePattern: checkedEstimatePatternList.length === 0,
    })
    if (
      !checkedIndexList.length ||
      !checkedIndexTypeList.length ||
      !checkedDeviceList.length ||
      !checkedEstimatePatternList.length
    )
      isValid = false
    return isValid
  }

  const getParams = () => {
    const paramsObj = new URLSearchParams({
      campaign_id: selectedCampaignIds.join(','),
      index: checkedIndexList.join(','),
      index_type: checkedIndexTypeList.join(','),
      reach_count: reachCount,
      unit: unit,
      base_population: basePopulation,
      co_viewing: coViewing.toString(),
      device_type: checkedDeviceList.join(','),
      estimate_pattern: checkedEstimatePatternList.join(','),
    })

    const params = '?' + paramsObj.toString()

    return params
  }

  const LoadingModal = () => {
    return (
      <Modal visible={modalVisible} centered footer={null} closable={false} maskClosable={false}>
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '20px' }}>
          <Space direction="vertical" size={'large'}>
            <Text>ダウンロード準備中...</Text>
            <Spin />
            <Button
              size="small"
              onClick={() => {
                controller.abort()
                cancelled.current = true
                setModalVisible(false)
              }}
            >
              キャンセル
            </Button>
          </Space>
        </div>
      </Modal>
    )
  }

  useEffect(() => {
    if (0 < selectedCampaignIds.length) {
      setIsDownloadButtonDisabled(false)
    } else {
      setIsDownloadButtonDisabled(true)
    }
  }, [selectedCampaignIds])

  const onClickDownload = () => {
    if (selectedCampaignIds.length > 100) {
      open({
        description: '100件を超える指定はできません',
        message: '',
        key: 'maxError',
        type: 'error',
      })
      return
    }
    if (!validateParams()) return
    const today = dayjs().format('YYYYMMDD')
    const accessToken = localStorage.getItem('accessToken')
    const params = getParams()
    setModalVisible(true)
    const url = process.env.REACT_APP_API_ENDPOINT + `export/management_user/totalled_result${params}`
    axios
      .get(url, {
        signal: controller.signal,
      })
      .then((resp) => {
        if (cancelled.current) {
          cancelled.current = false
          return Promise.reject({ response: { message: 'cancelled' } })
        }

        // 始業算出処理中エラー
        if (resp.data?.status === 'processing') throw Error('processing')

        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const blob = new Blob([bom, resp.data], {
          type: resp.data?.type,
        })
        return blob
      })
      .then((blob) => {
        setModalVisible(false)
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `キャンペーン結果_${today}_${basePopulation === 'tv_pop' ? 'TV所有者ベース' : '全人口ベース'}.csv`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        open!({
          message: 'キャンペーン結果をダウンロードしました',
          key: 'exportSuccess',
          type: 'success',
        })
      })
      .catch((error) => {
        setModalVisible(false)
        if (error?.message === 'processing') {
          datadogLogs.logger.info(`[キャンペーンダウンロード] 指標算出処理中`)
          open!({
            message: 'しばらくしてから再度実行してください',
            description: 'データ計算中です',
            key: 'exportError',
            type: 'error',
          })
        } else {
          if (error.response?.status === 401 || error.response?.message !== 'cancelled') {
            datadogLogs.logger.warn(`[キャンペーンダウンロード] ファイル出力失敗`)
            open!({
              message: 'ファイル出力に失敗しました',
              key: 'exportError',
              type: 'error',
            })
          }
        }
      })
  }

  // CTVのデータを公開する日付で推計パターンの表示/非表示の切り分けを行う
  // UTCのミリ秒換算で比較する
  const websensorCtvStartDate = !!process.env.REACT_APP_WEBSENSOR_CTV_START_DATE
    ? new Date(process.env.REACT_APP_WEBSENSOR_CTV_START_DATE).getTime()
    : undefined
  const today = new Date(Date.now()).getTime()

  return (
    <>
      <LoadingModal />
      <Card bodyStyle={{ paddingTop: '15px' }}>
        <div style={{ display: 'flex' }}>
          <Title level={5}>指標選択</Title>
        </div>

        {websensorCtvStartDate !== undefined && websensorCtvStartDate <= today ? (
          <Row style={{ marginBottom: '20px' }}>
            <Col span={6}>
              <div style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
                <Icons.CaretDownOutlined />
                <Text style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '3px' }}>推計パターン</Text>
                {errors.estimatePattern && <span style={{ color: 'red', marginLeft: '10px' }}>選択してください</span>}
              </div>
              <Checkbox
                indeterminate={indeterminateEstimatePattern}
                onChange={onCheckAllChangeEstimatePattern}
                checked={checkAllEstimatePattern}
              >
                すべて選択
              </Checkbox>
              <Checkbox.Group
                options={estimatePatternOptions}
                value={checkedEstimatePatternList}
                onChange={onChangeEstimatePattern}
                style={{ display: 'flex', flexDirection: 'column' }}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row gutter={16}>
          <Col span={6}>
            <div style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
              <Icons.CaretDownOutlined />
              <Text style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '3px' }}>指標</Text>
              {errors.index && <span style={{ color: 'red', marginLeft: '10px' }}>選択してください</span>}
            </div>
            <Checkbox indeterminate={indeterminateIndex} onChange={onCheckAllChangeIndex} checked={checkAllIndex}>
              すべて選択
            </Checkbox>
            <Checkbox.Group
              options={indexOptions}
              value={checkedIndexList}
              onChange={onChangeIndex}
              style={{ display: 'flex', flexDirection: 'column' }}
            />
          </Col>

          <Col span={6}>
            <div style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
              <Icons.CaretDownOutlined />
              <Text style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '3px' }}>TV / YouTube</Text>
              {errors.indexType && <span style={{ color: 'red', marginLeft: '10px' }}>選択してください</span>}
            </div>
            <Checkbox
              indeterminate={indeterminateIndexType}
              onChange={onCheckAllChangeIndexType}
              checked={checkAllIndexType}
            >
              すべて選択
            </Checkbox>
            <Checkbox.Group
              options={indexTypeOptions}
              value={checkedIndexTypeList}
              onChange={onChangeIndexType}
              style={{ display: 'flex', flexDirection: 'column' }}
            />
          </Col>

          <Col span={5}>
            <div style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
              <Icons.CaretDownOutlined />
              <Text style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '3px' }}>デバイス</Text>
              {checkedIndexTypeList.includes('yt') && errors.device && (
                <span style={{ color: 'red', marginLeft: '10px' }}>選択してください</span>
              )}
            </div>
            <Checkbox
              indeterminate={indeterminateDevice}
              onChange={onCheckAllChangeDevice}
              checked={checkAllDevice}
              disabled={!checkedIndexTypeList.includes('yt') || checkedEstimatePatternList[0] === 'ctv'}
            >
              すべて選択
            </Checkbox>
            {deviceOptions.map((option) => (
              <Checkbox
                key={option.value}
                value={option.value}
                checked={checkedDeviceList.includes(option.value)}
                onChange={onChangeDevice}
                disabled={
                  !checkedIndexTypeList.includes('yt') ||
                  (option.value === 'connected_tv' ? false : checkedEstimatePatternList[0] === 'ctv')
                }
                style={{ margin: 0, display: 'flex' }}
              >
                {option.label}
              </Checkbox>
            ))}
          </Col>

          <Col span={7}>
            <div style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
              <Icons.CaretDownOutlined />
              <Text style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '3px' }}>詳細設定</Text>
            </div>
            <Form>
              <Form.Item
                label={'リーチ判定回数'}
                name="reachCount"
                colon={false}
                style={{ marginBottom: '0px' }}
                initialValue={'1'}
                labelCol={{ span: 9 }}
              >
                <Select
                  showArrow
                  size="small"
                  placeholder="選択"
                  value={reachCount}
                  onChange={(key) => setReachCount(key)}
                >
                  {reachCounts.map((reachCount: any) => {
                    return (
                      <Option key={reachCount.key} value={reachCount.key}>
                        {reachCount.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={'属性グループ'}
                name="attribute"
                colon={false}
                initialValue="youtube"
                style={{ marginBottom: '0px' }}
                labelCol={{ span: 9 }}
              >
                <Select showArrow size="small" placeholder="選択" disabled>
                  <Option key={'youtube'} value={'youtube'}>
                    youtube
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={'粒度'}
                name="particleSize"
                colon={false}
                initialValue="campaign"
                style={{ marginBottom: '0px' }}
                labelCol={{ span: 9 }}
              >
                <Select showArrow size="small" placeholder="選択" disabled>
                  <Option key={'campaign'} value={'campaign'}>
                    campaign
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={'単位'}
                name="unit"
                colon={false}
                initialValue="pop,ratio"
                style={{ marginBottom: '0px' }}
                labelCol={{ span: 9 }}
              >
                <Select showArrow size="small" placeholder="選択" value={unit} onChange={(key) => setSelectedUnit(key)}>
                  <Option key={'pop,ratio'} value={'pop,ratio'}>
                    人数 / %
                  </Option>
                  <Option key={'pop'} value={'pop'}>
                    人数
                  </Option>
                  <Option key={'ratio'} value={'ratio'}>
                    %
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={'指標算出分母'}
                name="basePopulation"
                colon={false}
                initialValue="tv_pop"
                style={{ marginBottom: '0px' }}
                labelCol={{ span: 9 }}
              >
                <Select
                  showArrow
                  size="small"
                  placeholder="選択"
                  value={basePopulation}
                  onChange={(key) => setBasePopulation(key)}
                >
                  <Option key={'tv_pop'} value={'tv_pop'}>
                    TV所有者ベース
                  </Option>
                  <Option key={'all_pop'} value={'all_pop'}>
                    全人口ベース
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={'YouTube共視聴'}
                name="coViewing"
                colon={false}
                initialValue={true}
                style={{ marginBottom: '0px' }}
                labelCol={{ span: 9 }}
              >
                <Select
                  showArrow
                  size="small"
                  placeholder="選択"
                  value={coViewing}
                  onChange={(key) => setCoViewing(key)}
                >
                  <Option key={'true'} value={true}>
                    あり
                  </Option>
                  <Option key={'false'} value={false}>
                    なし
                  </Option>
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>

      {websensorCtvStartDate !== undefined && websensorCtvStartDate <= today ? (
        <div>
          <Text style={{ fontSize: '14px', marginLeft: '3px', display: 'block' }}>
            ※ 「TV×YouTube（CTV）」は、推計が実施されているキャンペーンのみデータダウンロードされます。
          </Text>
        </div>
      ) : (
        <></>
      )}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          onClick={onClickDownload}
          disabled={isDownloadButtonDisabled}
        >
          {'ダウンロード'}
        </Button>
      </div>
    </>
  )
}
