import {
  Button,
  Card,
  Col,
  EditButton,
  Form,
  Input,
  List,
  Row,
  Space,
  Table,
  useTable,
  getDefaultFilter,
  Icons,
  Pagination,
  FormProps,
} from '@pankod/refine-antd'
import { CrudFilters, HttpError, usePermissions, CrudSort } from '@pankod/refine-core'
import { ISponsor, ISponsorFilterVariables } from 'interfaces'
import { convertOrderKey, is } from 'utils'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'
import { maxLength } from 'utils'

export const SponsorList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    ISponsor,
    HttpError,
    ISponsorFilterVariables
  >({
    permanentSorter: [sortCondition],
    metaData: {
      fields: ['id', 'sponsorName', 'sponsorCd'],
      current: currentPage,
      pageSize: 10,
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { sponsorName, sponsorCd } = params

      filters.push({
        field: 'sponsorName',
        operator: 'contains',
        value: sponsorName ? sponsorName : null,
      })

      filters.push({
        field: 'sponsorCd',
        operator: 'contains',
        value: sponsorCd ? sponsorCd : null,
      })

      return filters
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('SPONSOR')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`広告主 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`広告主 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} title={'フィルター'}>
            <Filter formProps={searchFormProps} filters={filters || []} setCurrentPage={setCurrentPage} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List>
            <Table
              {...tableProps}
              rowKey="id"
              pagination={false}
              onChange={(_pagination, _filters, sorter: any) => {
                let field = ''
                if (is('String', sorter.field)) field = sorter.field
                else field = sorter.field.slice(-1)[0]
                setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
                tableQueryResult.refetch()
              }}
            >
              <Table.Column dataIndex="sponsorCd" title="広告主コード" sorter ellipsis />
              <Table.Column dataIndex="sponsorName" title="広告主" sorter ellipsis />
              <Table.Column<ISponsor>
                title="操作"
                dataIndex="actions"
                fixed="right"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" recordItemId={record.id} icon={false} />
                    </Space>
                  )
                }}
              />
            </Table>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                current={currentPage}
                onChange={onChangePage}
                total={tableQueryResult?.data?.total}
                showSizeChanger={false}
              />
            </div>
          </List>
        </Col>
      </Row>
    </>
  )
}

const Filter: React.FC<{
  formProps: FormProps
  filters: CrudFilters
  setCurrentPage: (currentPage: number) => void
}> = (props) => {
  const { formProps, filters, setCurrentPage } = props

  return (
    <Form
      layout="vertical"
      initialValues={{
        q: getDefaultFilter('sponsorName', filters),
      }}
      {...formProps}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'広告主コード'} name="sponsorCd" rules={[maxLength(5)]}>
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={5} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'広告主'} name="sponsorName" rules={[maxLength(100)]}>
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={100} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block onClick={() => setCurrentPage(1)}>
              {'絞り込み'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
