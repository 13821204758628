import { Create, Form, Input, ListButton, Select, useForm, useSelect } from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useCustom,
  useNavigation,
  useNotification,
  usePermissions,
} from '@pankod/refine-core'
import { gqlDataProvider, GRAPHQL_ENDPOINT } from 'auth'
import { ICampaign, IClient, IDepartment } from 'interfaces'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'
import { required, maxLength, validatePasteValueLength, frontBlankTrim, rearBlankTrim } from 'utils'

export const CampaignCreate: React.FC<IResourceComponentsProps> = () => {
  const { open } = useNotification()
  const { edit } = useNavigation()
  const { formProps, saveButtonProps } = useForm<ICampaign>()
  const [submitted, setSubmitted] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState()
  const [selectedDepartmentID, setSelectedDepartmentID] = useState()
  const { data: permissions } = usePermissions()

  // 依頼会社の取得
  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: 'clients',
    optionLabel: 'clientName',
    optionValue: 'id',
    filters: [{ field: 'id', operator: 'ne', value: 'Q2xpZW50OjE=' }], // 事務局除外
    metaData: {
      fields: ['id', 'clientName'],
      pageSize: 9999999,
    },
  })

  // VR部署の取得
  const { data: departments } = useCustom<[IDepartment]>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'departments',
    },
  })

  const onFinish = async (values: any) => {
    setSubmitted(true)
    try {
      const response = await gqlDataProvider.custom!({
        url: GRAPHQL_ENDPOINT,
        method: 'post',
        metaData: {
          operation: 'createCampaign',
          variables: {
            input: {
              value: {
                basicInfo: {
                  campaignName: values.basicInfo.campaignName,
                  clientId: values.basicInfo.clientId,
                  salesDepartmentId: values.basicInfo.salesDepartmentId,
                  isActive: values.basicInfo.isActive,
                },
              },
              type: 'CreateCampaignInput!',
            },
          },
          fields: [{ basicInfo: ['id'] }],
        },
      })
      open({
        message: 'キャンペーンを作成しました',
        key: 'success',
        type: 'success',
      })
      edit('campaigns', response.data.basicInfo.id)
    } catch (error) {
      open({
        message: 'キャンペーンの作成に失敗しました',
        key: 'error',
        type: 'error',
      })
      setSubmitted(false)
      return Promise.reject()
    }
  }

  if (!permissions?.includes('CAMPAIGN')) {
    return <ErrorComponent />
  }

  return (
    <>
      <Helmet>
        <title>{`キャンペーン | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`キャンペーン | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={{ ...saveButtonProps, disabled: submitted }}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="campaigns" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          onFinish={onFinish}
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 18 }}
          colon={false}
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item label="キャンペーン名" style={{ marginBottom: '0px' }} required>
            <Form.Item
              name={['basicInfo', 'campaignName']}
              rules={[required(), maxLength(200), frontBlankTrim(), rearBlankTrim()]}
            >
              <Input placeholder="キャンペーン名" maxLength={200} showCount />
            </Form.Item>
          </Form.Item>

          <Form.Item label="依頼会社" style={{ marginBottom: '0px' }} required>
            <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
              <Form.Item name={['basicInfo', 'clientId']} rules={[required('選択してください')]}>
                <Select
                  {...clientSelectProps}
                  showArrow
                  onSearch={undefined}
                  filterOption={true}
                  optionFilterProp="label"
                  placeholder={'選択'}
                  value={selectedClientId}
                  onSelect={(clientId: any) => setSelectedClientId(clientId)}
                />
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="VR営業担当部署" style={{ marginBottom: '0px' }} required>
            <Form.Item name={['basicInfo', 'salesDepartmentId']} rules={[required('選択してください')]}>
              <Select
                placeholder={'選択'}
                value={selectedDepartmentID}
                onSelect={(departmentID: any) => setSelectedDepartmentID(departmentID)}
              >
                {departments &&
                  departments.data.map((d: any) => {
                    return (
                      <Select.Option key={d.id} value={d.id}>
                        {d.department_name}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item name={['basicInfo', 'isActive']} hidden></Form.Item>
        </Form>
      </Create>
    </>
  )
}
