import { Spin, Edit, Form, Select, useSelect } from '@pankod/refine-antd'
import { IResourceComponentsProps, useNotification, usePermissions } from '@pankod/refine-core'
import { gqlDataProvider, GRAPHQL_ENDPOINT } from 'auth'
import { AccountUserLicenseMatrix } from 'components/form'
import { IAccountUser, IClient, ISponsor } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  AccountUserLicense,
  AccountUserLicenseTableColumnData,
  AccountUserLicenseTableData,
  CheckStateByAccountUser,
  checkStateBySponsor,
} from 'types'
import { required, validatePasteValueLength } from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const AccountUserLicenseEdit: React.FC<IResourceComponentsProps> = () => {
  const { open } = useNotification()
  const [isLoading, setIsLoading] = useState(false)
  const [checkedLicenses, setCheckedLicenses] = useState<AccountUserLicense[]>()
  const [tableData, setTableData] = useState<AccountUserLicenseTableData[]>()
  const [tableColumnData, setTableColumnData] = useState<AccountUserLicenseTableColumnData[]>()
  const [selectedClientId, setSelectedClientId] = useState()
  const [checkedAll, setCheckedAll] = useState(false)
  const [checkedAllByAccount, setCheckedAllByAccount] = useState<CheckStateByAccountUser[]>()
  const [checkedAllBySponsor, setCheckedAllSponsor] = useState<checkStateBySponsor[]>()
  const updateCheckedLicenses = (licenses: AccountUserLicense[]): void => setCheckedLicenses(licenses)
  const updateCheckedAll = (v: boolean): void => setCheckedAll(v)
  const updateCheckedAllByAccount = (checkedRows: any): void => setCheckedAllByAccount(checkedRows)
  const updateCheckedAllBySponsor = (checkedRows: any): void => setCheckedAllSponsor(checkedRows)

  const { selectProps: clientSelectProps, queryResult: clientQueryResult } = useSelect<IClient>({
    resource: 'clients',
    optionLabel: 'clientName',
    optionValue: 'id',
    filters: [{ field: 'id', operator: 'ne', value: 'Q2xpZW50OjE=' }], // 事務局除外
    metaData: {
      pageSize: 9999999,
      fields: [
        'id',
        'clientName',
        'clientCd',
        { sponsors: ['id', 'sponsorName'] },
        { accountUsers: ['id', 'loginId', { licenses: [{ sponsor: ['id'] }] }] },
        'isActive',
      ],
    },
  })

  const result = clientQueryResult?.data?.data

  useEffect(() => {
    if (!result) return
    const client = result.filter((client) => client.id === selectedClientId)[0]
    if (!client) return
    setIsLoading(true)

    const sponsorIds = client.sponsors.map((sponsor) => sponsor.id)
    const uniqueSponsorIds = Array.from(new Set(sponsorIds))

    // 広告主の「全て」チェック状態を初期化
    const initCheckedAllBySponsor = uniqueSponsorIds.map((sponsorId) => {
      return { sponsorId: sponsorId, checked: false }
    })
    updateCheckedAllBySponsor(initCheckedAllBySponsor)

    // アカウントの「全て」チェック状態を初期化
    const initCheckedAllByAccount = client.accountUsers?.map((accountUser) => {
      return {
        accountUserId: accountUser.id,
        checked: false,
      }
    })
    updateCheckedAllByAccount(initCheckedAllByAccount)

    // 個別のライセンスチェック状態を初期化（登録済のライセンスがあれば反映）
    const initLicenseState = client.accountUsers.map((accountUser: IAccountUser) => {
      const registeredSponsorIds: string[] = []
      accountUser.licenses.forEach((license: any) => registeredSponsorIds.push(license.sponsor.id))
      const updatedCheckList = uniqueSponsorIds.map((sponsorId) => {
        return { sponsorId: sponsorId, checked: registeredSponsorIds.includes(sponsorId) }
      })
      return {
        accountUserId: accountUser.id,
        licenses: updatedCheckList,
      } as AccountUserLicense
    })
    updateCheckedLicenses(initLicenseState)

    // テーブル描画データ初期化
    const initTableData = client.accountUsers.map((accountUser: IAccountUser) => {
      return {
        key: accountUser.id,
        accountUserId: accountUser.id,
        loginId: accountUser.loginId,
      }
    })
    setTableData(initTableData)

    // テーブルカラムデータ初期化
    let targets = JSON.parse(JSON.stringify(uniqueSponsorIds))
    let initTacbleColumnData = client.sponsors.map((sponsor: ISponsor) => {
      if (targets.includes(sponsor.id)) {
        targets = targets.filter((sponsorId: string) => sponsorId !== sponsor.id)
        return {
          key: sponsor.id,
          id: sponsor.id,
          sponsorName: sponsor.sponsorName,
        } as any
      }
    })
    initTacbleColumnData = initTacbleColumnData.filter((v) => v)
    setTableColumnData(initTacbleColumnData)
    updateCheckedAll(false)
    setIsLoading(false)
  }, [result, selectedClientId])

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('ACCOUNT_USER_LICENSE')) {
    return <ErrorComponent />
  }

  const isNotExistsLicenseTarget = () => {
    return checkedAllByAccount?.length === 0 || checkedAllBySponsor?.length === 0
  }

  const getRequestLicenseParams = () => {
    const params = checkedLicenses?.map((record) => {
      const sponsorIds = record.licenses.filter((license) => license.checked).map((license) => license.sponsorId)
      return {
        accountUserId: record.accountUserId,
        sponsorIds: sponsorIds,
      }
    })
    return params
  }

  const onClickSave = async () => {
    if (!checkedLicenses || !result || isNotExistsLicenseTarget()) {
      open({
        message: 'ライセンスが設定されていません',
        description: 'ライセンス未設定',
        key: 'error',
        type: 'error',
      })
      return Promise.reject()
    }

    const params = getRequestLicenseParams()
    const client = result.filter((client) => client.id === selectedClientId)[0]
    try {
      await gqlDataProvider.custom!({
        url: GRAPHQL_ENDPOINT,
        method: 'post',
        metaData: {
          operation: 'createAccountUserLicense',
          variables: {
            input: {
              value: {
                clientId: client.id,
                accountUserLicenses: params,
              },
              type: 'CreateAccountUserLicenseInput!',
            },
          },
          fields: ['id'],
        },
      })
      open({
        message: 'ライセンスを更新しました',
        description: '成功',
        key: 'success',
        type: 'success',
      })
      clientQueryResult.refetch()
      return Promise.resolve()
    } catch (error) {
      open({
        message: 'ライセンスの登録に失敗しました',
        description: '失敗',
        key: 'error',
        type: 'error',
      })
      return Promise.reject()
    }
  }

  return (
    <>
      <Helmet>
        <title>{`ライセンス管理 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`ライセンス管理 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        resource="accountUserLicenses"
        isLoading={clientQueryResult?.isFetching}
        saveButtonProps={{ onClick: onClickSave }}
        pageHeaderProps={{
          backIcon: false,
          extra: false,
          title: 'アカウントライセンス設定',
        }}
      >
        <Form
          layout="horizontal"
          labelCol={{ xxl: 2, xl: 2, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 8, xl: 8, lg: 18, md: 18, sm: 18 }}
          colon={false}
        >
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
            <Form.Item label="依頼主" rules={[required('選択してください')]}>
              <Select
                data-testid={'sl-client-id'}
                {...clientSelectProps}
                autoClearSearchValue={false}
                showArrow
                placeholder={'部分一致/選択'}
                onSelect={(v: any) => setSelectedClientId(v)}
              />
            </Form.Item>
          </div>
          <Form.Item wrapperCol={{ span: 22 }} label="ライセンス" rules={[required('選択してください')]}>
            {tableData && tableColumnData && checkedAllByAccount && checkedAllBySponsor && (
              <AccountUserLicenseMatrix
                tableData={tableData}
                tableColumnData={tableColumnData}
                checkedLicenses={checkedLicenses}
                checkedAll={checkedAll}
                checkedAllByAccount={checkedAllByAccount}
                checkedAllBySponsor={checkedAllBySponsor}
                updateCheckedAll={updateCheckedAll}
                updateCheckedLicenses={updateCheckedLicenses}
                updateCheckedAllByAccount={updateCheckedAllByAccount}
                updateCheckedAllBySponsor={updateCheckedAllBySponsor}
              />
            )}
            {isLoading && <Spin />}
            {isNotExistsLicenseTarget() && <div>ライセンス付与対象がありません。</div>}
            {!tableData && <div>依頼主を選択してください。</div>}
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}
