import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ErrorBoundary from 'components/ErrorBoundary'

export default ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <HelmetProvider>
        <Helmet>
          <title>{process.env.REACT_APP_SERVICE_NAME}</title>
          <meta name="description" content={`${process.env.REACT_APP_SERVICE_NAME}`} />
        </Helmet>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </HelmetProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
