import { Button, Result } from 'antd'
import React from 'react'

export const ErrorComponent: React.FC = () => {
  return (
    <Result
      status="warning"
      title="404"
      subTitle="ページが存在しません"
      extra={
        <Button type="primary" onClick={() => (window.location.href = '/')}>
          トップへ戻る
        </Button>
      }
    />
  )
}
