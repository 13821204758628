import { Create, Form, Input, ListButton, Select, useForm, useSelect } from '@pankod/refine-antd'
import { IResourceComponentsProps, usePermissions } from '@pankod/refine-core'
import { IAccountUser, IClient } from 'interfaces'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'
import {
  frontBlankTrim,
  LOGINID_REGEX,
  maxLength,
  PASSWORD_REGEX,
  pattern,
  rearBlankTrim,
  required,
  validatePasteValueLength,
} from 'utils'

export const AccountUserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IAccountUser>()

  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: 'clients',
    optionLabel: 'clientName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'clientName'],
      pageSize: 9999999,
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('ACCOUNT_USER')) {
    return <ErrorComponent />
  }

  return (
    <>
      <Helmet>
        <title>{`アカウント管理 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`アカウント管理 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="accountUsers" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 8 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 16 }}
          colon={false}
        >
          <Form.Item
            label="ログインID"
            name="loginId"
            required
            rules={[
              required(),
              frontBlankTrim(),
              rearBlankTrim(),
              pattern(LOGINID_REGEX, '4～16文字の半角英字か半角数字、規定の半角記号で入力してください'),
            ]}
          >
            <Input
              showCount
              autoComplete="new-password"
              minLength={4}
              maxLength={16}
              placeholder="ログインID"
              disabled={false}
            />
          </Form.Item>

          <Form.Item
            label="パスワード"
            name="password"
            required
            rules={[
              required(),
              frontBlankTrim(),
              rearBlankTrim(),
              pattern(PASSWORD_REGEX, '8〜16文字で必ず1つ以上の英字と1つ以上の数字を含めてください'),
            ]}
          >
            <Input.Password autoComplete="new-password" minLength={8} maxLength={16} placeholder="●●●●●●●●" />
          </Form.Item>

          <Form.Item
            label={'パスワード（確認）'}
            name="confirmPassword"
            required
            rules={[
              required(),
              ({ getFieldValue }) => ({
                validator(_, confirmPassword) {
                  if (confirmPassword !== '') {
                    if (getFieldValue('password') === confirmPassword) {
                      return Promise.resolve()
                    }
                    return Promise.reject(<span style={{ color: 'red' }}>パスワードが一致しません</span>)
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input.Password autoComplete="new-password" placeholder="●●●●●●●●" minLength={8} maxLength={16} />
          </Form.Item>

          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
            <Form.Item label={'依頼主'} name={'clientId'} required rules={[required('選択してください')]}>
              <Select {...clientSelectProps} showArrow autoClearSearchValue={false} placeholder={'部分一致/選択'} />
            </Form.Item>
          </div>

          <Form.Item label="部署" name="department" rules={[maxLength(100), frontBlankTrim(), rearBlankTrim()]}>
            <Input placeholder="営業部" maxLength={100} showCount />
          </Form.Item>

          <Form.Item name="isResponsible" hidden initialValue={false}></Form.Item>

          <Form.Item name="isActive" hidden initialValue={true}></Form.Item>
        </Form>
      </Create>
    </>
  )
}
