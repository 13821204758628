import {
  Button,
  Card,
  Col,
  EditButton,
  Form,
  Input,
  List,
  Row,
  Space,
  Table,
  useTable,
  Icons,
  Pagination,
  FormProps,
} from '@pankod/refine-antd'
import { usePermissions, CrudFilters, HttpError, CrudSort } from '@pankod/refine-core'
import { IManagementUser, IManagementUserFilterVariables } from 'interfaces'
import { convertOrderKey, is, maxLength } from 'utils'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const ManagementUserList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IManagementUser,
    HttpError,
    IManagementUserFilterVariables
  >({
    permanentSorter: [sortCondition],
    metaData: {
      fields: ['id', 'userName', 'email', 'department', 'label', 'isActive'],
      current: currentPage,
      pageSize: 10,
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { userName, department, label } = params

      filters.push({
        field: 'userName',
        operator: 'contains',
        value: userName ? userName : null,
      })

      filters.push({
        field: 'department',
        operator: 'contains',
        value: department ? department : null,
      })

      filters.push({
        field: 'label',
        operator: 'contains',
        value: label ? label : null,
      })

      return filters
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('MANAGEMENT_USER')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`管理ユーザー | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`管理ユーザー | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} title={'フィルター'}>
            <Filter formProps={searchFormProps} filters={filters || []} setCurrentPage={setCurrentPage} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List>
            <Table
              {...tableProps}
              rowKey="id"
              pagination={false}
              onChange={(_pagination, _filters, sorter: any) => {
                let field = ''
                if (is('String', sorter.field)) field = sorter.field
                else field = sorter.field.slice(-1)[0]
                setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
                tableQueryResult.refetch()
              }}
            >
              <Table.Column dataIndex="userName" title="名前" sorter ellipsis />
              <Table.Column dataIndex="email" title="メールアドレス" sorter ellipsis />
              <Table.Column dataIndex="department" title="所属" sorter ellipsis />
              <Table.Column dataIndex="label" title="ラベル" sorter ellipsis />
              <Table.Column
                dataIndex={'isActive'}
                title="状態"
                ellipsis
                render={(value) => (value ? '利用可' : '利用停止中')}
              />
              <Table.Column<IManagementUser>
                title="操作"
                dataIndex="actions"
                fixed="right"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" recordItemId={record.id} icon={false} />
                    </Space>
                  )
                }}
              />
            </Table>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                current={currentPage}
                onChange={onChangePage}
                total={tableQueryResult?.data?.total}
                showSizeChanger={false}
              />
            </div>
          </List>
        </Col>
      </Row>
    </>
  )
}
const Filter: React.FC<{
  formProps: FormProps
  filters: CrudFilters
  setCurrentPage: (currentPage: number) => void
}> = (props) => {
  const { formProps, setCurrentPage } = props

  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'名前'} name="userName" rules={[maxLength(100)]}>
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={100} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'所属'} name="department" rules={[maxLength(100)]}>
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={100} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'ラベル'} name="label" rules={[maxLength(100)]}>
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={100} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block onClick={() => setCurrentPage(1)}>
              {'絞り込み'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
