import { Button, PageHeader, Typography } from '@pankod/refine-antd'
import { useGetIdentity, useLogout, useNotification } from '@pankod/refine-core'
import './style.less'

const { Text } = Typography

export const Header: React.FC = () => {
  const { data: user } = useGetIdentity()
  const { mutate: logout } = useLogout()
  const { open } = useNotification()

  return (
    <PageHeader
      ghost={false}
      style={{ height: '40px', paddingTop: '6px', backgroundColor: '#07042e', borderRadius: 0 }}
      extra={[
        <Text key={1} ellipsis strong style={{ color: '#fff' }}>
          {user?.userName}
        </Text>,
        <Button
          key={2}
          size="small"
          onClick={() => {
            open({
              message: 'ログアウトしました',
              type: 'success',
            })
            logout()
          }}
          style={{ fontSize: '12px' }}
        >
          ログアウト
        </Button>,
      ]}
    />
  )
}
