export type SponsorLicense = {
  sponsorId?: string
  operations?: string[]
}

export type BrandLicense = {
  brandId?: string
  operations?: string[]
}

export type CampaignLicense = {
  campaignId?: string
  operations?: string[]
}

export type License = SponsorLicense | BrandLicense | CampaignLicense

export type AffiliationInputMode = 'sponsor' | 'client'

export type Node = {
  value: string
  title: string
  children: Node[]
}

export type ClientLicense = {
  sponsorId: string
  licenses: Licenses
}

export namespace LicenseNS {
  // 全て
  export const ALL = 'ALL'
  // サマリ
  export const CAMPAIGN_SUMMARY = 'CAMPAIGN_SUMMARY'
  // 属性比較
  export const CAMPAIGN_ATTRIBUTE_COMPARATION = 'CAMPAIGN_ATTRIBUTE_COMPARATION'
  // クロスフリークエンシー
  export const CAMPAIGN_CROSS_FREQUENCY = 'CAMPAIGN_CROSS_FREQUENCY'
  // キャンペーン比較
  export const CAMPAIGN_COMPARATION = 'CAMPAIGN_COMPARATION'
  // YouTube属性比較
  export const YOUTUBE_ATTRIBUTE_COMPARATION = 'YOUTUBE_ATTRIBUTE_COMPARATION'
}

export type Licenses = {
  [LicenseNS.ALL]: boolean
  [LicenseNS.CAMPAIGN_SUMMARY]: boolean
  [LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION]: boolean
  [LicenseNS.CAMPAIGN_CROSS_FREQUENCY]: boolean
  [LicenseNS.CAMPAIGN_COMPARATION]: boolean
  [LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION]: boolean
}

export type ClientLicenseTableData = {
  key: string
  sponsorId: string
  sponsorName: string
  CAMPAIGN_SUMMARY: boolean
  CAMPAIGN_ATTRIBUTE_COMPARATION: boolean
  CAMPAIGN_CROSS_FREQUENCY: boolean
  CAMPAIGN_COMPARATION: boolean
  YOUTUBE_ATTRIBUTE_COMPARATION: boolean
}

export type AccountUserLicense = {
  accountUserId: string
  licenses: { sponsorId: string; checked: boolean }[]
}

export type CheckStateByAccountUser = {
  accountUserId: string
  checked: boolean
}

export type checkStateBySponsor = {
  sponsorId: string
  checked: boolean
}

export type AccountUserLicenseTableData = {
  key: string
  accountUserId: string
  loginId: string
}

export type AccountUserLicenseTableColumnData = {
  key: string
  id: string
  sponsorName: string
}
