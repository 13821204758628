import { EditButton, List, Pagination, Space, Table, useTable } from '@pankod/refine-antd'
import { CrudSort, HttpError, usePermissions } from '@pankod/refine-core'
import { IFaqGroup } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { convertOrderKey, is } from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const FaqGroupList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, tableQueryResult } = useTable<IFaqGroup, HttpError>({
    permanentSorter: [sortCondition],
    metaData: {
      fields: ['id', 'faqGroupName', 'displayOrder'],
      current: currentPage,
      pageSize: 10,
    },
  })

  useEffect(() => {
    setSortCondition({ field: 'displayOrder', order: 'asc' } as CrudSort)
    tableQueryResult.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('FAQ_GROUP')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`FAQ | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`FAQ | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <List>
        <Table
          {...tableProps}
          rowKey="id"
          pagination={false}
          onChange={(_pagination, _filters, sorter: any) => {
            let field = ''
            if (is('String', sorter.field)) field = sorter.field
            else field = sorter.field.slice(-1)[0]
            setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
            tableQueryResult.refetch()
          }}
        >
          <Table.Column dataIndex="faqGroupName" title="FAQグループ名" sorter ellipsis />
          <Table.Column dataIndex="displayOrder" title="表示順" sorter ellipsis />
          <Table.Column<IFaqGroup>
            title="操作"
            dataIndex="actions"
            fixed="right"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <EditButton size="small" recordItemId={record.id} icon={false} />
                </Space>
              )
            }}
          />
        </Table>
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Pagination
            current={currentPage}
            onChange={onChangePage}
            total={tableQueryResult?.data?.total}
            showSizeChanger={false}
          />
        </div>
      </List>
    </>
  )
}
