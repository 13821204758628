import {
  Badge,
  Button,
  Tag,
  DeleteButton,
  Edit,
  Form,
  Icons,
  ListButton,
  Space,
  Upload,
  useForm,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useNavigation,
  useNotification,
  usePermissions,
  useRouterContext,
} from '@pankod/refine-core'
import { API_ENDPOINT, gqlDataProvider, GRAPHQL_ENDPOINT } from 'auth'
import { CampaignErrors, ExtendCmMaterial, ExtendImportAdhInfo, IAdArea, ICampaign, ICmMaterial } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { getCampaignNameForExport, getPeriod, hasRearBrank, hasFrontBrank } from 'utils'
import UUID from 'uuidjs'
import { ReturnTop } from '../../components/parts'
import { CampaignFields, importCustomerInputFileFields } from '../../returnFields'
import { BasicInfo } from './BasicInfo'
import { CMInfo } from './CMInfo'
import { DigitalPlacementInfo } from './DigitalPlacementInfo'
import './styles.css'
import { ErrorComponent } from 'components/404/ErrorComponent'
import { INIT_CAMPAIGN_ERROR_STATUS, INITIAL_ERRORS } from 'consts'

export const CampaignEdit: React.FC<IResourceComponentsProps> = () => {
  const { open } = useNotification()
  const { list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  // CM素材
  const [cmMaterials, setCmMaterials] = useState<ExtendCmMaterial[]>([])
  // google広告アカウントId
  const [googleAdsIds, setGoogleAdsIds] = useState<string[]>([])
  // デジタル出稿情報
  const [importAdhInfos, setImportAdhInfos] = useState<ExtendImportAdhInfo[]>([])
  // キャンペーン情報
  const [inputCampaign, setInputCampaign] = useState<any>({
    campaignName: null,
    productName: null,
    clientId: null,
    clientDepartment: null,
    vcid: null,
    salesDepartmentId: null,
    campaignId: null,
    sponsorNameFromCustomer: null,
    sponsorId: null,
    subAccountId: null,
    campaignPeriod: null,
    cmMaterialNumber: null,
    tvCmPeriod: null,
    tvBroadcastWaveId: null,
    tvTargetAttribute: null,
    adPlatformId: null,
    digitalPlacingPeriod: null,
    digitalTargetAttribute: null,
  })

  const [importCustomerInputFileErrors, setImportCustomerInputFileErrors] = useState<string[]>([])
  const [errors, setErrors] = useState<CampaignErrors>({
    campaignName: INIT_CAMPAIGN_ERROR_STATUS,
    productName: INIT_CAMPAIGN_ERROR_STATUS,
    clientId: INIT_CAMPAIGN_ERROR_STATUS,
    clientDepartment: INIT_CAMPAIGN_ERROR_STATUS,
    vcid: INIT_CAMPAIGN_ERROR_STATUS,
    salesDepartmentId: INIT_CAMPAIGN_ERROR_STATUS,
    campaignId: INIT_CAMPAIGN_ERROR_STATUS,
    sponsorNameFromCustomer: INIT_CAMPAIGN_ERROR_STATUS,
    sponsorId: INIT_CAMPAIGN_ERROR_STATUS,
    subAccountId: INIT_CAMPAIGN_ERROR_STATUS,
    campaignPeriod: INIT_CAMPAIGN_ERROR_STATUS,
    cmMaterialNumber: INIT_CAMPAIGN_ERROR_STATUS,
    tvTargetAttribute: INIT_CAMPAIGN_ERROR_STATUS,
    tvCmPeriod: INIT_CAMPAIGN_ERROR_STATUS,
    tvBroadcastWaveId: INIT_CAMPAIGN_ERROR_STATUS,
    adPlatformId: INIT_CAMPAIGN_ERROR_STATUS,
    digitalPlacingPeriod: INIT_CAMPAIGN_ERROR_STATUS,
    digitalTargetAttribute: INIT_CAMPAIGN_ERROR_STATUS,
  })

  const updateInputCampaign = (name: string, event: any) => {
    const value = event?.target?.value ?? event
    const newInputCampaign = {
      ...inputCampaign,
      [name]: value,
    }
    setInputCampaign(newInputCampaign)
    validateWhenInput(newInputCampaign)
  }

  const uploadCustomerInputFile = async (file: any) => {
    try {
      const data = await gqlDataProvider.custom!({
        url: GRAPHQL_ENDPOINT,
        method: 'post',
        metaData: {
          operation: 'importCampaign',
          variables: {
            input: {
              value: {
                id: record?.basicInfo?.id,
                file: file,
              },
              type: 'ImportCampaignInput!',
            },
          },
          fields: importCustomerInputFileFields,
        },
      })

      // ファイルインポートデータを画面項目に反映
      updateByImportData(data.data)

      open({
        message: '',
        description: 'インポートファイルの内容を反映しました',
        key: 'importInputCustomerFileSuccess',
        type: 'success',
      })
      return Promise.resolve()
    } catch (error: any) {
      open({
        message: '',
        description: 'ファイルインポートに失敗しました',
        key: 'importInputCustomerFileError',
        type: 'error',
      })
      const errorMessages = [error.message]
      setImportCustomerInputFileErrors(errorMessages)
      return Promise.reject()
    }
  }

  const uploadProps = {
    name: 'file',
    beforeUpload(file: any) {
      setImportCustomerInputFileErrors([])
      const excelFileTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ]
      if (!excelFileTypes.includes(file.type)) {
        open({
          message: 'Excel形式のファイルを指定してください',
          description: 'ファイル形式エラー',
          key: 'fileTypeError',
          type: 'error',
        })
        return
      }
      uploadCustomerInputFile(file)
      return Upload.LIST_IGNORE
    },
  }

  // フォームの管理
  const { formProps, saveButtonProps, queryResult } = useForm<ICampaign>({
    metaData: {
      fields: CampaignFields,
    },
  })

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('campaigns')
  const record = queryResult?.data?.data

  useEffect(() => {
    setInputCampaign({
      campaignName: record?.basicInfo?.campaignName ?? null,
      productName: record?.basicInfo?.productName ?? null,
      clientId: record?.basicInfo?.client?.id ?? null,
      clientDepartment: record?.basicInfo?.clientDepartment ?? null,
      vcid: record?.basicInfo?.client?.vcid ?? null,
      salesDepartmentId: record?.basicInfo?.salesDepartment?.id ?? null,
      campaignId: record?.basicInfo?.campaignId ?? null,
      sponsorNameFromCustomer: record?.basicInfo?.sponsorNameFromCustomer ?? null,
      sponsorId: record?.basicInfo?.sponsor?.id ?? null,
      subAccountId: record?.basicInfo?.subAccountId ?? null,
      campaignPeriod: getPeriod(record?.basicInfo?.campaignFrom, record?.basicInfo?.campaignTo),
      cmMaterialNumber: record?.tvCmInfo?.cmMaterialNumber ?? null,
      tvCmPeriod: getPeriod(record?.tvCmInfo?.tvCmFrom, record?.tvCmInfo?.tvCmTo),
      tvBroadcastWaveId: 'VHZCcm9hZGNhc3RXYXZlOjA=', // 放送波固定
      tvTargetAttribute: record?.tvCmInfo?.tvTargetAttribute ?? null,
      adPlatformId: record?.digitalPlacementInfo?.adPlatform.id ?? null,
      digitalPlacingPeriod: getPeriod(
        record?.digitalPlacementInfo?.digitalPlacingFrom,
        record?.digitalPlacementInfo?.digitalPlacingTo,
      ),
      digitalTargetAttribute: record?.digitalPlacementInfo?.digitalTargetAttribute ?? null,
    })
    setCmMaterials(
      record?.tvCmInfo?.cmMaterials.map((m) => {
        return { ...m, imported: false, rowId: UUID.generate() }
      }) ?? [],
    )
    setImportAdhInfos(
      record?.digitalPlacementInfo?.importAdhInfos.map((m) => {
        return { ...m, imported: false, rowId: UUID.generate() }
      }) ?? [],
    )
    setGoogleAdsIds(record?.basicInfo?.googleAdsAccountIds ?? [])
  }, [record])

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('CAMPAIGN') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  /**
   * インポートデータをもとに顧客指定IDを構築する
   */
  const getImportAdhInfo = (adhInfo: any) => {
    const newAdhInfo: any = {
      rowId: UUID.generate(),
      imported: true,
      importAdhId: adhInfo.id?.trim() ?? null,
      adAreaType: adhInfo.adAreaType,
      service: {
        id: adhInfo.serviceId,
        isActive: true,
        serviceName: adhInfo.serviceName,
      },
    }

    // ID指定があれば追加
    if (adhInfo.adhImportId) newAdhInfo.id = adhInfo.adhImportId
    return newAdhInfo
  }

  /**
   * インポートデータをもとにCM素材を構築する
   */
  const getImportCmMaterial = (importCmMaterial: any) => {
    let isNew = true
    let oldCmMaterial: ICmMaterial | null = null

    // 同一IDを保持していれば更新対象とみなす
    if ('cmMaterialId' in importCmMaterial && importCmMaterial.cmMaterialId) {
      const sameIdCmMaterials = cmMaterials?.filter(
        (stateCmMaterial) => 'id' in stateCmMaterial && stateCmMaterial.id === importCmMaterial.cmMaterialId,
      )
      if (sameIdCmMaterials.length > 1) throw Error('データ不整合があります')
      if (sameIdCmMaterials.length === 1) {
        isNew = false
        oldCmMaterial = sameIdCmMaterials[0]
      }
    }

    const newCmMaterial: any = {
      rowId: UUID.generate(),
      imported: true,
      id: isNew ? null : importCmMaterial.cmMaterialId?.trim() ?? null,
      cmMaterialDetail: importCmMaterial.cmMaterialDetail?.trim() ?? null,
      cmMaterialFirstPlacing: importCmMaterial.cmMaterialFirstPlacing?.trim() ?? null,
      cmSeconds: importCmMaterial.cmSeconds,
      cmMaterialEditName: importCmMaterial.cmMaterialName?.trim() ?? null, // CM素材名が cmMaterialEditName でなく cmMaterialName として渡ってきている

      // インポートファイルからの入力値がない項目
      brand: isNew ? null : oldCmMaterial?.brand,
      adAreas: isNew ? [] : oldCmMaterial?.adAreas,
      cmMaterialCd: isNew ? null : oldCmMaterial?.cmMaterialCd,
      cmMaterialFrom: isNew ? null : oldCmMaterial?.cmMaterialFrom,
      cmMaterialTo: isNew ? null : oldCmMaterial?.cmMaterialTo,
      hasAttachment: isNew ? null : oldCmMaterial?.hasAttachment,
      cmMaterialName: isNew ? null : oldCmMaterial?.cmMaterialName,
    }

    return newCmMaterial
  }

  /**
   * 顧客入力ファイルからのインポートデータをステートに反映する
   */
  const updateByImportData = (importData: any) => {
    setInputCampaign((original: any) => {
      return {
        // インポートファイルからの入力値がない項目はステートの値を入れ直す
        campaignName: importData.campaignName?.trim() ?? original.campaignName,
        productName: importData.productName?.trim() ?? original.productName,
        clientId: importData.clientId?.trim() ?? original.clientId,
        clientDepartment: importData.clientDepartment?.trim() ?? original.clientDepartment,
        sponsorNameFromCustomer: importData.sponsorNameFromCustomer?.trim() ?? original.sponsorNameFromCustomer,
        campaignPeriod: getPeriod(importData.campaignFrom, importData.campaignTo) ?? original.campaignPeriod,
        cmMaterialNumber: importData.tvCm.cmMaterialNumber ?? original.cmMaterialNumber,
        tvCmPeriod: getPeriod(importData.tvCm.tvCmFrom, importData.tvCm.tvCmTo) ?? original.tvCmPeriod,
        tvBroadcastWaveId: importData.tvCm.tvBroadcastWaveId?.trim() ?? original.tvBroadcastWaveId,
        tvTargetAttribute: importData.tvCm.tvTargetAttribute?.trim() ?? original.tvTargetAttribute,
        adPlatformId: importData.digitalPlacement.adPlatformId?.trim() ?? original.adPlatformId,
        digitalPlacingPeriod:
          getPeriod(importData.digitalPlacement.digitalPlacingFrom, importData.digitalPlacement.digitalPlacingTo) ??
          original.digitalPlacingPeriod,
        digitalTargetAttribute:
          importData.digitalPlacement.digitalTargetAttribute?.trim() ?? original.digitalTargetAttribute,

        // インポート対象でない項目はステートの値を入れ直す
        vcid: original.vcid,
        salesDepartmentId: original.salesDepartmentId,
        campaignId: original.campaignId,
        sponsorId: original.sponsorId,
        subAccountId: original.subAccountId,
      }
    })
    if (importData.tvCm.cmMaterials.length > 0) {
      setCmMaterials(importData.tvCm?.cmMaterials?.map((cmMaterial: any) => getImportCmMaterial(cmMaterial)))
    }
    if (importData.digitalPlacement.adhs.length > 0) {
      setImportAdhInfos(importData.digitalPlacement?.adhs?.map((adhInfo: any) => getImportAdhInfo(adhInfo)))
    }
  }

  const { form } = formProps

  /**
   * APIのInputパラメータ用に顧客指定IDを構築する
   */
  const getInputImportAdhInfos = () => {
    const infos = importAdhInfos.map((importAdhInfo) => {
      const info: { importAdhId: string; adAreaType: string; serviceId: string; importAdhInfoId?: string } = {
        importAdhId: importAdhInfo.importAdhId,
        adAreaType: importAdhInfo.adAreaType,
        serviceId: importAdhInfo.service.id,
      }
      // 既存レコードの場合はIDを追加
      if ('id' in importAdhInfo && importAdhInfo.id) info.importAdhInfoId = importAdhInfo.id
      return info
    })
    return infos
  }

  const getAdAreaIds = (adAreas: IAdArea[]) => {
    return adAreas.map((adArea: IAdArea) => adArea.id)
  }

  /**
   * APIのInputパラメータ用にCM素材を構築する
   */
  const getInputCmMaterials = () => {
    const materials = cmMaterials.map((cmMaterial) => {
      const material: any = {
        cmMaterialCd: cmMaterial.cmMaterialCd,
        cmMaterialDetail: cmMaterial.cmMaterialDetail,
        cmMaterialName: cmMaterial.cmMaterialName,
        cmMaterialEditName: cmMaterial.cmMaterialEditName,
        cmMaterialFirstPlacing: cmMaterial.cmMaterialFirstPlacing,
        cmMaterialFrom: cmMaterial.cmMaterialFrom ?? null,
        cmMaterialTo: cmMaterial.cmMaterialTo ?? null,
        cmSeconds: cmMaterial.cmSeconds,
        hasAttachment: cmMaterial.hasAttachment,
        adAreaIds: getAdAreaIds(cmMaterial.adAreas),
        brandId: cmMaterial.brand?.id,
      }
      // 既存レコードの場合はIDを追加
      if ('id' in cmMaterial && cmMaterial.id) material.cmMaterialId = cmMaterial.id
      return material
    })
    return materials
  }

  /**
   * フォーム入力時のバリデーション
   */
  const validateWhenInput = (inputCampaign: any) => {
    const validResults: any = INITIAL_ERRORS

    for (const key of Object.keys(inputCampaign)) {
      validResults[key] = INIT_CAMPAIGN_ERROR_STATUS

      // 行頭空白チェック
      if (hasFrontBrank(inputCampaign[key])) {
        validResults[key] = { ...validResults[key], ...{ requiredFrontTrim: true } }
      }

      // 行末空白チェック
      if (hasRearBrank(inputCampaign[key])) {
        validResults[key] = { ...validResults[key], ...{ requiredRearTrim: true } }
      }
    }
    setErrors({ ...validResults })
  }

  /**
   * 確定ボタン押下時の時のバリデーション
   */
  const validateWhenRegister = () => {
    let isValid = true
    const validResults: any = INITIAL_ERRORS

    for (const key of Object.keys(inputCampaign)) {
      const excludes = ['tvTargetAttribute', 'digitalTargetAttribute']
      validResults[key] = INIT_CAMPAIGN_ERROR_STATUS

      // 必須チェック
      if (
        !excludes.includes(key) &&
        (inputCampaign[key] === '' || inputCampaign[key] === null || inputCampaign[key] === undefined)
      ) {
        validResults[key] = { ...validResults[key], ...{ required: true } }
        isValid = false
      }

      // 行頭空白チェック
      if (hasFrontBrank(inputCampaign[key])) {
        validResults[key] = { ...validResults[key], ...{ requiredFrontTrim: true } }
        isValid = false
      }

      // 行末空白チェック
      if (hasRearBrank(inputCampaign[key])) {
        validResults[key] = { ...validResults[key], ...{ requiredRearTrim: true } }
        isValid = false
      }
    }
    setErrors({ ...validResults })
    if (!isValid) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
    return isValid
  }

  /**
   * 一時保存ボタン押下時の時のバリデーション
   */
  const validateWhenTemporarySave = () => {
    let isValid = true
    const validResults: any = INITIAL_ERRORS

    for (const key of Object.keys(inputCampaign)) {
      const requiredTarget = ['clientId', 'campaignId', 'vcid']
      validResults[key] = INIT_CAMPAIGN_ERROR_STATUS

      // 必須チェック
      if (
        requiredTarget.includes(key) &&
        (inputCampaign[key] === '' || inputCampaign[key] === null || inputCampaign[key] === undefined)
      ) {
        validResults[key] = { ...validResults[key], ...{ required: true } }
        isValid = false
      }

      // 行頭空白チェック
      if (hasFrontBrank(inputCampaign[key])) {
        validResults[key] = { ...validResults[key], ...{ requiredFrontTrim: true } }
        isValid = false
      }

      // 行末空白チェック
      if (hasRearBrank(inputCampaign[key])) {
        validResults[key] = { ...validResults[key], ...{ requiredRearTrim: true } }
        isValid = false
      }
    }
    setErrors({ ...validResults })
    if (!isValid) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
    return isValid
  }

  const onClickSave = async (isTemporary: boolean) => {
    const isValid = isTemporary ? validateWhenTemporarySave() : validateWhenRegister()
    if (!isValid) {
      open({
        message: '',
        description: '必須項目が未入力または入力に誤りがあります',
        key: 'validateError',
        type: 'error',
      })
      return
    }

    form?.setFieldsValue({
      basicInfo: {
        campaignName: inputCampaign.campaignName ?? null,
        productName: inputCampaign.productName ?? null,
        clientId: inputCampaign.clientId ?? null,
        clientDepartment: inputCampaign.clientDepartment ?? null,
        vcid: inputCampaign.client?.vcid ?? null,
        salesDepartmentId: inputCampaign.salesDepartmentId ?? null,
        campaignId: inputCampaign.campaignId ?? null,
        sponsorNameFromCustomer: inputCampaign.sponsorNameFromCustomer ?? null,
        sponsorId: inputCampaign.sponsorId ?? null,
        subAccountId: inputCampaign.subAccountId ?? null,
        campaignFrom: inputCampaign.campaignPeriod ? inputCampaign.campaignPeriod[0] : null,
        campaignTo: inputCampaign.campaignPeriod ? inputCampaign.campaignPeriod[1] : null,
        googleAdsAccountIds: googleAdsIds,
      },
      tvCmInfo: {
        cmMaterialNumber: inputCampaign.cmMaterialNumber ?? null,
        tvCmFrom: inputCampaign.tvCmPeriod ? inputCampaign.tvCmPeriod[0] : null,
        tvCmTo: inputCampaign.tvCmPeriod ? inputCampaign.tvCmPeriod[1] : null,
        tvBroadcastWaveId: inputCampaign.tvBroadcastWaveId ?? null,
        tvTargetAttribute: inputCampaign.tvTargetAttribute ?? null,
        cmMaterials: getInputCmMaterials(),
      },
      digitalPlacementInfo: {
        adPlatformId: inputCampaign.adPlatformId ?? null,
        digitalPlacingFrom: inputCampaign.digitalPlacingPeriod ? inputCampaign.digitalPlacingPeriod[0] : null,
        digitalPlacingTo: inputCampaign.digitalPlacingPeriod ? inputCampaign.digitalPlacingPeriod[1] : null,
        digitalTargetAttribute: inputCampaign.digitalTargetAttribute ?? null,
        importAdhInfos: getInputImportAdhInfos(),
      },
      isTemporary: isTemporary,
    })
    form?.submit()
  }

  const onClickExportCustomerInputFile = async () => {
    if (!record?.basicInfo?.id) return
    const accessToken = localStorage.getItem('accessToken')
    fetch(API_ENDPOINT + `export/customer_input_file/${record?.basicInfo?.id}`)
      .then((resp) => {
        if (resp.status === 401) throw new Error('Unauthorized')
        return resp.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `登録用紙_${record?.basicInfo?.campaignId}_${getCampaignNameForExport(
          record?.basicInfo?.campaignName,
        )}.xlsx`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        open({
          message: '',
          description: '登録ファイルをダウンロードしました',
          key: 'exportInputFileSuccess',
          type: 'success',
        })
      })
      .catch(() => {
        open({
          message: '',
          description: 'ファイル出力に失敗しました',
          key: 'exportInputFileError',
          type: 'error',
        })
      })
  }

  const onClickExportConfirmFile = async () => {
    if (!record?.basicInfo?.id) return
    const accessToken = localStorage.getItem('accessToken')
    fetch(API_ENDPOINT + `export/final_confirm_file/${record?.basicInfo?.id}`, {
      headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
    })
      .then((resp) => {
        if (resp.status === 401) throw new Error('Unauthorized')
        return resp.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `確認用紙_${record?.basicInfo?.campaignId}_${getCampaignNameForExport(
          record?.basicInfo?.campaignName,
        )}.xlsx`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        open({
          message: '',
          description: '顧客確認ファイルをダウンロードしました',
          key: 'exportConfirmFileSuccess',
          type: 'success',
        })
      })
      .catch(() => {
        open({
          message: '',
          description: 'ファイル出力に失敗しました',
          key: 'exportConfirmFileError',
          type: 'error',
        })
      })
  }

  return (
    <>
      <Helmet>
        <title>{`キャンペーン | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`キャンペーン | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="campaigns" />,
        }}
        actionButtons={
          <>
            <DeleteButton
              onSuccess={() => {
                list('campaigns')
              }}
              icon={false}
            />
            {/* キャンペーンステータスが未確定の場合のみ一時保存ボタンを表示 */}
            {record?.basicInfo?.confirmationStatus !== 'COMPLETE' && (
              <Button htmlType="submit" onClick={() => onClickSave(true)}>
                一時保存
              </Button>
            )}
            <Button htmlType="submit" type="primary" onClick={() => onClickSave(false)}>
              {record?.basicInfo?.confirmationStatus !== 'COMPLETE' ? '確定' : '編集'}
            </Button>
          </>
        }
      >
        <div style={{ display: 'flex', WebkitFlexWrap: 'wrap' }}>
          <Space direction="vertical">
            <Upload {...uploadProps} showUploadList={false}>
              <Button
                type="primary"
                icon={<Icons.PlusSquareOutlined />}
                style={{ margin: '8px 4px 0px 4px' }}
                onClick={() => {}}
              >
                登録用紙インポート
              </Button>
            </Upload>
            {importCustomerInputFileErrors &&
              importCustomerInputFileErrors.map((errorMessage) => <span style={{ color: 'red' }}>{errorMessage}</span>)}
          </Space>
          <div className="customerConfirmationFileOutput">
            <Button
              icon={<Icons.DownloadOutlined />}
              style={{ margin: '8px 4px 0px 8px' }}
              onClick={onClickExportCustomerInputFile}
            >
              登録用紙出力
            </Button>
            <Button
              icon={<Icons.DownloadOutlined />}
              style={{ margin: '8px 8px 0px 8px' }}
              onClick={onClickExportConfirmFile}
            >
              顧客確認用紙出力
            </Button>
          </div>
        </div>
        <Form
          {...formProps}
          labelCol={{ xxl: 10, xl: 8, lg: 11, md: 11, sm: 8 }}
          wrapperCol={{ xxl: 14, xl: 16, lg: 13, md: 13, sm: 16 }}
          colon={false}
        >
          {record && (
            <BasicInfo
              record={record}
              gAds={{ googleAdsIds, setGoogleAdsIds }}
              input={{ inputCampaign, updateInputCampaign }}
              errors={errors}
            />
          )}
          {record && (
            <CMInfo
              input={{ inputCampaign, updateInputCampaign }}
              tvCmInput={{ cmMaterials, setCmMaterials }}
              errors={errors}
            />
          )}
          <ReturnTop />
          {record?.digitalPlacementInfo && (
            <DigitalPlacementInfo
              record={record}
              input={{ inputCampaign, updateInputCampaign }}
              adhInfos={{ importAdhInfos, setImportAdhInfos }}
              errors={errors}
            />
          )}
          <ReturnTop />
        </Form>
      </Edit>
    </>
  )
}
