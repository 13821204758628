import datadogLogs from 'logger'
import { ConfigProvider, Icons, Layout } from '@pankod/refine-antd'
import '@pankod/refine-antd/dist/styles.min.css'
import { AuthProvider, Refine, NotificationProvider, LayoutWrapper } from '@pankod/refine-core'
import routerProvider from '@pankod/refine-react-router-v6'
import jaJP from 'antd/lib/locale/ja_JP'
import { GRAPHQL_ENDPOINT, gqlDataProvider } from 'auth'
import { ErrorComponent } from 'components/404/ErrorComponent'
import * as gql from 'gql-query-builder'
import { GraphQLClient } from 'graphql-request'
import { CampaignDownload } from 'pages/download'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { setLogger } from 'react-query'
import { clearAuth, getUserIp } from 'utils'
import { Header } from './components/header'
import { Sider } from './components/sider'
import { Title } from './components/title'
import { AccountUserLicenseEdit } from './pages/accountUserLicenses'
import { AccountUserCreate, AccountUserEdit, AccountUserList, AccountUserPasswordChange } from './pages/accountUsers'
import { BrandCreate, BrandEdit, BrandList } from './pages/brands'
import { CampaignCreate, CampaignEdit, CampaignList } from './pages/campaigns'
import { ClientCreate, ClientEdit, ClientList } from './pages/clients'
import { Dashboard } from './pages/dashboard'
import { FaqGroupCreate, FaqGroupEdit, FaqGroupList } from './pages/faqGroup'
import { InformationCreate, InformationEdit, InformationList } from './pages/informations'
import { IpAddressCreate, IpAddressEdit, IpAddressList } from './pages/ipAddresses'
import { Login, OneTimePasswordAuth } from './pages/login'
import {
  ManagementUserCreate,
  ManagementUserEdit,
  ManagementUserList,
  ManagementUserPasswordChange,
} from './pages/managementUsers'
import { SponsorCreate, SponsorEdit, SponsorList } from './pages/sponsors'
import './http-interceptor'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { makeToastProps } from './utils/toast'

// react-queryのコンソールログ出力を抑制
setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
})

const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    const ipAddress = await getUserIp()

    try {
      const client = new GraphQLClient(GRAPHQL_ENDPOINT, { method: 'post' })
      const { query, variables } = gql.mutation({
        operation: 'loginManagementUser',
        variables: {
          input: {
            value: {
              email: email,
              password: password,
              ipAddress: ipAddress,
            },
            type: 'LoginManagementUserInput!',
          },
        },
        fields: [
          { user: ['id', 'userName', 'email', 'authorities', 'isActive'] },
          'accessToken',
          'refreshToken',
          'isAuthorizedIp',
        ],
      })

      const response = await client.request(query, variables)
      localStorage.setItem('currentUser', JSON.stringify({ ...response.loginManagementUser?.user, ip: ipAddress }))

      if (!response.loginManagementUser?.isAuthorizedIp) {
        sessionStorage.setItem('tryLogin', 'true')
        return Promise.resolve('/login/otp')
      }

      localStorage.setItem('accessToken', response.loginManagementUser?.accessToken)
      localStorage.setItem('refreshToken', response.loginManagementUser?.refreshToken)

      datadogLogs.logger.info(`[login] ${response.loginManagementUser?.user.email}`)

      return Promise.resolve()
    } catch (error) {
      return Promise.reject({ name: 'ログインエラー', message: 'ログインに失敗しました' })
    }
  },
  logout: async () => {
    await clearAuth()
      .then(() => {
        return Promise.resolve()
      })
      .catch(() => {
        return Promise.reject()
      })
  },
  checkError: async (error) => {
    if (error?.message === '再ログインしてください') {
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: async () => {
    // ワンタイムパスワード入力画面ではAuthチェックしない
    if (window.location?.href?.includes('otp')) return Promise.resolve()

    // トークン、ユーザー情報が存在しない場合はログアウト
    const user = localStorage.getItem('currentUser')
    const token = localStorage.getItem('accessToken')
    if (!token || !user) {
      await clearAuth().catch(() => {})
      return Promise.reject()
    }

    // ログインユーザが無効化されている場合はログアウト
    const currentUser = JSON.parse(user)
    if (!currentUser.isActive) {
      datadogLogs.logger.error(`[Disabled user] ${currentUser.email}`)
      await clearAuth().catch(() => {})
      toast.error('ユーザー情報の検証に失敗したためログアウトしました', {
        toastId: 'invalidUserError',
      })
      return Promise.reject()
    }

    // ページ遷移時にIPアドレスが変更されていないかチェック（ログインページを除く）
    if (!window.location?.href?.includes('login')) {
      const ipAddress = await getUserIp()
      if (currentUser.ip && currentUser.ip !== ipAddress) {
        datadogLogs.logger.warn(`[Has changed IP address] user: ${currentUser.email}`)
        await clearAuth().catch(() => {})
        toast.error('ログイン時と異なる接続情報が検出されたためログアウトしました', {
          toastId: 'invalidIpError',
        })
        return Promise.reject()
      }
    }
    return Promise.resolve()
  },
  getPermissions: () => {
    const currentUser = localStorage.getItem('currentUser')
    if (currentUser) {
      const parsedUser = JSON.parse(currentUser)
      return Promise.resolve(parsedUser.authorities)
    }
    return Promise.reject(Error)
  },
  getUserIdentity: () => {
    const currentUser = localStorage.getItem('currentUser')
    if (currentUser) {
      const parsedUser = JSON.parse(currentUser)
      return Promise.resolve(parsedUser)
    }
    clearAuth().catch(() => {})
    return Promise.reject(Error)
  },
}

/**
 * 組み込みのdataProviderで処理すると「Error (status code: undefined)」が表示される
 * この表示を除去するため通知方式をカスタマイズする
 *
 * 本実装をするもととなったissue
 * @see https://github.com/rossoinc/VR-TotalReachEstimation-API/issues/839
 *
 * 「Error (status code: undefined)」が表示される理由（概要としては既存の実装が本来のエラー処理方法と異なるため）
 * @see https://refine.dev/docs/api-reference/core/providers/data-provider/#error-format
 */
const notificationProvider: NotificationProvider = {
  open: ({ message, description, key, type }) => {
    const [toastId, toastMessage] = makeToastProps(message, description, key, type)
    if (type === 'success') {
      toast.success(toastMessage, {
        toastId: `${toastId}`,
      })
    } else if (type === 'error') {
      toast.error(toastMessage, {
        toastId: `${toastId}`,
      })
    } else {
      toast.info(toastMessage, {
        toastId: `${toastId}`,
      })
    }
  },
  close: () => {},
}

const App: React.FC = () => {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }
  return (
    <ConfigProvider locale={jaJP}>
      <Refine
        Title={Title}
        LoginPage={Login}
        i18nProvider={i18nProvider}
        Header={Header}
        Sider={Sider}
        Layout={Layout}
        DashboardPage={Dashboard}
        notificationProvider={notificationProvider}
        reactQueryDevtoolConfig={false}
        catchAll={<ErrorComponent />}
        reactQueryClientConfig={{
          defaultOptions: {
            queries: {
              retry: false,
              refetchOnWindowFocus: false,
              staleTime: 0,
            },
          },
        }}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <OneTimePasswordAuth />,
              path: '/login/otp',
            },
            {
              element: <ManagementUserPasswordChange />,
              path: '/managementUsers/edit/password',
            },
            {
              element: <AccountUserPasswordChange />,
              path: '/accountUsers/edit/password',
            },
            {
              element: (
                <LayoutWrapper>
                  <ErrorComponent />
                </LayoutWrapper>
              ),
              path: '*',
            },
          ] as typeof routerProvider.routes,
        }}
        authProvider={authProvider}
        dataProvider={gqlDataProvider}
        resources={[
          {
            name: 'campaigns',
            list: CampaignList,
            create: CampaignCreate,
            edit: CampaignEdit,
            canDelete: true,
            icon: <Icons.AreaChartOutlined />,
          },
          {
            name: 'clients',
            list: ClientList,
            create: ClientCreate,
            edit: ClientEdit,
            canDelete: true,
            icon: <Icons.BulbOutlined />,
          },
          {
            name: 'sponsors',
            list: SponsorList,
            create: SponsorCreate,
            edit: SponsorEdit,
            canDelete: true,
            icon: <Icons.ShopOutlined />,
          },
          {
            name: 'brands',
            list: BrandList,
            create: BrandCreate,
            edit: BrandEdit,
            canDelete: true,
            icon: <Icons.ShoppingOutlined />,
          },
          {
            name: 'accountUsers',
            list: AccountUserList,
            create: AccountUserCreate,
            edit: AccountUserEdit,
            canDelete: true,
            icon: <Icons.TeamOutlined />,
          },

          {
            name: 'accountUserLicenses',
            edit: AccountUserLicenseEdit,
            icon: <Icons.IdcardOutlined />,
          },
          {
            name: 'informations',
            list: InformationList,
            create: InformationCreate,
            edit: InformationEdit,
            canDelete: true,
            icon: <Icons.AlertOutlined />,
          },
          {
            name: 'faqGroups',
            list: FaqGroupList,
            create: FaqGroupCreate,
            edit: FaqGroupEdit,
            canDelete: true,
            icon: <Icons.QuestionCircleOutlined />,
          },
          {
            name: 'managementUsers',
            list: ManagementUserList,
            create: ManagementUserCreate,
            edit: ManagementUserEdit,
            canDelete: true,
            icon: <Icons.UserOutlined />,
          },
          {
            name: 'ipAddresses',
            list: IpAddressList,
            create: IpAddressCreate,
            edit: IpAddressEdit,
            canDelete: true,
            icon: <Icons.GlobalOutlined />,
          },
          {
            name: 'campaignDownload',
            list: CampaignDownload,
          },
        ]}
      >
        <ToastContainer />
      </Refine>
    </ConfigProvider>
  )
}

if (process.env.NODE_ENV === 'test') {
  exports.authProvider = authProvider
}

export default App
