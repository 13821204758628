import { Edit, Form, Input, ListButton, useForm } from '@pankod/refine-antd'
import { useRouterContext, IResourceComponentsProps, usePermissions, useList, useNavigation } from '@pankod/refine-core'
import { ISponsor } from 'interfaces'
import React, { useState } from 'react'
import { required, pattern, maxLength, frontBlankTrim, rearBlankTrim } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const SponsorEdit: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  const [duplicatedSponsorCd, setDuplicatedSponsorCd] = useState(false)
  const { form, formProps, saveButtonProps, queryResult } = useForm<ISponsor>({
    metaData: {
      fields: ['id', 'sponsorName', 'sponsorCd'],
    },
  })

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('sponsors')
  const record = queryResult?.data?.data

  const sponsorQueryResult = useList<ISponsor>({
    resource: 'sponsors',
    metaData: {
      fields: ['sponsorCd'],
      pageSize: 9999999,
    },
  })
  const existsSponsorCd = sponsorQueryResult.data?.data
    .filter((sponsor) => sponsor.sponsorCd !== record?.sponsorCd)
    .map((sponsor) => sponsor.sponsorCd)

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('SPONSOR') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  const onClickSave = () => {
    if (duplicatedSponsorCd) {
      return
    }
    form?.submit()
  }

  return (
    <>
      <Helmet>
        <title>{`広告主 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`広告主 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={{ ...saveButtonProps, onClick: onClickSave }}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="sponsors" />,
        }}
        resource="sponsors"
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 18 }}
          colon={false}
        >
          <Form.Item
            label="広告主コード"
            name="sponsorCd"
            validateStatus={duplicatedSponsorCd ? 'error' : 'success'}
            help={duplicatedSponsorCd ? <span style={{ color: 'red' }}>この広告主コードは登録済です</span> : null}
            rules={[
              required(),
              pattern(/^[a-zA-Z0-9]{5}$/, '5文字の半角英数字で入力してください'),
              frontBlankTrim(),
              rearBlankTrim(),
            ]}
          >
            <Input
              maxLength={5}
              placeholder="広告主コード"
              onChange={(e: any) => {
                if (e.target.value !== '' && existsSponsorCd?.includes(e.target.value)) {
                  setDuplicatedSponsorCd(true)
                } else {
                  setDuplicatedSponsorCd(false)
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="広告主"
            name="sponsorName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={100} showCount placeholder="広告主" />
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}
