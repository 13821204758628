import { Edit, Form, Input, ListButton, Radio, useForm } from '@pankod/refine-antd'
import { useRouterContext, IResourceComponentsProps, usePermissions, useNavigation } from '@pankod/refine-core'
import { IIpAddress } from 'interfaces'
import React from 'react'
import { required, maxLength, frontBlankTrim, rearBlankTrim, isIpAddressEnabled } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const IpAddressEdit: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  const { formProps, saveButtonProps, queryResult } = useForm<IIpAddress>({
    metaData: {
      fields: ['id', 'companyName', 'ipAddress', 'isActive'],
    },
  })

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('IP_ADDRESS') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !queryResult?.data?.data)
  ) {
    return <ErrorComponent />
  }

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('ipAddresses')

  return (
    <>
      <Helmet>
        <title>{`IPアドレス管理 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`IPアドレス管理 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="ipAddresses" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 3, lg: 4, md: 4, sm: 4 }}
          wrapperCol={{ xxl: 8, xl: 10, lg: 20, md: 20, sm: 20 }}
          colon={false}
        >
          <Form.Item
            label="企業名称"
            name="companyName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={100} showCount placeholder="企業名称" />
          </Form.Item>
          <Form.Item
            label={'IPアドレス'}
            name={'ipAddress'}
            rules={[
              required(),
              () => ({
                validator(_, value) {
                  if (value === '' || isIpAddressEnabled(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(<span style={{ color: 'red' }}>IPアドレスの形式で入力してください</span>)
                },
              }),
            ]}
          >
            <Input maxLength={20} placeholder="xxx.xxx.xxx.xxx" />
          </Form.Item>
          <Form.Item label="状態" name="isActive" rules={[required('選択してください')]}>
            <Radio.Group>
              <Radio value={true}>{'利用可'}</Radio>
              <Radio value={false}>{'利用停止'}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}
