import { useState } from 'react'
import { Button, Radio, Transfer, Edit, ListButton, Form, Input, useForm, useSelect } from '@pankod/refine-antd'
import { useRouterContext, usePermissions, useNavigation, IResourceComponentsProps } from '@pankod/refine-core'
import { IManagementUser, IAuthority } from 'interfaces'
import React, { useEffect } from 'react'
import { required, maxLength, frontBlankTrim, rearBlankTrim } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const ManagementUserEdit: React.FC<IResourceComponentsProps> = () => {
  const { push, list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  const { formProps, saveButtonProps, queryResult } = useForm<IManagementUser>({
    metaData: {
      fields: ['id', 'userName', 'email', 'department', 'authorities', 'label', 'isActive'],
    },
  })
  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('managementUsers')
  const record = queryResult?.data?.data
  const [targetKeys, setTargetKeys] = useState<string[]>()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  useEffect(() => {
    setTargetKeys(record?.authorities ?? [])
  }, [record])

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys)
  }

  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  const { selectProps: authoritySelectProps } = useSelect<IAuthority>({
    resource: 'authorities',
    optionLabel: 'authorityName',
    optionValue: 'authorityKey',
    metaData: {
      fields: ['authorityKey', 'authorityName'],
      pageSize: 9999999,
    },
  })

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('MANAGEMENT_USER') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  const authorities = authoritySelectProps?.options?.map((option) => {
    return {
      key: option.value,
      title: option.label,
    } as any
  })

  const filterOption = (inputValue: string, option: any) => option.title.indexOf(inputValue) > -1

  return (
    <>
      <Helmet>
        <title>管理ユーザー | {process.env.REACT_APP_SERVICE_NAME}</title>
        <meta name="description" content={`管理ユーザー | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          onBack: () => list('managementUsers'),
          extra: (
            <>
              <Button onClick={() => push('edit/password', { state: { id: record?.id, userName: record?.userName } })}>
                パスワード変更
              </Button>
              <ListButton data-testid="show-list-button" resourceName="managementUsers" />
            </>
          ),
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 18 }}
        >
          <Form.Item
            label="名前"
            name="userName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder="名前" maxLength={100} showCount autoComplete="new-password" />
          </Form.Item>
          <Form.Item label="所属" name="department" rules={[maxLength(100), frontBlankTrim(), rearBlankTrim()]}>
            <Input placeholder="所属" maxLength={100} showCount />
          </Form.Item>
          <Form.Item label="ラベル" name="label" rules={[maxLength(100), frontBlankTrim(), rearBlankTrim()]}>
            <Input placeholder="ラベル" maxLength={100} showCount />
          </Form.Item>
          <div>
            <Form.Item
              label={'権限'}
              name={'authorities'}
              rules={[required('一つ以上適用してください')]}
              labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
              wrapperCol={{ xxl: 10, xl: 14, lg: 18, md: 18, sm: 18 }}
            >
              {authorities && (
                <Transfer
                  dataSource={authorities}
                  showSearch={false}
                  locale={{
                    selectAll: 'すべて選択',
                    selectInvert: '反転する',
                  }}
                  titles={['未適用', '適用済']}
                  targetKeys={targetKeys}
                  selectedKeys={selectedKeys}
                  onChange={onChange}
                  onSelectChange={onSelectChange}
                  render={(item) => item.title}
                  filterOption={filterOption}
                  listStyle={{
                    width: 500,
                    height: 300,
                  }}
                />
              )}
            </Form.Item>
          </div>
          <Form.Item label="状態" name="isActive" rules={[required('選択してください')]}>
            <Radio.Group>
              <Radio value={true}>{'利用可'}</Radio>
              <Radio value={false}>{'利用停止'}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}
