import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generateDatePicker from 'antd/lib/date-picker/generatePicker'
import 'dayjs/locale/ja'

dayjs.locale('ja')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Asia/Tokyo')

export const DateTimePicker = generateDatePicker<Dayjs>(dayjsGenerateConfig)
