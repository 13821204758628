export const PASSWORD_REGEX = new RegExp(
  /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z!"#$%&'()*+,-./:;<=>?@‘–[\]^_`\\{|}~0-9]{8,16}$/i,
)

export const ONE_TIME_PASSWORD_REGEX = new RegExp(/^[0-9]{6}$/i)

export const LOGINID_REGEX = new RegExp(/^[a-zA-Z!"#$%&'()*+,-./:;<=>?@[\]^_`\\{|}~0-9]{4,16}$/i)

export const IP_REGEX = new RegExp(
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/,
)

export const EMAIL_RFC5321_REGEX = new RegExp(
  // eslint-disable-next-line
  /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
)

export const HALF_WIDTH_ALPHANUMERIC = new RegExp(/^[a-zA-Z0-9]*$/)
