import datadogLogs from 'logger'
import { CrudFilters, CrudSorting, DataProvider } from '@pankod/refine-core'
import camelCase from 'camelcase'
import * as gql from 'gql-query-builder'
import { GraphQLClient } from 'graphql-request'
import pluralize from 'pluralize'
import { clearAuth } from 'utils'
import { getClient } from './auth'
import { is, toUpperCase } from './utils/functions'

const genereteSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const sortQuery = sort.map((i) => {
      return `${i.field}:${i.order}`
    })

    return sortQuery.join()
  }

  return []
}

export const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: any } = {}
  if (filters) {
    filters.map((filter) => {
      if (filter.operator !== 'or') {
        let { field, operator, value } = filter
        if (!is('String', value)) value = value.map((v: string) => v.replace(/'/g, '&apos;'))
        else value = value.replace(/'/g, '&apos;')
        queryFilters[`${field}_${operator}`] = value
      } else {
        const { value } = filter
        const orFilters: any[] = []
        value.map((val) => {
          orFilters.push({
            [`${val.field}_${val.operator}`]: val.value,
          })
        })

        queryFilters['_or'] = orFilters
      }
    })
  }

  return queryFilters
}

const dataProvider = (): DataProvider => {
  return {
    getList: async ({ resource, sort, filters, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })
      const current = metaData?.current || 1
      const pageSize = metaData?.pageSize || 10

      const sortBy = genereteSort(sort)
      const filterBy = generateFilter(filters)

      const camelResource = camelCase(resource)

      const operation = metaData?.operation ?? camelResource
      const { query, variables } = gql.query({
        operation,
        variables: {
          ...metaData?.variables,
          sort: sortBy,
          where: { value: filterBy, type: 'String' },
          offset: (current - 1) * pageSize,
          limit: pageSize,
        },
        fields: [{ values: metaData?.fields }, 'totalCount'],
      })
      variables.where = JSON.stringify(variables.where)
      try {
        const response = await client.request(query, variables)
        return {
          data: response[operation].values,
          total: response[operation].totalCount,
        }
      } catch (error: any) {
        if (error.message.indexOf('record not found') > -1) {
          return {
            data: [],
            total: 0,
          }
        } else {
          datadogLogs.logger.error(`[ResponseError] ${error}`)
          const message = error?.response?.errors.map((e: any) => e.message).join()
          if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
          throw Error(message ?? 'データ取得に失敗しました')
        }
      }
    },

    getMany: async ({ resource, ids, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const camelResource = camelCase(resource)
      const operation = metaData?.operation ?? camelResource

      const { query, variables } = gql.query({
        operation,
        variables: {
          where: {
            value: { id_in: ids },
            type: 'String',
          },
        },
        fields: metaData?.fields,
      })

      variables.where = JSON.stringify(variables.where)

      try {
        const response = await client.request(query, variables)
        return {
          data: response[operation],
        }
      } catch (error: any) {
        if (error.message.indexOf('record not found') > -1) {
          return {
            data: [],
          }
        } else {
          datadogLogs.logger.error(`[ResponseError] ${error}`)
          const message = error?.response?.errors.map((e: any) => e.message).join()
          if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
          throw Error(message ?? 'データ取得に失敗しました')
        }
      }
    },

    create: async ({ resource, variables, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const singularResource = pluralize.singular(resource)
      const operation = `create${toUpperCase(metaData?.operation ?? singularResource)}`

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          input: {
            value: variables,
            type: `Create${toUpperCase(singularResource)}Input!`,
          },
        },
        fields: metaData?.fields ?? ['id'],
      })

      if (operation === 'createAccountUser' || operation === 'createManagementUser') {
        delete gqlVariables.input.confirmPassword
      }

      try {
        const response = await client.request(query, gqlVariables)
        return {
          data: response[operation][singularResource],
        }
      } catch (error: any) {
        datadogLogs.logger.error(`[ResponseError] ${error}`)
        const message = error?.response?.errors.map((e: any) => e.message).join()
        if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
        throw Error(message ?? 'データ作成に失敗しました')
      }
    },

    createMany: async ({ resource, variables, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const singularResource = pluralize.singular(resource)
      const camelCreateName = camelCase(`create-${singularResource}`)

      const operation = metaData?.operation ?? camelCreateName

      try {
        const response = await Promise.all(
          variables.map(async (param) => {
            const { query, variables: gqlVariables } = gql.mutation({
              operation,
              variables: {
                input: {
                  value: { data: param },
                  type: `${camelCreateName}Input`,
                },
              },
              fields: metaData?.fields ?? [
                {
                  operation: singularResource,
                  fields: ['id'],
                  variables: {},
                },
              ],
            })
            const result = await client.request(query, gqlVariables)
            return result[operation][singularResource]
          }),
        )
        return {
          data: response,
        }
      } catch (error: any) {
        datadogLogs.logger.error(`[ResponseError] ${error}`)
        const message = error?.response?.errors.map((e: any) => e.message).join()
        if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
        throw Error(message ?? 'データ作成に失敗しました')
      }
    },

    update: async ({ resource, id, variables, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const singularResource = pluralize.singular(resource)
      const upperResourceName = toUpperCase(singularResource)
      const operation = `update${toUpperCase(metaData?.operation ?? singularResource)}`
      const values = upperResourceName === 'Campaign' ? variables : { id: id, ...variables }
      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          input: {
            value: values,
            type: `Update${upperResourceName}Input!`,
          },
        },
        fields: metaData?.fields ?? ['id'],
      })

      try {
        const response = await client.request(query, gqlVariables)
        return {
          data: response[operation].id,
        }
      } catch (error: any) {
        datadogLogs.logger.error(`[ResponseError] ${error}`)
        const message = error?.response?.errors.map((e: any) => e.message).join()
        if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
        throw Error(message ?? 'データ更新に失敗しました')
      }
    },

    updateMany: async ({ resource, ids, variables, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const singularResource = pluralize.singular(resource)
      const camelUpdateName = camelCase(`update-${singularResource}`)

      const operation = metaData?.operation ?? camelUpdateName

      try {
        const response = await Promise.all(
          ids.map(async (id) => {
            const { query, variables: gqlVariables } = gql.mutation({
              operation,
              variables: {
                input: {
                  value: { where: { id }, data: variables },
                  type: `${camelUpdateName}Input`,
                },
              },
              fields: metaData?.fields ?? [
                {
                  operation: singularResource,
                  fields: ['id'],
                  variables: {},
                },
              ],
            })
            const result = await client.request(query, gqlVariables)
            return result[operation][singularResource]
          }),
        )
        return {
          data: response,
        }
      } catch (error: any) {
        datadogLogs.logger.error(`[ResponseError] ${error}`)
        const message = error?.response?.errors.map((e: any) => e.message).join()
        if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
        throw Error(message ?? 'データ更新に失敗しました')
      }
    },

    getOne: async ({ resource, id, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const singularResource = pluralize.singular(resource)
      const operation = metaData?.operation ?? singularResource

      const { query, variables } = gql.query({
        operation,
        variables: {
          id: { value: id, type: 'ID', required: true },
        },
        fields: metaData?.fields,
      })

      try {
        const response = await client.request(query, variables)
        return {
          data: response[operation],
        }
      } catch (error: any) {
        if (error.message.indexOf('record not found') > -1) {
          return {
            data: [],
          }
        } else {
          datadogLogs.logger.error(`[ResponseError] ${error}`)
          const message = error?.response?.errors.map((e: any) => e.message).join()
          if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
          throw Error(message ?? 'データ更新に失敗しました')
        }
      }
    },

    deleteOne: async ({ resource, id }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const singularResource = pluralize.singular(resource)
      const upperResourceName = toUpperCase(singularResource)
      const operation = `delete${toUpperCase(singularResource)}`

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          input: {
            value: { id: id },
            type: `Delete${upperResourceName}Input!`,
          },
        },
      })

      try {
        const response = await client.request(query, variables)
        return {
          data: response[operation],
        }
      } catch (error: any) {
        datadogLogs.logger.error(`[ResponseError] ${error}`)
        const message = error?.response?.errors.map((e: any) => e.message).join()
        if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
        throw Error(message ?? 'データ削除に失敗しました')
      }
    },

    deleteMany: async ({ resource, ids, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        throw Error('再ログインしてください')
      })

      const singularResource = pluralize.singular(resource)
      const camelDeleteName = camelCase(`delete-${singularResource}`)

      const operation = metaData?.operation ?? camelDeleteName

      try {
        const response = await Promise.all(
          ids.map(async (id) => {
            const { query, variables: gqlVariables } = gql.mutation({
              operation,
              variables: {
                input: {
                  value: { where: { id } },
                  type: `${camelDeleteName}Input`,
                },
              },
              fields: metaData?.fields ?? [
                {
                  operation: singularResource,
                  fields: ['id'],
                  variables: {},
                },
              ],
            })
            const result = await client.request(query, gqlVariables)

            return result[operation][singularResource]
          }),
        )
        return {
          data: response,
        }
      } catch (error: any) {
        datadogLogs.logger.error(`[ResponseError] ${error}`)
        const message = error?.response?.errors.map((e: any) => e.message).join()
        if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
        throw Error(message ?? 'データ削除に失敗しました')
      }
    },

    getApiUrl: () => {
      throw Error('Not implemented on refine-graphql data provider.')
    },

    custom: async ({ url, method, headers, metaData }) => {
      const client: GraphQLClient = await getClient().catch(() => {
        return new GraphQLClient(url, { headers, method })
      })
      if (metaData) {
        if (metaData.operation) {
          if (method === 'get') {
            const { query, variables } = gql.query({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            })

            try {
              const response = await client.request(query, variables)

              return {
                data: response[metaData.operation],
              }
            } catch (error: any) {
              if (error.response?.status === 200) {
                // GraphQL仕様によるエラー。データ取得はできているためそのまま使う。
                if (error.response?.errors?.[0]?.message?.indexOf('Float cannot represent non numeric value') > -1) {
                  return { data: error.response.data }
                } else if (error.response?.errors?.[0]?.message?.indexOf('not found') > -1) {
                  return { data: null }
                }
              }
              datadogLogs.logger.error(`[ResponseError] ${error}`)
              const message = error?.response?.errors.map((e: any) => e.message).join()
              if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
              throw Error(message ?? 'データ処理に失敗しました')
            }
          } else {
            const { query, variables } = gql.mutation({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            })

            try {
              const response = await client.request(query, variables)

              return {
                data: response[metaData.operation],
              }
            } catch (error: any) {
              datadogLogs.logger.error(`[ResponseError] ${error}`)
              const message = error?.response?.errors.map((e: any) => e.message).join()
              if (message?.includes('アクセス権がありません')) clearAuth().catch(() => {})
              throw Error(message ?? 'データ処理に失敗しました')
            }
          }
        } else {
          throw Error('GraphQL operation name required.')
        }
      } else {
        throw Error('GraphQL need to operation, fields and variables values in metaData object.')
      }
    },
  }
}

export default dataProvider
