import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Descriptions,
  Form,
  Icons,
  Input,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
  useSelect,
} from '@pankod/refine-antd'
import { useCustom, useNotification } from '@pankod/refine-core'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import dayjs from 'dayjs'
import { ICampaign, IClient, IDepartment, ISponsor } from 'interfaces'
import { RetryEstimateModal } from 'pages/campaigns/RetryEstimateModal'
import React, { useEffect, useRef, useState } from 'react'
import { ellipsis, hasError, validatePasteValueLength } from 'utils'
const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DateTimePicker

interface Props {
  record: ICampaign | undefined
  gAds: {
    googleAdsIds: string[]
    setGoogleAdsIds: (v: string[]) => void
  }
  input: {
    inputCampaign: any
    updateInputCampaign: any
  }
  errors: any
}

export const BasicInfo: React.FC<Props> = React.memo((props) => {
  const { record, input, errors } = props
  const { open } = useNotification()
  const { googleAdsIds, setGoogleAdsIds } = props.gAds
  const { inputCampaign, updateInputCampaign } = input
  const [inputVisible, setInputVisible] = useState<boolean>(false)
  const [inputGAdsId, setInputGAdsId] = useState<string>('')
  const [retryEstimateModalVisible, setRetryEstimateModalVisible] = useState(false)
  const inputRef = useRef<any>(null)

  // 依頼会社を取得
  const { selectProps: clientSelectProps, queryResult: clientQueryResult } = useSelect<IClient>({
    resource: 'clients',
    optionLabel: 'clientName',
    optionValue: 'id',
    filters: [{ field: 'id', operator: 'ne', value: 'Q2xpZW50OjE=' }], // 事務局除外
    metaData: {
      fields: ['id', 'clientName'],
      pageSize: 9999999,
    },
  })

  // スポンサーを取得
  const { selectProps: sponsorSelectProps, queryResult: sponsorQueryResult } = useSelect<ISponsor>({
    resource: 'sponsors',
    optionLabel: 'sponsorName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'sponsorName'],
      pageSize: 9999999,
    },
  })

  // 部署を取得
  const { data: departments } = useCustom<[IDepartment]>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'departments',
    },
  })

  // Google広告アカウントID操作
  useEffect(() => {
    // ボタンからインプット表示に切り替わった際にフォーカスする
    if (inputVisible && inputRef) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  // タグにIDを追加してID入力欄を追加ボタンに戻す
  const handleInputConfirm = () => {
    if (inputGAdsId && googleAdsIds.indexOf(inputGAdsId) === -1) {
      setGoogleAdsIds([...googleAdsIds, inputGAdsId])
    }
    setInputVisible(false)
    setInputGAdsId('')
  }
  // タグが閉じられたらstateから消す
  const handleClose = (removedTag: string) => {
    setGoogleAdsIds(googleAdsIds.filter((tag) => tag !== removedTag))
  }

  const getEstimateStatusLabel = () => {
    if (!record?.isEstimateExecuted || !record?.isIndexErrorOccurred) return '指標算出'
    if (record?.isIndexErrorOccurred) {
      return (
        <Space size={5}>
          指標算出
          <Button
            shape="round"
            size={'small'}
            style={{
              marginLeft: '4px',
              paddingLeft: '10px',
              paddingRight: '10px',
              fontSize: '11px',
              border: 'none',
              backgroundColor: '#108ee9',
              color: '#fff',
            }}
            onClick={() => setRetryEstimateModalVisible(true)}
          >
            <Space size={2}>
              <Icons.ReloadOutlined />
              <Text style={{ marginLeft: '2px', color: '#fff' }}>再実行</Text>
            </Space>
          </Button>
        </Space>
      )
    }
  }

  const EstimateStatus = () => {
    if (!record?.isEstimateExecuted) return <Tag>未実行</Tag>
    if (record?.isIndexErrorOccurred) {
      return (
        <Tag icon={<Icons.CloseCircleFilled />} color="error">
          エラー
        </Tag>
      )
    }
    // 実行済、エラーなし、表示可能である場合に成功とみなす
    if (record?.isIndexDisplayable) {
      return (
        <Tag icon={<Icons.CheckCircleFilled />} color="cyan">
          成功
        </Tag>
      )
    }
    return <Tag>未実行</Tag>
  }

  return (
    <>
      {/* ********** 案件情報 ********** */}
      <Card
        size="small"
        title={
          <Title level={5} style={{ color: 'white' }}>
            案件情報
          </Title>
        }
        style={{ marginTop: '20px' }}
        headStyle={{ backgroundColor: '#7c4a85' }}
      >
        <div style={{ overflowX: 'scroll' }}>
          {/* Descriptionsタグ内のForm項目は無効となる。表示用として使用する */}
          <Descriptions bordered size="small" column={2} style={{ minWidth: '860px' }}>
            <Form.Item label="キャンペーンID">
              <Input placeholder={'キャンペーンID'} value={inputCampaign.campaignId} disabled maxLength={200} />
            </Form.Item>

            <Form.Item label="VCID">
              <Input placeholder={'VCID'} value={inputCampaign.vcid} disabled maxLength={200} />
            </Form.Item>

            <Form.Item
              label={
                <Space size={5}>
                  依頼会社
                  <Button
                    type={'link'}
                    size={'small'}
                    style={{ padding: '0px' }}
                    target="_blank"
                    href="/clients/create"
                  >
                    <Icons.PlusOutlined />
                  </Button>
                  <Button
                    type={'link'}
                    size={'small'}
                    style={{ padding: '0px' }}
                    onClick={() => {
                      try {
                        clientQueryResult.refetch()
                        open({
                          message: '選択項目を更新しました',
                          key: 'success',
                          type: 'success',
                        })
                      } catch {
                        open({
                          message: '選択項目の更新に失敗しました',
                          key: 'error',
                          type: 'error',
                        })
                      }
                    }}
                  >
                    <Icons.ReloadOutlined />
                  </Button>
                </Space>
              }
              initialValue={inputCampaign.clientId}
            >
              <div
                data-testId="test-paste-div"
                onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}
              >
                <Select
                  filterOption={clientSelectProps.filterOption}
                  loading={clientSelectProps.loading}
                  onSearch={clientSelectProps.onSearch}
                  style={
                    errors.clientId.required
                      ? { border: '1px solid red', borderRadius: '6px', width: '100%' }
                      : { width: '100%' }
                  }
                  showArrow
                  showSearch
                  disabled={record?.basicInfo?.confirmationStatus === 'COMPLETE' ? true : false}
                  placeholder={'選択'}
                  value={inputCampaign.clientId}
                  onChange={(e) => updateInputCampaign('clientId', e)}
                >
                  {clientSelectProps.options &&
                    clientSelectProps.options.map((d: any) => {
                      return (
                        <Select.Option key={d.value} value={d.value}>
                          <Tooltip placement="top" title={d.label}>
                            {ellipsis(d.label, 20)}
                          </Tooltip>
                        </Select.Option>
                      )
                    })}
                </Select>
              </div>
              {errors.clientId.required && <span style={{ color: 'red' }}>選択してください</span>}
            </Form.Item>

            <Form.Item label="依頼主部署">
              <Input
                placeholder={'依頼主部署'}
                maxLength={200}
                showCount
                style={hasError(errors.clientDepartment) ? { border: '1px solid red' } : {}}
                value={inputCampaign.clientDepartment}
                onChange={(e) => {
                  updateInputCampaign('clientDepartment', e)
                }}
              />
              {errors.clientDepartment.required && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>入力してください</span>
                  <br />
                </div>
              )}
              {errors.clientDepartment.requiredFrontTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行頭に空白は使えません</span>
                  <br />
                </div>
              )}
              {errors.clientDepartment.requiredRearTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行末に空白は使えません</span>
                  <br />
                </div>
              )}
            </Form.Item>

            <Form.Item label="キャンペーン名">
              <Input
                style={hasError(errors.campaignName) ? { border: '1px solid red' } : {}}
                showCount
                placeholder={'キャンペーン名'}
                maxLength={200}
                value={inputCampaign.campaignName}
                onChange={(e) => updateInputCampaign('campaignName', e)}
              />
              {errors.campaignName.required && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>入力してください</span>
                  <br />
                </div>
              )}
              {errors.campaignName.requiredFrontTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行頭に空白は使えません</span>
                  <br />
                </div>
              )}
              {errors.campaignName.requiredRearTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行末に空白は使えません</span>
                  <br />
                </div>
              )}
            </Form.Item>

            <Form.Item label={'VR営業担当部署'} initialValue={inputCampaign.salesDepartmentId}>
              <Select
                style={
                  hasError(errors.salesDepartmentId)
                    ? { border: '1px solid red', borderRadius: '6px', width: '100%' }
                    : { width: '100%' }
                }
                placeholder={'選択'}
                value={inputCampaign.salesDepartmentId}
                onSelect={(salesDepartmentId: any) => updateInputCampaign('salesDepartmentId', salesDepartmentId)}
              >
                {departments &&
                  departments.data.map((d: any) => {
                    return (
                      <Option key={d.id} value={d.id}>
                        {d.department_name}
                      </Option>
                    )
                  })}
              </Select>
              {errors.salesDepartmentId.required && <span style={{ color: 'red' }}>選択してください</span>}
            </Form.Item>

            <Form.Item label="広告主名(顧客入力)">
              <Input
                placeholder={'広告主名(顧客入力)'}
                maxLength={200}
                showCount
                style={hasError(errors.sponsorNameFromCustomer) ? { border: '1px solid red' } : {}}
                value={inputCampaign.sponsorNameFromCustomer}
                onChange={(e) => updateInputCampaign('sponsorNameFromCustomer', e)}
              />
              {errors.sponsorNameFromCustomer?.required && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>入力してください</span>
                  <br />
                </div>
              )}
              {errors.sponsorNameFromCustomer?.requiredFrontTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行頭に空白は使えません</span>
                  <br />
                </div>
              )}
              {errors.sponsorNameFromCustomer?.requiredRearTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行末に空白は使えません</span>
                  <br />
                </div>
              )}
            </Form.Item>

            <Form.Item label="商品名">
              <Input
                placeholder={'商品名'}
                maxLength={100}
                showCount
                style={hasError(errors.productName) ? { border: '1px solid red' } : {}}
                value={inputCampaign.productName}
                onChange={(e) => updateInputCampaign('productName', e)}
              />
              {errors.productName?.required && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>入力してください</span>
                  <br />
                </div>
              )}
              {errors.productName?.requiredFrontTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行頭に空白は使えません</span>
                  <br />
                </div>
              )}
              {errors.productName?.requiredRearTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行末に空白は使えません</span>
                  <br />
                </div>
              )}
            </Form.Item>

            <Form.Item
              initialValue={inputCampaign.sponsorId}
              label={
                <Space size={5}>
                  広告主
                  <Button
                    type={'link'}
                    size={'small'}
                    style={{ padding: '0px' }}
                    target="_blank"
                    href="/sponsors/create"
                  >
                    <Icons.PlusOutlined />
                  </Button>
                  <Button
                    type={'link'}
                    size={'small'}
                    style={{ padding: '0px' }}
                    onClick={() => {
                      try {
                        sponsorQueryResult.refetch()
                        open({
                          message: '選択項目を更新しました',
                          key: 'success',
                          type: 'success',
                        })
                      } catch {
                        open({
                          message: '選択項目の更新に失敗しました',
                          key: 'error',
                          type: 'error',
                        })
                      }
                    }}
                  >
                    <Icons.ReloadOutlined />
                  </Button>
                </Space>
              }
            >
              <div
                data-testId={'test-paste-div'}
                onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}
              >
                <Select
                  filterOption={sponsorSelectProps.filterOption}
                  loading={sponsorSelectProps.loading}
                  onSearch={sponsorSelectProps.onSearch}
                  showArrow
                  showSearch
                  placeholder={'選択'}
                  style={
                    hasError(errors.sponsorId)
                      ? { border: '1px solid red', borderRadius: '6px', width: '100%' }
                      : { width: '100%' }
                  }
                  value={inputCampaign.sponsorId}
                  onChange={(e) => updateInputCampaign('sponsorId', e)}
                >
                  {sponsorSelectProps.options &&
                    sponsorSelectProps.options.map((d: any) => {
                      return (
                        <Select.Option key={d.value} value={d.value}>
                          <Tooltip placement="top" title={d.label}>
                            {ellipsis(d.label, 20)}
                          </Tooltip>
                        </Select.Option>
                      )
                    })}
                </Select>
              </div>
              {errors.sponsorId.required && <span style={{ color: 'red', width: '100%' }}>選択してください</span>}
            </Form.Item>

            <Form.Item label="sub-account ID">
              <Input
                value={inputCampaign.subAccountId}
                disabled
                style={hasError(errors.subAccountId) ? { border: '1px solid red' } : {}}
                maxLength={200}
              />
              {errors.subAccountId.required && (
                <span style={{ color: 'red', width: '100%' }}>取得に失敗しています。管理者にお問い合わせください</span>
              )}
            </Form.Item>

            <Descriptions.Item label="期間">
              <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
                <RangePicker
                  locale={locale}
                  format="YYYY/MM/DD"
                  placeholder={['開始日時', '終了日時']}
                  style={hasError(errors.campaignPeriod) ? { border: '1px solid red', borderRadius: '6px' } : {}}
                  value={
                    inputCampaign.campaignPeriod
                      ? [dayjs(inputCampaign.campaignPeriod[0]), dayjs(inputCampaign.campaignPeriod[1])]
                      : null
                  }
                  onChange={(value: any) => {
                    if (!value) {
                      updateInputCampaign('campaignPeriod', null)
                    } else {
                      updateInputCampaign('campaignPeriod', [
                        value[0].format('YYYY-MM-DDT00:00:00'),
                        value[1].format('YYYY-MM-DDT00:00:00'),
                      ])
                    }
                  }}
                />
              </div>
              {errors.campaignPeriod.required && (
                <span style={{ color: 'red', marginLeft: '10px', width: '100%' }}>入力してください</span>
              )}
            </Descriptions.Item>

            <Form.Item label={getEstimateStatusLabel()}>
              <EstimateStatus />
            </Form.Item>

            <Form.Item
              label="Google広告アカウントID"
              labelCol={{ xxl: 15, xl: 12, lg: 15, md: 15, sm: 11 }}
              name={['inputCampaign', 'googleAdsAccountIds']}
            >
              <Space>
                {inputVisible && (
                  <Input
                    ref={inputRef}
                    type="text"
                    pattern="^[0-9A-Za-z]+$"
                    placeholder="英数字・ハイフンのみ"
                    size="middle"
                    showCount
                    maxLength={20}
                    style={{ width: 200 }}
                    value={inputGAdsId}
                    onChange={(e) => {
                      let GAdsId = e.target.value.trim().replace(/[^\-0-9a-z]/gi, '')
                      if (GAdsId === '') {
                        setInputVisible(false)
                        setInputGAdsId('')
                        return
                      }
                      if (GAdsId) setInputGAdsId(GAdsId)
                    }}
                    onBlur={() => handleInputConfirm()}
                    onPressEnter={() => handleInputConfirm()}
                  />
                )}
                {!inputVisible && (
                  <Tag
                    data-testId="tag-test-id"
                    onClick={() => setInputVisible(true)}
                    style={{ borderStyle: 'dashed', width: 90, textAlign: 'center' }}
                  >
                    <PlusOutlined /> 追加
                  </Tag>
                )}
                <div
                  style={{
                    border: 'solid 1px #d9d9d9',
                    borderRadius: '6px',
                    overflowX: 'auto',
                    maxHeight: '52px',
                    minHeight: '32px',
                    minWidth: '500px',
                    maxWidth: '700px',
                    background: '#f5f5f5',
                  }}
                >
                  <div style={{ display: 'flex', padding: '0px 4px' }}>
                    {googleAdsIds.map((tag, index) => {
                      return (
                        <Tag
                          data-testId={'test-tag-btn'}
                          closable
                          key={index}
                          onClose={(e) => {
                            // これがないと2つ連続で消える
                            e.preventDefault()
                            handleClose(tag)
                          }}
                          color="geekblue"
                          style={{ whiteSpace: 'nowrap', margin: '4px 4px' }}
                        >
                          {tag}
                        </Tag>
                      )
                    })}
                  </div>
                </div>
              </Space>
            </Form.Item>
          </Descriptions>
        </div>

        {/* APIリクエストに使われるForm項目 */}
        <Form.Item name={['basicInfo', 'clientId']} hidden />
        <Form.Item name={['basicInfo', 'clientDepartment']} hidden />
        <Form.Item name={['basicInfo', 'salesDepartmentId']} hidden />
        <Form.Item name={['basicInfo', 'campaignName']} hidden />
        <Form.Item name={['basicInfo', 'sponsorNameFromCustomer']} hidden />
        <Form.Item name={['basicInfo', 'productName']} hidden />
        <Form.Item name={['basicInfo', 'sponsorId']} hidden />
        <Form.Item name={['basicInfo', 'campaignFrom']} hidden />
        <Form.Item name={['basicInfo', 'campaignTo']} hidden />
        <Form.Item name={['basicInfo', 'googleAdsAccountIds']} hidden />
        <Form.Item name="isTemporary" hidden />
        <Form.Item name={['basicInfo', 'id']} hidden />
      </Card>
      {record && (
        <RetryEstimateModal
          record={record}
          retryEstimateModalVisible={retryEstimateModalVisible}
          setRetryEstimateModalVisible={setRetryEstimateModalVisible}
        />
      )}
    </>
  )
})
