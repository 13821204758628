import {
  Button,
  Card,
  Descriptions,
  EditButton,
  Form,
  Icons,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  useSelect,
} from '@pankod/refine-antd'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import dayjs from 'dayjs'
import locale from 'antd/lib/date-picker/locale/ja_JP'

import { ExtendCmMaterial, IAdArea, ICmMaterial } from 'interfaces'
import React, { useState } from 'react'
import { hasError, pattern, sortObjectsByBase64Id, validatePasteValueLength } from 'utils'
import { CreateCmMaterialModal } from './CreateCmMaterialModal'
import { EditCmMaterialModal } from './EditCmMaterialModal'

const { Title } = Typography
const { RangePicker } = DateTimePicker

interface Props {
  input: {
    inputCampaign: any
    updateInputCampaign: any
  }
  tvCmInput: {
    cmMaterials: ExtendCmMaterial[]
    setCmMaterials: (v: ExtendCmMaterial[]) => void
  }
  errors: any
}

export const CMInfo: React.FC<Props> = (props) => {
  const { input, tvCmInput, errors } = props
  const { inputCampaign, updateInputCampaign } = input
  const { cmMaterials, setCmMaterials } = tvCmInput
  const [isCreateCmModalVisible, setIsCreateCmModalVisible] = useState(false)
  const [isEditCmModalVisible, setIsEditCmModalVisible] = useState(false)
  const [currentCmMaterialRecord, setCurrentCmMaterialRecord] = useState<ExtendCmMaterial>()

  // TV放送波を取得
  const { selectProps: tvBroadcastWavesSelectProps } = useSelect<IAdArea>({
    resource: 'tvBroadcastWaves',
    optionLabel: 'tvBroadcastWaveName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'tvBroadcastWaveName'],
      pageSize: 9999999,
    },
  })

  const editModalShow = (record: ICmMaterial) => {
    setCurrentCmMaterialRecord(record)
    setIsEditCmModalVisible(true)
  }

  return (
    <>
      {/* ********** テレビCM情報 ********** */}
      <Card
        size="small"
        title={
          <Title level={5} style={{ color: 'white' }}>
            テレビCM情報
          </Title>
        }
        style={{ marginTop: '20px' }}
        headStyle={{ backgroundColor: '#7c4a85' }}
      >
        <div style={{ overflowX: 'scroll' }}>
          {/* Descriptionsタグ内のForm項目は無効となる。表示用として使用する */}
          <Descriptions bordered size="small" column={3} style={{ minWidth: '860px' }}>
            <Form.Item label="期間">
              <div
                data-testId={'test-paste-div'}
                onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}
              >
                <RangePicker
                  locale={locale}
                  format="YYYY/MM/DD"
                  placeholder={['開始日時', '終了日時']}
                  style={hasError(errors.tvCmPeriod) ? { border: '1px solid red', width: '100%' } : {}}
                  value={
                    inputCampaign.tvCmPeriod
                      ? [dayjs(inputCampaign.tvCmPeriod[0]), dayjs(inputCampaign.tvCmPeriod[1])]
                      : null
                  }
                  onChange={(value: any) => {
                    if (!value) {
                      updateInputCampaign('tvCmPeriod', null)
                    } else {
                      updateInputCampaign('tvCmPeriod', [
                        value[0].format('YYYY-MM-DDT00:00:00'),
                        value[1].format('YYYY-MM-DDT00:00:00'),
                      ])
                    }
                  }}
                />
              </div>
              {errors.tvCmPeriod.required && <span style={{ color: 'red', width: '100%' }}>入力してください</span>}
            </Form.Item>

            <Form.Item label="素材数" initialValue={inputCampaign.cmMaterialNumber} rules={[pattern('^[1-9][0-9]*$')]}>
              <InputNumber
                style={
                  hasError(errors.cmMaterialNumber) ? { border: '1px solid red', width: '100%' } : { width: '100%' }
                }
                placeholder={'素材数'}
                controls={false}
                maxLength={5}
                min={1}
                value={inputCampaign.cmMaterialNumber}
                onBlur={(e) => {
                  if (0 < parseInt(e.target.value)) {
                    const v = e.target.value.toString().replace(/\./g, '')
                    updateInputCampaign('cmMaterialNumber', parseInt(v))
                  } else {
                    updateInputCampaign('cmMaterialNumber', 1)
                  }
                }}
              />
              {errors.cmMaterialNumber.required && (
                <span style={{ color: 'red', width: '100%' }}>入力してください</span>
              )}
            </Form.Item>

            {/* 地上波固定 */}
            <Form.Item label={'TV放送波'} initialValue={inputCampaign.tvBroadcastWaveId}>
              <Select
                {...tvBroadcastWavesSelectProps}
                style={
                  hasError(errors.tvBroadcastWaveId)
                    ? { border: '1px solid red', borderRadius: '6px', width: '100%' }
                    : { width: '100%' }
                }
                disabled
                showArrow
                showSearch={false}
                placeholder={'選択'}
                value={inputCampaign.tvBroadcastWaveId}
                onChange={(e) => updateInputCampaign('tvBroadcastWaveId', e)}
              ></Select>
              {errors.tvBroadcastWaveId.required && (
                <span style={{ color: 'red', width: '100%' }}>選択してください</span>
              )}
            </Form.Item>
            <Form.Item label="ターゲット属性" initialValue={inputCampaign.tvTargetAttribute}>
              <Input
                showCount
                style={
                  hasError(errors.tvTargetAttribute) ? { border: '1px solid red', width: '100%' } : { width: '100%' }
                }
                placeholder={'ターゲット属性'}
                maxLength={200}
                value={inputCampaign.tvTargetAttribute}
                onChange={(e) => updateInputCampaign('tvTargetAttribute', e)}
              />
              {errors.tvTargetAttribute?.requiredFrontTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行頭に空白は使えません</span>
                  <br />
                </div>
              )}
              {errors.tvTargetAttribute?.requiredRearTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行末に空白は使えません</span>
                  <br />
                </div>
              )}
            </Form.Item>
          </Descriptions>
        </div>

        <Space align="baseline">
          <Title level={5}>CM素材</Title>
          <Button
            size="small"
            type="primary"
            style={{ margin: '8px 8px' }}
            onClick={() => setIsCreateCmModalVisible(true)}
          >
            追加
          </Button>
        </Space>

        <Table
          size="small"
          bordered
          columns={[
            {
              title: 'CM素材名',
              dataIndex: 'cmMaterialEditName',
            },
            {
              title: '添付資料あり',
              dataIndex: 'hasAttachment',
              align: 'center',
              render(_value, record) {
                return <>{record.hasAttachment ? <Icons.CheckOutlined /> : ''}</>
              },
            },
            { title: '初出稿枠', dataIndex: 'cmMaterialFirstPlacing' },
            { title: '内容', dataIndex: 'cmMaterialDetail' },
            { title: 'CM秒数', dataIndex: 'cmSeconds' },
            { title: 'CM素材コード', dataIndex: 'cmMaterialCd' },
            {
              title: 'CM素材名(CM統計)',
              ellipsis: true,
              width: 160,
              dataIndex: 'cmMaterialName',
            },
            { title: '銘柄', dataIndex: ['brand', 'brandName'] },
            {
              title: 'CM素材From',
              dataIndex: 'cmMaterialFrom',
              render(_value, record) {
                return <>{record.cmMaterialFrom && dayjs(record.cmMaterialFrom).format('YYYY/MM/DD')}</>
              },
            },
            {
              title: 'CM素材To',
              dataIndex: 'cmMaterialTo',
              render(_value, record) {
                return <>{record.cmMaterialFrom && dayjs(record.cmMaterialTo).format('YYYY/MM/DD')}</>
              },
            },
            {
              title: '地区',
              dataIndex: ['adAreas'],
              render(_value, record) {
                // 地区IDでソート
                const adAreas = sortObjectsByBase64Id(record.adAreas)
                return (
                  <>
                    {adAreas.map((adArea: IAdArea, index: any) => {
                      return (
                        <Tag key={index} color="geekblue">
                          {adArea.adAreaName}
                        </Tag>
                      )
                    })}
                  </>
                )
              },
            },
            {
              title: 'アクション',
              fixed: 'right',
              width: '102px',
              align: 'center',
              render: (record) => (
                <Space>
                  <EditButton size="small" onClick={() => editModalShow(record)} icon={false} />
                  {/* 削除ボタン */}
                  <Button
                    size="small"
                    style={{ borderColor: '#e32020' }}
                    onClick={() => {
                      // 未登録の行は固有IDを持っていないので行作成時に付与したrowIdで対象行を特定
                      if ('rowId' in record) {
                        setCmMaterials(cmMaterials.filter((material) => material.rowId !== record.rowId))
                      } else {
                        setCmMaterials(cmMaterials.filter((material) => material.id !== record.id))
                      }
                    }}
                  >
                    <Icons.DeleteTwoTone twoToneColor={'#e32020'} />
                  </Button>
                </Space>
              ),
            },
          ]}
          dataSource={cmMaterials}
          pagination={false}
          scroll={{ x: 1600 }}
        ></Table>
        <CreateCmMaterialModal
          isCreateCmModalVisible={isCreateCmModalVisible}
          cmMaterials={cmMaterials}
          setCmMaterials={setCmMaterials}
          setIsCreateCmModalVisible={setIsCreateCmModalVisible}
        />
        {currentCmMaterialRecord && (
          <EditCmMaterialModal
            currentCmMaterialRecord={currentCmMaterialRecord}
            isEditCmModalVisible={isEditCmModalVisible}
            cmMaterials={cmMaterials}
            setCmMaterials={setCmMaterials}
            setIsEditCmModalVisible={setIsEditCmModalVisible}
          />
        )}

        {/* APIリクエストに使われるForm項目 */}
        <Form.Item name={['tvCmInfo', 'cmMaterialNumber']} hidden />
        <Form.Item name={['tvCmInfo', 'tvBroadcastWaveId']} hidden />
        <Form.Item name={['tvCmInfo', 'tvCmFrom']} hidden />
        <Form.Item name={['tvCmInfo', 'tvCmTo']} hidden />
        <Form.Item name={['tvCmInfo', 'tvTargetAttribute']} hidden />
        <Form.Item name={['tvCmInfo', 'cmMaterialNumber']} hidden />
        <Form.Item name={['tvCmInfo', 'cmMaterials']} hidden />
      </Card>
    </>
  )
}
