import datadogLogs from 'logger'
import React, { ErrorInfo } from 'react'
import { ErrorComponent } from 'components/404/ErrorComponent'

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    datadogLogs.logger.info(`ErrorBoundary: ${error}`)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />
    }
    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
