import axios from 'axios'
import { GRAPHQL_ENDPOINT, getClient } from './auth'
import { clearAuth } from 'utils'

export const isRequiredAccessToken = (url: URL): boolean => {
  return url.origin === graphqlEndpoint.origin && url.pathname !== graphqlEndpoint.pathname
}
export const throwInvaliedToken = async () => {
  await getClient().catch(() => {
    clearAuth().catch(() => {})
    if (window && window.location && window.location) window.location.href = '/login'
    return Promise.reject()
  })
  return Promise.resolve()
}
export const getFetchConfig = (request: any, isAuthorization = true): object => {
  if (!isAuthorization) {
    return request.length === 1 ? {} : request[1]
  }
  if (request.length === 1) {
    return {
      headers: new Headers({ Authorization: `Bearer ${localStorage.getItem('accessToken')}` }),
    }
  }
  request[1].headers.delete('Authorization')
  request[1].headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
  return request[1]
}

const graphqlEndpoint = new URL(GRAPHQL_ENDPOINT)
const { fetch: originalFetch } = window

window.fetch = async (...request: any) => {
  let config = getFetchConfig(request, false)
  if (isRequiredAccessToken(new URL(request[0]))) {
    await throwInvaliedToken()
    config = getFetchConfig(request)
  }
  const response = await originalFetch(request[0], config)
  return response
}

axios.interceptors.request.use(async (request: any) => {
  if (isRequiredAccessToken(new URL(request.url))) {
    await throwInvaliedToken()
    request.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
  }
  return request
})
