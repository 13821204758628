export const IP_CHECK_URL = 'https://api.ipify.org/?format=json'
export const VALIDATION_MESSAGES = {
  PASTE_VALUE_OVER_LENGTH: '貼り付け可能な文字数を超えています',
}

export const INIT_CAMPAIGN_ERROR_STATUS = { required: false, requiredFrontTrim: false, requiredRearTrim: false }

export const INITIAL_ERRORS = {
  campaignName: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  productName: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  clientId: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  clientDepartment: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  vcid: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  salesDepartmentId: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  campaignId: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  sponsorNameFromCustomer: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  sponsorId: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  subAccountId: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  campaignPeriod: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  cmMaterialNumber: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  tvTargetAttribute: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  tvCmPeriod: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  tvBroadcastWaveId: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  adPlatformId: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  digitalPlacingPeriod: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
  digitalTargetAttribute: { required: false, requiredFrontTrim: false, requiredRearTrim: false },
}
