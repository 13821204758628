import { Button, Checkbox, Form, Icons, Input, InputNumber, Modal, Select, Space, useSelect } from '@pankod/refine-antd'
import { useNotification } from '@pankod/refine-core'
import { FormInstance } from 'antd'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import dayjs from 'dayjs'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { ExtendCmMaterial, IAdArea, IBrand } from 'interfaces'
import React, { useEffect, useRef } from 'react'
import {
  frontBlankTrim,
  HALF_WIDTH_ALPHANUMERIC,
  pattern,
  rearBlankTrim,
  required,
  validatePasteValueLength,
  length,
  detectInvalidValue as d,
} from 'utils'
const { RangePicker } = DateTimePicker

interface Props {
  currentCmMaterialRecord: ExtendCmMaterial
  isEditCmModalVisible: boolean
  cmMaterials: ExtendCmMaterial[]
  setCmMaterials: (v: ExtendCmMaterial[]) => void
  setIsEditCmModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditCmMaterialModal = (props: Props) => {
  const { currentCmMaterialRecord, isEditCmModalVisible, cmMaterials, setCmMaterials, setIsEditCmModalVisible } = props
  const refEditModalForm = useRef<FormInstance>(null)

  const { selectProps: adAreasSelectProps, queryResult: adAreaQueryResult } = useSelect<IAdArea>({
    resource: 'adAreas',
    optionLabel: 'adAreaName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'adAreaName', 'isActive'],
      pageSize: 9999999,
    },
  })

  const { queryResult: brandQueryResult } = useSelect<IBrand>({
    resource: 'brands',
    optionLabel: 'brandName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'brandName', 'brandCd'],
      pageSize: 9999999,
    },
  })
  const { open } = useNotification()

  const brands = brandQueryResult.data?.data

  useEffect(() => {
    refEditModalForm.current?.resetFields()
  }, [isEditCmModalVisible])

  const onOk = async () => {
    let formCurrent = refEditModalForm.current
    // フォームバリデーション
    await formCurrent?.validateFields([
      'cmMaterialName',
      'cmMaterialFirstPlacing',
      'cmMaterialDetail',
      'cmSeconds',
      'cmMaterialCd',
      'cmMaterialEditName',
    ])

    const {
      cmMaterialName,
      cmMaterialFirstPlacing,
      cmMaterialDetail,
      cmSeconds,
      cmMaterialCd,
      cmMaterialPeriod,
      hasAttachment,
      adAreas,
      cmMaterialEditName,
    } = formCurrent?.getFieldsValue()

    let selectedAdAreas: IAdArea[] | undefined
    if (adAreas) {
      selectedAdAreas = adAreaQueryResult.data?.data.filter((v) => adAreas.includes(v.id))
    }
    const existsBrand = brands?.filter((brand) => brand.brandCd === cmMaterialCd?.substring(0, 8))[0]

    const newMaterials = cmMaterials.map((cmMaterial) => {
      if (cmMaterial.rowId === currentCmMaterialRecord.rowId) {
        const material: ExtendCmMaterial = {
          rowId: currentCmMaterialRecord?.rowId,
          id: d(currentCmMaterialRecord?.id),
          brand: d(existsBrand),
          cmMaterialEditName: d(cmMaterialEditName),
          cmMaterialName: d(cmMaterialName),
          cmMaterialFirstPlacing: d(cmMaterialFirstPlacing),
          cmMaterialDetail: d(cmMaterialDetail),
          cmSeconds: d(cmSeconds),
          cmMaterialCd: d(cmMaterialCd),
          cmMaterialFrom: cmMaterialPeriod?.[0]?.format('YYYY-MM-DDT00:00:00'),
          cmMaterialTo: cmMaterialPeriod?.[1]?.format('YYYY-MM-DDT00:00:00'),
          hasAttachment: d(hasAttachment),
          adAreas: selectedAdAreas ?? [],
        }
        return material
      }
      return cmMaterial
    })
    setCmMaterials(newMaterials)
    setIsEditCmModalVisible(false)
  }

  return (
    <>
      <Modal
        centered
        width={800}
        title="テレビCM素材編集"
        visible={isEditCmModalVisible}
        maskClosable={false}
        onOk={() => onOk()}
        okText={'登録'}
        onCancel={() => setIsEditCmModalVisible(false)}
      >
        <Form
          labelCol={{ xxl: 7, xl: 7, lg: 7, md: 7, sm: 7 }}
          wrapperCol={{ xxl: 18, xl: 18, lg: 18, md: 18, sm: 18 }}
          colon={false}
          ref={refEditModalForm}
        >
          <Form.Item
            label="CM素材名"
            required
            name="cmMaterialEditName"
            rules={[frontBlankTrim(), rearBlankTrim(), required()]}
            initialValue={currentCmMaterialRecord?.cmMaterialEditName}
          >
            <Input placeholder={'CM素材名'} maxLength={200} showCount />
          </Form.Item>

          <Form.Item
            label="初出稿枠"
            name="cmMaterialFirstPlacing"
            initialValue={currentCmMaterialRecord?.cmMaterialFirstPlacing}
            rules={[frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder={'初出稿枠'} maxLength={200} showCount />
          </Form.Item>

          <Form.Item
            label="内容"
            name="cmMaterialDetail"
            initialValue={currentCmMaterialRecord?.cmMaterialDetail}
            rules={[frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder={'内容'} maxLength={200} showCount />
          </Form.Item>

          <Form.Item
            label="CM秒数"
            name="cmSeconds"
            initialValue={currentCmMaterialRecord?.cmSeconds}
            rules={[pattern('^[1-9][0-9]*$')]}
          >
            <InputNumber placeholder={'CM秒数'} maxLength={9} min={1} max={999999999} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item label="銘柄">
            <Space size={5}>
              <Button type={'link'} size={'small'} target="_blank" href="/brands/create">
                追加
              </Button>
              <Button
                type={'link'}
                size={'small'}
                style={{ padding: '0px' }}
                onClick={() => {
                  try {
                    brandQueryResult.refetch()
                    open({
                      message: '最新銘柄情報を更新しました',
                      key: 'success',
                      type: 'success',
                    })
                  } catch {
                    open({
                      message: '最新銘柄情報の更新に失敗しました',
                      key: 'error',
                      type: 'error',
                    })
                  }
                }}
              >
                <Icons.ReloadOutlined />
              </Button>
            </Space>
          </Form.Item>

          <Form.Item
            label="CM素材コード"
            name="cmMaterialCd"
            initialValue={currentCmMaterialRecord?.cmMaterialCd}
            rules={[
              frontBlankTrim(),
              rearBlankTrim(),
              pattern(HALF_WIDTH_ALPHANUMERIC, '半角英数字で入力してください'),
              length(11, 'CM素材コードは11桁で登録してください'),
              // CM素材コードに関連する銘柄の存在チェック
              () => ({
                validator(_, value) {
                  if (value && 8 <= value.length) {
                    const filteredBrands = brands?.filter((brand: IBrand) => brand.brandCd === value?.substring(0, 8))
                    if (!filteredBrands || filteredBrands.length !== 1) {
                      return Promise.reject(
                        <span style={{ color: 'red' }}>CM素材コードに関連する銘柄を先に登録してください</span>,
                      )
                    }
                  }
                  return Promise.resolve()
                },
              }),
              // CM素材コードの重複チェック
              () => ({
                validator(_, value) {
                  if (
                    value &&
                    cmMaterials
                      .map((item) => item.cmMaterialCd)
                      .filter((cmMaterialCd) => cmMaterialCd !== currentCmMaterialRecord?.cmMaterialCd)
                      .includes(value)
                  ) {
                    return Promise.reject(<span style={{ color: 'red' }}>このCM素材コードは既に登録されています</span>)
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input placeholder={'CM素材コード'} maxLength={11} showCount />
          </Form.Item>

          <Form.Item
            label="CM素材名（CM統計）"
            name="cmMaterialName"
            initialValue={currentCmMaterialRecord?.cmMaterialName}
            rules={[frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder={'CM素材名（CM統計）'} maxLength={200} showCount />
          </Form.Item>

          <div
            data-testid={'modal-test-paste-div'}
            onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}
          >
            <Form.Item
              label="期間"
              name="cmMaterialPeriod"
              initialValue={[
                currentCmMaterialRecord?.cmMaterialFrom ? dayjs(currentCmMaterialRecord?.cmMaterialFrom) : undefined,
                currentCmMaterialRecord?.cmMaterialTo ? dayjs(currentCmMaterialRecord?.cmMaterialTo) : undefined,
              ]}
            >
              <RangePicker locale={locale} format="YYYY/MM/DD" placeholder={['開始日時', '終了日時']} />
            </Form.Item>
          </div>

          <Form.Item
            label={'地区'}
            name="adAreas"
            valuePropName="value"
            initialValue={currentCmMaterialRecord?.adAreas.map((adArea) => adArea.id)}
          >
            <Select
              {...adAreasSelectProps}
              mode="multiple"
              allowClear
              showArrow
              placeholder={'選択'}
              showSearch={false}
            ></Select>
          </Form.Item>

          <Form.Item
            label="添付資料あり"
            name="hasAttachment"
            valuePropName="checked"
            initialValue={currentCmMaterialRecord?.hasAttachment}
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
