import { Button, Icons } from '@pankod/refine-antd'
import { returnTop } from 'utils'

export const ReturnTop: React.FC = () => {
  return (
    <div style={{ textAlign: 'right' }}>
      <Button type="text" onClick={returnTop}>
        <Icons.CaretUpFilled />
        トップへ戻る
      </Button>
    </div>
  )
}
