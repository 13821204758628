import { IP_REGEX } from './regex'

/**
 * 必須項目
 */
export const required = (customErrorMessage?: string) => {
  return {
    required: true,
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {customErrorMessage ? customErrorMessage : '入力してください'}
      </span>
    ),
  }
}

/**
 * 最大文字数
 */
export const maxLength = (maxLength: number) => {
  return {
    max: maxLength,
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {`${maxLength}文字以下で入力してください`}
      </span>
    ),
  }
}

/**
 * 文字数指定
 */
export const length = (length: number, customErrorMessage?: string) => {
  return {
    pattern: new RegExp(`^.{${length}}$`),
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {customErrorMessage ? customErrorMessage : `${length}文字で入力してください`}
      </span>
    ),
  }
}

/**
 * 正規表現
 */
export const pattern = (pattern: any, customErrorMessage?: string) => {
  return {
    pattern: new RegExp(pattern),
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {customErrorMessage ? customErrorMessage : '不正な値です'}
      </span>
    ),
  }
}

/**
 * 行頭の空白を許容しない
 */
export const frontBlankTrim = (customErrorMessage?: string) => {
  return {
    pattern: new RegExp(/^[\S]+/),
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {customErrorMessage ? customErrorMessage : '行頭に空白は使えません'}
      </span>
    ),
  }
}

/**
 * 行末の空白を許容しない
 */
export const rearBlankTrim = (customErrorMessage?: string) => {
  return {
    pattern: new RegExp(/\S+$/),
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {customErrorMessage ? customErrorMessage : '行末に空白は使えません'}
      </span>
    ),
  }
}

/**
 * 空白以外の文字が最低1文字は必須
 */
export const oneCharacterRequired = (customErrorMessage?: string) => {
  return {
    whitespace: true,
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {customErrorMessage ? customErrorMessage : '空白以外の文字を入力してください'}
      </span>
    ),
  }
}

/**
 * 大文字を許容しない
 */
export const lowerCaseOnly = (customErrorMessage?: string) => {
  return {
    pattern: new RegExp(/^[^A-Z]+$/),
    message: (
      <span id="validationError" style={{ color: 'red' }}>
        {customErrorMessage ? customErrorMessage : 'アルファベットは小文字で入力してください'}
      </span>
    ),
  }
}

/**
 * IPアドレスのフォーマットをチェック
 */
export const isIpAddressFormatEnabled = (ipAddress: string): boolean => {
  let isValid = false
  if (IP_REGEX.test(ipAddress)) {
    isValid = true
  }
  return isValid
}

/**
 * CIDRのチェック
 */
export const isIpAddressCIDREnabled = (ipAddress: string): boolean => {
  let isValid = false
  const ipAddressGroup = ipAddress.split('/')
  if (ipAddressGroup[1] === undefined) {
    isValid = true
    return isValid
  }
  const ip2bin = (ip: any) =>
    ip
      .split('.')
      .map((e: any) => Number(e).toString(2).padStart(8, '0'))
      .join('')
  const ip2long = (ip: any) => parseInt(ip2bin(ip), 2)
  const long2ip = (num: any) => {
    let bin = Number(num).toString(2).padStart(32, '0')
    return [bin.slice(0, 8), bin.slice(8, 16), bin.slice(16, 24), bin.slice(24, 32)]
      .map((e) => parseInt(e, 2))
      .join('.')
  }
  const cidr2long = (cidr: any) => parseInt(String('').padStart(cidr, '1').padEnd(32, '0'), 2)
  const getNetworkAddr = (ip: any, subNetMask: any) => (ip & subNetMask) >>> 0
  const ipLong = ip2long(ipAddressGroup[0])
  const cidr = cidr2long(ipAddressGroup[1])

  if (ipAddressGroup[0] === long2ip(getNetworkAddr(ipLong, cidr))) {
    isValid = true
  }
  return isValid
}

/**
 * IPアドレスが有効かチェック
 */
export const isIpAddressEnabled = (ipAddress: string): boolean => {
  let isValid = false
  if (isIpAddressFormatEnabled(ipAddress) && isIpAddressCIDREnabled(ipAddress)) {
    isValid = true
  }
  return isValid
}
