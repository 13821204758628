import './style.less'
import { Typography } from '@pankod/refine-antd'

type TitleProps = {
  collapsed: boolean
}

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  return (
    <div className="logo">
      {collapsed ? <Typography>バックオフィス</Typography> : <Typography>バックオフィス</Typography>}
    </div>
  )
}
