import {
  Tooltip,
  Button,
  Card,
  Col,
  DateField,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Pagination,
  Row,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, CrudSort, HttpError, usePermissions } from '@pankod/refine-core'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import dayjs from 'dayjs'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { IInformation, IInformationFilterVariables } from 'interfaces'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { convertOrderKey, ellipsis, is, validatePasteValueLength } from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const InformationList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IInformation,
    HttpError,
    IInformationFilterVariables
  >({
    permanentSorter: [sortCondition],
    metaData: {
      current: currentPage,
      pageSize: 10,
      fields: [
        'id',
        'title',
        'publishStartAt',
        'publishEndAt',
        'maintenanceStartAt',
        'maintenanceEndAt',
        'isMaintenanceMode',
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { title, content, publishStartAt, publishEndAt, maintenanceStartAt, maintenanceEndAt } = params

      filters.push({
        field: 'title',
        operator: 'contains',
        value: title ? title : null,
      })

      filters.push({
        field: 'content',
        operator: 'contains',
        value: content ? content : null,
      })

      filters.push({
        field: 'publishStartAt',
        operator: 'gte',
        value: publishStartAt ? dayjs(publishStartAt).format('YYYY/MM/DD HH:mm:00') : null,
      })

      filters.push({
        field: 'publishEndAt',
        operator: 'lte',
        value: publishEndAt ? dayjs(publishEndAt).format('YYYY/MM/DD HH:mm:00') : null,
      })

      filters.push({
        field: 'maintenanceStartAt',
        operator: 'gte',
        value: maintenanceStartAt ? dayjs(maintenanceStartAt).format('YYYY/MM/DD HH:mm:00') : null,
      })

      filters.push({
        field: 'maintenanceEndAt',
        operator: 'lte',
        value: maintenanceEndAt ? dayjs(maintenanceEndAt).format('YYYY/MM/DD HH:mm:00') : null,
      })

      return filters
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('INFORMATION')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`お知らせ | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`お知らせ | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} title={'フィルター'}>
            <Filter formProps={searchFormProps} filters={filters || []} setCurrentPage={setCurrentPage} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List>
            <Table
              {...tableProps}
              rowKey="id"
              pagination={false}
              onChange={(_pagination, _filters, sorter: any) => {
                let field = ''
                if (is('String', sorter.field)) field = sorter.field
                else field = sorter.field.slice(-1)[0]
                setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
                tableQueryResult.refetch()
              }}
            >
              <Table.Column
                dataIndex="title"
                title="見出し"
                ellipsis={{
                  showTitle: false,
                }}
                sorter
                render={(value) => {
                  return (
                    <Tooltip placement="top" title={value}>
                      {ellipsis(value, 15)}
                    </Tooltip>
                  )
                }}
              />
              <Table.Column
                ellipsis
                dataIndex="publishStartAt"
                title="掲載開始日時"
                render={(value) => <DateField format="YYYY年MM月DD日 HH:mm" value={value} />}
                sorter
              />
              <Table.Column
                ellipsis
                dataIndex="publishEndAt"
                title="掲載終了日時"
                render={(value) => <DateField format="YYYY年MM月DD日 HH:mm" value={value} />}
                sorter
              />
              <Table.Column<IInformation>
                ellipsis
                dataIndex="maintenanceStartAt"
                title="メンテナンス開始日時"
                render={(value, record) => {
                  if (record?.isMaintenanceMode && value) {
                    return <DateField format="YYYY年MM月DD日 HH:mm" value={value} />
                  } else {
                    return ''
                  }
                }}
                sorter
              />
              <Table.Column<IInformation>
                ellipsis
                dataIndex="maintenanceEndAt"
                title="メンテナンス終了日時"
                render={(value, record) => {
                  if (record?.isMaintenanceMode && value) {
                    return <DateField format="YYYY年MM月DD日 HH:mm" value={value} />
                  } else {
                    return ''
                  }
                }}
                sorter
              />
              <Table.Column<IInformation>
                title="操作"
                dataIndex="actions"
                fixed="right"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" recordItemId={record.id} icon={false} />
                    </Space>
                  )
                }}
              />
            </Table>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                current={currentPage}
                onChange={onChangePage}
                total={tableQueryResult?.data?.total}
                showSizeChanger={false}
              />
            </div>
          </List>
        </Col>
      </Row>
    </>
  )
}

const Filter: React.FC<{
  formProps: FormProps
  filters: CrudFilters
  setCurrentPage: (currentPage: number) => void
}> = (props) => {
  const { formProps, setCurrentPage } = props

  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'見出し'} name="title">
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={200} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'内容'} name="content">
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={1000} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label={'掲載開始日時'} name="publishStartAt">
              <DateTimePicker
                locale={locale}
                showTime
                allowClear
                style={{ width: '100%' }}
                placeholder="日時を選択"
                format="YYYY/MM/DD HH:mm 以降"
              />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label={'掲載終了日時'} name="publishEndAt">
              <DateTimePicker
                locale={locale}
                showTime
                allowClear
                style={{ width: '100%' }}
                placeholder="日時を選択"
                format="YYYY/MM/DD HH:mm 以前"
              />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label={'メンテナンス開始日時'} name="maintenanceStartAt">
              <DateTimePicker
                locale={locale}
                showTime
                allowClear
                style={{ width: '100%' }}
                placeholder="日時を選択"
                format="YYYY/MM/DD HH:mm 以降"
              />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label={'メンテナンス終了日時'} name="maintenanceEndAt">
              <DateTimePicker
                locale={locale}
                showTime
                allowClear
                style={{ width: '100%' }}
                placeholder="日時を選択"
                format="YYYY/MM/DD HH:mm 以前"
              />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block onClick={() => setCurrentPage(1)}>
              {'絞り込み'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
