import { Create, Form, Input, ListButton, Transfer, useForm, useSelect } from '@pankod/refine-antd'
import { IResourceComponentsProps, usePermissions } from '@pankod/refine-core'
import { ErrorComponent } from 'components/404/ErrorComponent'
import { IAuthority, IManagementUser } from 'interfaces'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  EMAIL_RFC5321_REGEX,
  PASSWORD_REGEX,
  frontBlankTrim,
  lowerCaseOnly,
  maxLength,
  pattern,
  rearBlankTrim,
  required,
} from 'utils'

export const ManagementUserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IManagementUser>()
  const [targetKeys, setTargetKeys] = useState<string[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  const { selectProps: authoritySelectProps } = useSelect<IAuthority>({
    resource: 'authorities',
    optionLabel: 'authorityName',
    optionValue: 'authorityKey',
    metaData: {
      fields: ['authorityKey', 'authorityName'],
      pageSize: 9999999,
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('MANAGEMENT_USER')) {
    return <ErrorComponent />
  }

  const authorities = authoritySelectProps?.options?.map((option) => {
    return {
      key: option.value,
      title: option.label,
    } as any
  })

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys)
  }

  const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  const filterOption = (inputValue: string, option: any) => option.title.indexOf(inputValue) > -1

  return (
    <>
      <Helmet>
        <title>{`管理ユーザー | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`管理ユーザー | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="managementUsers" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 8 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 16 }}
          colon={false}
        >
          <Form.Item
            label="名前"
            name="userName"
            required
            rules={[required(), frontBlankTrim(), rearBlankTrim(), maxLength(100)]}
          >
            <Input placeholder="名前" maxLength={100} showCount autoComplete="new-password" />
          </Form.Item>

          <Form.Item
            label="メールアドレス"
            name="email"
            required
            rules={[
              required(),
              frontBlankTrim(),
              rearBlankTrim(),
              maxLength(200),
              lowerCaseOnly(),
              pattern(EMAIL_RFC5321_REGEX, '有効なメールアドレスを入力してください'),
            ]}
          >
            <Input placeholder="user@example.com" maxLength={200} showCount autoComplete="new-password" />
          </Form.Item>

          <Form.Item
            label="パスワード"
            name="password"
            required
            rules={[
              required(),
              frontBlankTrim(),
              rearBlankTrim(),
              pattern(PASSWORD_REGEX, '8〜16文字で必ず1つ以上の英字と1つ以上の数字を含めてください'),
            ]}
          >
            <Input.Password autoComplete="new-password" minLength={8} maxLength={16} placeholder="●●●●●●●●" />
          </Form.Item>

          <Form.Item
            label="パスワード（確認）"
            name="confirmPassword"
            required
            rules={[
              required(),
              ({ getFieldValue }) => ({
                validator(_, confirmPassword) {
                  if (confirmPassword !== '') {
                    if (getFieldValue('password') === confirmPassword) {
                      return Promise.resolve()
                    }
                    return Promise.reject(<span style={{ color: 'red' }}>パスワードが一致しません</span>)
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input.Password autoComplete="new-password" placeholder="●●●●●●●●" minLength={8} maxLength={16} />
          </Form.Item>

          <Form.Item label="所属" name="department" rules={[maxLength(100), frontBlankTrim(), rearBlankTrim()]}>
            <Input placeholder="所属" maxLength={100} showCount />
          </Form.Item>

          <Form.Item label="ラベル" name="label" rules={[maxLength(100), frontBlankTrim(), rearBlankTrim()]}>
            <Input placeholder="ラベル" maxLength={100} showCount />
          </Form.Item>

          <div>
            <Form.Item
              label={'権限'}
              name={'authorities'}
              rules={[required('一つ以上適用してください')]}
              labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 8 }}
              wrapperCol={{ xxl: 10, xl: 14, lg: 18, md: 18, sm: 16 }}
            >
              {authorities && (
                <Transfer
                  dataSource={authorities}
                  showSearch={false}
                  locale={{
                    selectAll: 'すべて選択',
                    selectInvert: '反転する',
                  }}
                  titles={['未適用', '適用済']}
                  targetKeys={targetKeys}
                  selectedKeys={selectedKeys}
                  onChange={onChange}
                  onSelectChange={onSelectChange}
                  render={(item) => item.title}
                  filterOption={filterOption}
                  listStyle={{
                    width: 500,
                    height: 300,
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Form>
      </Create>
    </>
  )
}
