import {
  Pagination,
  Select,
  useSelect,
  Button,
  Card,
  Col,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Row,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, HttpError, usePermissions, CrudSort } from '@pankod/refine-core'
import { IAccountUser, IAccountUserFilterVariables, IClient } from 'interfaces'
import React, { useState } from 'react'
import { convertOrderKey, is } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'
import { maxLength, validatePasteValueLength } from 'utils'

export const AccountUserList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IAccountUser,
    HttpError,
    IAccountUserFilterVariables
  >({
    permanentSorter: [sortCondition],
    metaData: {
      fields: ['id', 'loginId', 'department', { client: ['id', 'clientName'] }, 'isResponsible', 'isActive'],
      current: currentPage,
      pageSize: 10,
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { loginId, client, department } = params

      filters.push({
        field: 'loginId',
        operator: 'contains',
        value: loginId ? loginId : null,
      })

      filters.push({
        field: 'client.id',
        operator: 'eq',
        value: client,
      })

      filters.push({
        field: 'department',
        operator: 'contains',
        value: department ? department : null,
      })

      return filters
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('ACCOUNT_USER')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`アカウント管理 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`アカウント管理 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} title={'フィルター'}>
            <Filter formProps={searchFormProps} filters={filters || []} setCurrentPage={setCurrentPage} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List>
            <Table
              {...tableProps}
              rowKey="id"
              pagination={false}
              onChange={(_pagination, _filters, sorter: any) => {
                let field = ''
                if (is('String', sorter.field)) {
                  field = sorter.field
                } else if (sorter?.column?.title === '依頼主') {
                  field = 'client.clientName'
                } else {
                  field = sorter.field.slice(-1)[0]
                }
                setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
                tableQueryResult.refetch()
              }}
            >
              <Table.Column dataIndex="loginId" title="ログインID" sorter ellipsis />
              <Table.Column dataIndex={['client', 'clientName']} title="依頼主" ellipsis sorter />
              <Table.Column dataIndex={'department'} title="部署" ellipsis />
              <Table.Column
                dataIndex="isActive"
                title="状態"
                ellipsis
                render={(value) => (value ? '利用可' : '利用停止中')}
              />
              <Table.Column<IAccountUser>
                title="操作"
                dataIndex="actions"
                fixed="right"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" recordItemId={record.id} icon={false} />
                    </Space>
                  )
                }}
              />
            </Table>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                current={currentPage}
                onChange={onChangePage}
                total={tableQueryResult?.data?.total}
                showSizeChanger={false}
              />
            </div>
          </List>
        </Col>
      </Row>
    </>
  )
}

const Filter: React.FC<{
  formProps: FormProps
  filters: CrudFilters
  setCurrentPage: (currentPage: number) => void
}> = (props) => {
  const { formProps, setCurrentPage } = props

  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: 'clients',
    optionLabel: 'clientName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'clientName'],
      pageSize: 9999999,
    },
  })

  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'ログインID'} name="loginId" rules={[maxLength(16)]}>
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={16} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
            <Form.Item label={'依頼主'} name="client">
              <Select
                {...clientSelectProps}
                showArrow
                allowClear
                onSearch={undefined}
                filterOption={true}
                optionFilterProp="label"
                mode="multiple"
                placeholder={'部分一致/選択'}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'部署'} name="department" rules={[maxLength(100)]}>
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={100} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block onClick={() => setCurrentPage(1)}>
              {'絞り込み'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
