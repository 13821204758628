import { Button, Form, Icons, Select, Space, useSelect } from '@pankod/refine-antd'
import { ISponsor } from 'interfaces'
import React from 'react'
import { validatePasteValueLength } from 'utils'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type SelectSponsorProps = {
  selectedSponsorId: string | undefined
  onChangeSponsor: (sponsorId: string) => void
}

export const SelectSponsor: React.FC<SelectSponsorProps> = () => {
  const { selectProps: sponsorSelectProps, queryResult: sponsorQueryResult } = useSelect<ISponsor>({
    resource: 'sponsors',
    optionLabel: 'sponsorName',
    optionValue: 'id',
    sort: [
      {
        field: 'id',
        order: 'asc',
      },
    ],
    metaData: {
      fields: ['id', 'sponsorName'],
      pageSize: 9999999,
    },
  })

  return (
    <div
      data-testid="select-sponsor-test-paste"
      onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}
    >
      <Form.Item
        label={
          <Space size={5}>
            広告主
            <Button type={'link'} size={'small'} style={{ padding: '0px' }} target="_blank" href="/sponsors/create">
              <Icons.PlusOutlined />
            </Button>
            <Button
              data-testid={'btn-test'}
              type={'link'}
              size={'small'}
              style={{ padding: '0px' }}
              onClick={() => {
                try {
                  sponsorQueryResult.refetch()
                  toast.success('選択項目を更新しました', {
                    toastId: 'selectSponsorSuccess',
                  })
                } catch (e) {
                  toast.error('選択項目の更新に失敗しました', {
                    toastId: 'selectSponsorError',
                  })
                }
              }}
            >
              <Icons.ReloadOutlined />
            </Button>
          </Space>
        }
        name={'sponsorId'}
        rules={[
          {
            required: true,
            message: <span style={{ color: 'red' }}>選択してください</span>,
          },
        ]}
      >
        <Select
          {...sponsorSelectProps}
          showArrow
          showSearch
          autoClearSearchValue={false}
          placeholder={'部分一致/選択'}
        ></Select>
      </Form.Item>
    </div>
  )
}
