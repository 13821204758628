import timezone from 'dayjs/plugin/timezone'
import dayjs, { Dayjs } from 'dayjs'
import { VALIDATION_MESSAGES, IP_CHECK_URL } from 'consts'
import { ICmMaterial, IMaterialBrandName } from 'interfaces'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import datadogLogs from 'logger'
import * as gql from 'gql-query-builder'
import { GraphQLClient } from 'graphql-request'
import { getClient } from '../auth'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
dayjs.extend(isSameOrBefore)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

export const toUpperCase = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const toCamelCase = (text: string) => {
  text = text.charAt(0).toLowerCase() + text.slice(1)
  return text.replace(/[-_](.)/g, (match, group1) => group1.toUpperCase())
}

export const toSnakeCase = (text: string) => {
  var camel = toCamelCase(text)
  return camel.replace(/[A-Z]/g, (s) => '_' + s.charAt(0).toLowerCase())
}

export const getPeriod = (from: string | undefined, to: string | undefined) => {
  if (from && to) return [from, to]
  else return null
}

/**
 * n文字以上の文字列を省略する
 *
 * @param {string} str	対象文字列
 * @param {number} size	全角での長さ
 * @return {string} 整形文字列
 */
export const ellipsis = (str: string, size: number): string => {
  if (!str || str.length === 0) return ''

  let trim = ''
  let _size = size * 2
  const ellipsis = '...'

  for (let i = 0; i < str.length; i++) {
    const _string = str.charAt(i)
    if (/[a-zA-Z0-9\s,!-/:-@¥/-`{-~/*]/.test(_string)) {
      _size -= 1
    } else {
      _size -= 2
    }
    trim = trim + _string

    if (_size <= 0) {
      if (i + 1 !== str.length) trim += ellipsis
      break
    }
  }

  return trim
}

export const returnTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const clearAuth = async () => {
  if (!localStorage.getItem('accessToken')) return Promise.reject()
  if (!localStorage.getItem('currentUser')) return Promise.reject()
  try {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    datadogLogs.logger.info(`[logout] ${currentUser.email}`)
  } catch (error: any) {
    datadogLogs.logger.error('[logout] currentUser json parse error')
    return Promise.reject()
  }

  const client: GraphQLClient | undefined = await getClient().catch(() => {
    datadogLogs.logger.error('[logout] GraphQLClient error')
    return undefined
  })

  // リクエスト用リソースを取得できなかった場合はローカルの情報をのみ破棄して終了
  if (!client) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('currentUser')
    return Promise.resolve()
  }

  const { query } = gql.mutation({
    operation: 'logoutManagementUser',
  })

  try {
    await client.request(query)
  } catch (error: any) {}

  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('currentUser')

  return Promise.resolve()
}

export const is = (type: string, obj: any) => {
  const clas = Object.prototype.toString.call(obj).slice(8, -1)
  return obj !== undefined && obj !== null && clas === type
}

export const convertOrderKey = (order: 'ascend' | 'descend' | 'asc' | 'desc' | undefined) => {
  if (order === undefined) return undefined
  if (order === 'asc' || order === 'desc') return order
  return order === 'ascend' ? 'asc' : 'desc'
}

export const getFilterToDateForDownload = (inputDate: Dayjs | null = null) => {
  const max = dayjs().subtract(4, 'd')
  if (!inputDate || inputDate > max) return max.format('YYYY-MM-DDT00:00:00')
  return inputDate.format('YYYY-MM-DDT00:00:00')
}

export const getCampaignNameForExport = (fileName: string | null = null, suffix: string | null = null) => {
  if (!fileName) return ''
  let fileName_ = fileName.substring(0, 50)
  if (suffix) {
    const suffix_ = suffix.substring(0, 50)
    fileName_ = fileName_ + '_' + suffix_
  }
  return fileName_
}

export const validatePasteValueLength = (event: React.ClipboardEvent, length: number) => {
  const paste = event.clipboardData.getData('text')
  if (paste?.length > length) {
    alert(VALIDATION_MESSAGES.PASTE_VALUE_OVER_LENGTH)
    event.preventDefault()
  }
}

export const fetchIp = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(IP_CHECK_URL)
      const result = await res.json()
      resolve(result.ip)
    } catch {
      datadogLogs.logger.debug(`Failed to Get IPAddress`)
      reject()
    }
  })
}

/**
 * ログインユーザのIPアドレスを取得
 * 3回トライに失敗したらログアウトする
 */
export const getUserIp = async () => {
  const result = await fetchIp()
    .catch(fetchIp)
    .catch(fetchIp)
    .then((ipAddress) => {
      return Promise.resolve(ipAddress)
    })
    .catch(async () => {
      datadogLogs.logger.error(`[Failed to Get IPAddress] ユーザー情報の検証に失敗したためログアウトしました`)
      await clearAuth().catch(() => {})
      toast.error('ユーザー情報の検証に失敗したためログアウトしました', {
        toastId: 'invalidUserError',
      })
      return Promise.reject()
    })
  return result
}

/**
 * キャンペーンステータス表示用のラベルを返す
 */
export const getCampaignStatusLabel = (
  campaignFrom: string,
  campaignTo: string,
): { color: string; label: string } | null => {
  if (!campaignFrom || !campaignTo) return null
  if (!dayjs(campaignFrom).isValid() || !dayjs(campaignTo).isValid()) return null

  let now = dayjs()

  // AM5時前は前日扱いとする
  if (now.hour() < 5) {
    now = now.subtract(1, 'd')
  }

  const now_ = parseInt(now.format('YYYYMMDD'))
  const campaignFrom_ = parseInt(campaignFrom.replaceAll('-', ''))
  const campaignTo_ = parseInt(campaignTo.replaceAll('-', ''))

  if (now_ < campaignFrom_) {
    return { color: 'blue', label: '開始前' }
  } else if (now_ <= campaignTo_) {
    return { color: 'magenta', label: '測定中' }
  } else {
    return { color: '', label: '終了' }
  }
}

export const hasError = (errors: { key: boolean }) => {
  for (const isError of Object.values(errors)) {
    if (isError) return true
  }
  return false
}

/**
 * 行頭に空白がある場合にtrue
 */
export const hasFrontBrank = (value: string) => {
  return /^[\s]+/.test(value)
}

/**
 * 行末に空白がある場合にtrue
 */
export const hasRearBrank = (value: string) => {
  return /\s+$/.test(value)
}

/**
 * CM素材リストからBase64デコードしたIDと銘柄名を抽出
 * @param cmMaterials
 * @returns
 */
const extractMaterialNames = (cmMaterials: ICmMaterial[]): IMaterialBrandName[] => {
  const extracted: any = cmMaterials
    .filter((m) => {
      return m.brand?.brandName
    })
    .map((material: any) => {
      try {
        const idWithResouceName = atob(material.id)
        const id = idWithResouceName.split(':')[1]
        return { id: id, brandName: material.brand?.brandName }
      } catch {
        // 何もしない
      }
    })
    .filter((m) => m)
  return extracted
}

/**
 * CM素材をIDの最新順でソート
 * @param cmMaterials
 * @returns
 */
export const sortMaterials = (cmMaterials: IMaterialBrandName[]): IMaterialBrandName[] => {
  cmMaterials.sort((x: any, y: any) => {
    let xKey = parseInt(x.id)
    let ykey = parseInt(y.id)
    let flg = 0

    if (xKey > ykey) {
      flg = -1
    } else {
      flg = 1
    }
    return flg
  })
  return cmMaterials
}

/**
 * 登録が最新のCM素材銘柄名を取得
 * @param cmMaterials
 * @returns string
 */
export const getMaterialBrandName = (cmMaterials: ICmMaterial[]) => {
  const extracted = extractMaterialNames(cmMaterials)
  if (!extracted.length) return ''
  const sorted = sortMaterials(extracted)
  if (!sorted.length) return ''
  return sorted[0].brandName
}

export const sortObjectsByBase64Id = (resources: any[]): any => {
  if (!resources.length) return []

  // ソート用のキーとしてBase64からIDを抽出
  const converted: any = resources
    .filter((resource: any) => {
      return resource.id
    })
    .map((resource: any) => {
      try {
        const idWithResouceName = atob(resource.id)
        const resourceId = idWithResouceName.split(':')[1]
        return { pk: parseInt(resourceId), ...resource }
      } catch {
        // 何もしない
      }
    })
    .filter((resource: any) => resource)

  // ソート
  const sorted = converted.sort((a: any, b: any) => {
    return a.pk < b.pk ? -1 : 1
  })

  return sorted
}

/**
 * 無効な値を検出し、nullを返す
 * @param value チェックする値
 * @returns チェック後の値。無効な値の場合はnull
 */
export const detectInvalidValue = (value: any): any => {
  if (value === undefined || value === null) return null
  if (typeof value === 'string' && value.trim() === '') return null
  return value
}
