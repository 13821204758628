import { ListButton, Create, Form, Input, InputNumber, useForm } from '@pankod/refine-antd'
import { usePermissions, IResourceComponentsProps } from '@pankod/refine-core'
import { IFaqGroup } from 'interfaces'
import React, { useState } from 'react'
import { required, maxLength, frontBlankTrim, rearBlankTrim, pattern } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const FaqGroupCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IFaqGroup>()
  const [inputNum, setInputNum] = useState()
  const { data: permissions } = usePermissions()
  if (!permissions?.includes('FAQ_GROUP')) {
    return <ErrorComponent />
  }

  return (
    <>
      <Helmet>
        <title>{`FAQ | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`FAQ | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={{ ...saveButtonProps }}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="faqGroups" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 18 }}
        >
          <Form.Item
            label="グループ名"
            name="faqGroupName"
            rules={[required(), maxLength(200), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={200} showCount placeholder="グループ名" />
          </Form.Item>
          <Form.Item label="表示順" name="displayOrder" rules={[required(), pattern('^[1-9][0-9]*$')]}>
            <InputNumber
              min={1}
              max={999999999}
              maxLength={9}
              value={inputNum}
              placeholder="表示順"
              onChange={(v: any) => setInputNum(v)}
            />
          </Form.Item>
        </Form>
      </Create>
    </>
  )
}
