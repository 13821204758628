import { Pagination, Col, EditButton, List, Row, Space, Table, useTable } from '@pankod/refine-antd'
import { usePermissions, HttpError, CrudSort } from '@pankod/refine-core'
import { IIpAddress } from 'interfaces'
import { is, convertOrderKey } from 'utils'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const IpAddressList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, tableQueryResult } = useTable<IIpAddress, HttpError>({
    permanentSorter: [sortCondition],
    metaData: {
      fields: ['id', 'companyName', 'ipAddress', 'isActive'],
      current: currentPage,
      pageSize: 10,
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('IP_ADDRESS')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`IPアドレス管理 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`IPアドレス管理 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Row>
        <Col span={24}>
          <List>
            <Table
              {...tableProps}
              rowKey="id"
              pagination={false}
              onChange={(_pagination, _filters, sorter: any) => {
                let field = ''
                if (is('String', sorter.field)) field = sorter.field
                else field = sorter.field.slice(-1)[0]
                setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
                tableQueryResult.refetch()
              }}
            >
              <Table.Column dataIndex="companyName" title="企業名称" sorter ellipsis />
              <Table.Column dataIndex="ipAddress" title="IPアドレス" sorter ellipsis />
              <Table.Column
                dataIndex={'isActive'}
                title="状態"
                ellipsis
                render={(value) => (value ? '利用可' : '利用停止中')}
              />
              <Table.Column<IIpAddress>
                title="操作"
                dataIndex="actions"
                fixed="right"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" recordItemId={record.id} icon={false} />
                    </Space>
                  )
                }}
              />
            </Table>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                current={currentPage}
                onChange={onChangePage}
                total={tableQueryResult?.data?.total}
                showSizeChanger={false}
              />
            </div>
          </List>
        </Col>
      </Row>
    </>
  )
}
