import { Button, Card, Col, Divider, Icons, Row, Typography } from '@pankod/refine-antd'
import { useGetIdentity, useNavigation } from '@pankod/refine-core'
import { Helmet } from 'react-helmet-async'
const { Title, Text } = Typography

export const Dashboard: React.FC = () => {
  const { push } = useNavigation()
  const { data: user } = useGetIdentity()
  return (
    <>
      <Helmet>
        <title>{`ホーム | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`ホーム | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      {user?.authorities && (
        <>
          {(user.authorities.includes('CAMPAIGN') ||
            user.authorities.includes('CLIENT') ||
            user.authorities.includes('SPONSOR') ||
            user.authorities.includes('BRAND')) && (
            <Title level={5} style={{ color: '#aaa' }}>
              <Icons.SoundFilled /> キャンペーン
            </Title>
          )}
          <Row gutter={[16, 16]}>
            {user.authorities.includes('CAMPAIGN') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>キャンペーン</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>計測対象のキャンペーンを登録/検索します。</Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('campaigns')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('CLIENT') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>依頼主</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>キャンペーン計測を依頼する会社を登録/検索します。</Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('clients')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('SPONSOR') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>広告主</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>
                      キャンペーン対象銘柄の広告主を登録/検索します。
                      <br />
                      広告主はTVCM広告統計で銘柄に紐づく広告主となります。
                    </Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('sponsors')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('BRAND') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>銘柄</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>
                      キャンペーン対象の銘柄を登録/検索します。
                      <br />
                      銘柄はTVCM広告統計での銘柄となります。
                    </Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('brands')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
          </Row>
          {user.authorities.includes('ACCOUNT_USER') && user.authorities.includes('ACCOUNT_USER_LICENSE') && (
            <>
              <Divider />
              <div style={{ marginTop: '30px' }}>
                <Title level={5} style={{ color: '#aaa' }}>
                  <Icons.SmileFilled /> アカウント
                </Title>
              </div>
            </>
          )}
          <Row gutter={[16, 16]}>
            {user.authorities.includes('ACCOUNT_USER') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>アカウント管理</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>依頼主ごとにログインIDとパスワードを作成/検索します。</Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('accountUsers')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('ACCOUNT_USER_LICENSE') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>ライセンス管理</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>依頼主ごとに発番されたログインIDに閲覧ライセンスを付与します。</Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('/accountUserLicenses/edit')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
          </Row>
          {(user.authorities.includes('INFORMATION') ||
            user.authorities.includes('FAQ_GROUP') ||
            user.authorities.includes('MANAGEMENT_USER') ||
            user.authorities.includes('CAMPAIGN_DOWNLOAD') ||
            user.authorities.includes('IP_ADDRESS')) && (
            <>
              <Divider />
              <div style={{ marginTop: '30px' }}>
                <Title level={5} style={{ color: '#aaa' }}>
                  <Icons.ToolFilled /> 管理
                </Title>
              </div>
            </>
          )}
          <Row gutter={[16, 16]}>
            {user.authorities.includes('INFORMATION') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>お知らせ</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>
                      ユーザーへのお知らせを設定します。
                      <br />
                      メンテナンス日時に設定した期間はログインできなくなります。
                    </Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('informations')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('FAQ_GROUP') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>FAQ</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>社内外からのサービス、業務に関する質問に対応するためのFAQを作成/検索します。</Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('faqGroups')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('MANAGEMENT_USER') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>管理ユーザー</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>
                      管理権限を持つユーザーを登録/権限付与します。
                      <br />
                      関係会社のみ登録可で、顧客の登録はNGです。
                    </Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('managementUsers')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('CAMPAIGN_DOWNLOAD') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>ダウンロード</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>キャンペーン結果データをCSVファイルでダウンロードします。</Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('/campaignDownload')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
            {user.authorities.includes('IP_ADDRESS') && (
              <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
                <Card size="small" title={<Title level={4}>IPアドレス管理</Title>} style={{ height: '100%' }}>
                  <div style={{ height: '80px', marginBottom: '20px' }}>
                    <Text>
                      アクセスを許可するIPアドレスを登録します。
                      <br />
                      登録ない場合はワンタイムパスワード認証となります。
                    </Text>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={() => push('ipAddresses')}>
                      確認
                    </Button>
                  </div>
                </Card>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  )
}
