import datadogLogs from 'logger'
import { Spin, AntdLayout, Button, Card, Col, Form, Input, Row, Typography } from '@pankod/refine-antd'
import { useGetIdentity, useNavigation, useNotification } from '@pankod/refine-core'
import { gqlDataProvider, GRAPHQL_ENDPOINT } from 'auth'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ONE_TIME_PASSWORD_REGEX } from 'utils/regex'
import './styles.css'
import { required, pattern } from 'utils'

const { Title } = Typography

export interface IOneTimePasswordForm {
  oneTimePassword: string
}

export const OneTimePasswordAuth: React.FC = () => {
  const { open } = useNotification()
  const { push } = useNavigation()
  const { data: user } = useGetIdentity()
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm<IOneTimePasswordForm>()

  useEffect(() => {
    // ログイン画面からのリダイレクトでなければ画面表示しない
    const tryLogin = sessionStorage.getItem('tryLogin')
    if (!tryLogin) {
      push('/')
    } else {
      // 判定後はキーを破棄
      sessionStorage.removeItem('tryLogin')

      // 遷移時点でメール送信がされている前提
      open({
        message: '登録メールアドレスに認証コードを送信しました',
        description: '認証コード送信',
        key: 'otp-send',
        type: 'success',
      })
      localStorage.setItem('currentUser', JSON.stringify(user))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const CardTitle = (
    <Title level={3} className="title">
      ワンタイムパスワード認証
    </Title>
  )

  const onFinish = async (values: any) => {
    setIsLoading(true)
    try {
      const { data } = await gqlDataProvider.custom!({
        url: GRAPHQL_ENDPOINT,
        method: 'post',
        metaData: {
          operation: 'oneTimePasswordAuthentication',
          variables: {
            input: {
              value: {
                id: user?.id,
                oneTimePassword: values.code,
              },
              type: 'OneTimePasswordInput!',
            },
          },
          fields: ['accessToken', 'refreshToken'],
        },
      })
      localStorage.setItem('accessToken', data.accessToken)
      localStorage.setItem('refreshToken', data.refreshToken)
      datadogLogs.logger.info(`[login] ${user.email}`)
      push('/')
      setIsLoading(false)
    } catch (error) {
      open({
        message: '認証コードが不正または有効期限が過ぎています',
        description: '認証エラー',
        key: 'otp-error',
        type: 'error',
      })
      setIsLoading(false)
      return Promise.reject()
    }
  }

  const onClickSendOtp = async () => {
    setIsLoading(true)
    try {
      await gqlDataProvider.custom!({
        url: GRAPHQL_ENDPOINT,
        method: 'post',
        metaData: {
          operation: 'sendOneTimePassword',
          variables: {
            input: {
              value: {
                id: user?.id,
              },
              type: 'SendOneTimePasswordInput!',
            },
          },
        },
      })
      open({
        message: '登録メールアドレスに認証コードを送信しました',
        description: '認証コード送信',
        key: 'otp-re-send',
        type: 'success',
      })
      setIsLoading(false)
    } catch (error) {
      open({
        message: '認証コードの送信に失敗しました',
        description: '認証コード送信エラー',
        key: 'otp-send-error',
        type: 'error',
      })
      setIsLoading(false)
      return Promise.reject()
    }
  }

  return (
    <>
      <Helmet>
        <title>{`ログイン | ${process.env.REACT_APP_SERVICE_NAME}`}</title>

        <meta name="description" content={`ログイン | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <AntdLayout className="layout">
        <Row
          justify="center"
          align="middle"
          style={{
            height: '100vh',
          }}
        >
          <Col xs={22}>
            <div className="container">
              <Card title={CardTitle} headStyle={{ borderBottom: 0, paddingBottom: 0 }} bodyStyle={{ paddingTop: 0 }}>
                <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
                  受信したメールに記載の認証コードを入力してください
                </div>
                {isLoading ? (
                  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Spin />
                  </div>
                ) : (
                  <></>
                )}
                <Form<IOneTimePasswordForm>
                  layout="vertical"
                  form={form}
                  style={{ marginTop: 0 }}
                  onFinish={onFinish}
                  requiredMark={false}
                >
                  <Form.Item
                    name="code"
                    label="認証コード"
                    rules={[required(), pattern(ONE_TIME_PASSWORD_REGEX, '6桁の数字を入力してください')]}
                    style={{ marginBottom: '12px' }}
                  >
                    <Input placeholder="123456" size="large" maxLength={6} />
                  </Form.Item>
                  <Button type="primary" size="large" htmlType="submit" block>
                    送信してログイン
                  </Button>
                  <div style={{ textAlign: 'center', marginTop: '12px' }}>
                    <Button type="text" size="small" onClick={onClickSendOtp}>
                      認証コードを再送する
                    </Button>
                  </div>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
      </AntdLayout>
    </>
  )
}
