import { Button, Edit, Form, Input, useForm } from '@pankod/refine-antd'
import {
  LayoutWrapper,
  useAuthenticated,
  useGetIdentity,
  useNavigation,
  useNotification,
  usePermissions,
  useRouterContext,
} from '@pankod/refine-core'
import { GRAPHQL_ENDPOINT } from 'auth'
import { InputPassword, InputRequiredConfirmPassword } from 'components/form'
import * as gql from 'gql-query-builder'
import { GraphQLClient } from 'graphql-request'
import { IManagementUserPasswordChange } from 'interfaces'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { clearAuth } from 'utils'
import { Header } from '../../components/header'
import { Sider } from '../../components/sider'
import { Title } from '../../components/title'
import { ErrorComponent } from 'components/404/ErrorComponent'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const ManagementUserPasswordChange: React.FC = () => {
  const { list, edit, push } = useNavigation()
  const { isError: authError } = useAuthenticated()
  const { useLocation } = useRouterContext()
  const { data: user } = useGetIdentity()
  const [submitted, setSubmitted] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const updatePassword = (password: string): void => setPassword(password)
  const updateConfirmPassword = (password: string): void => setConfirmPassword(password)

  const location = useLocation()
  const { id: resourceId, userName } = location?.state ?? { id: '', loginId: '' }
  const { formProps, saveButtonProps, queryResult } = useForm<IManagementUserPasswordChange>({
    resource: 'PasswordManagementUser',
    action: 'edit',
    metaData: {
      fields: ['id', 'password', 'confirmPassword'],
    },
  })

  const onFinish = async () => {
    if (password !== confirmPassword) return
    setSubmitted(true)
    try {
      let accessToken = localStorage.getItem('accessToken')
      if (!accessToken) return Promise.reject()
      const client = new GraphQLClient(GRAPHQL_ENDPOINT, { headers: { Authorization: `Bearer ${accessToken}` } })
      const { query, variables } = gql.mutation({
        operation: 'updatePasswordManagementUser',
        variables: {
          input: {
            value: {
              id: resourceId,
              password: password,
              confirmPassword: confirmPassword,
            },
            type: 'UpdatePasswordManagementUserInput!',
          },
        },
        fields: ['accessToken', 'refreshToken'],
      })

      const response = await client.request(query, variables)

      // 自分自身のPWを変更した場合はトークンを更新する
      if (user.id === resourceId) {
        localStorage.setItem('accessToken', response.updatePasswordManagementUser?.accessToken)
        localStorage.setItem('refreshToken', response.updatePasswordManagementUser?.refreshToken)
      }

      list('managementUsers')
      toast.success('パスワードを更新しました', {
        toastId: 'managementUserPasswordChangeSuccess',
      })
    } catch (error: any) {
      toast.error('更新に失敗しました', {
        toastId: 'managementUserPasswordChangeError',
      })
      setSubmitted(false)
      return Promise.reject()
    }
  }

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('MANAGEMENT_USER') || !resourceId) {
    if (authError) {
      clearAuth().catch(() => {})
      toast.error('ユーザー情報の検証に失敗したためログアウトしました', {
        toastId: 'invalidUserError',
      })
      push('/')
    }
    return (
      <LayoutWrapper Sider={Sider} Header={Header} Title={Title}>
        <ErrorComponent />
      </LayoutWrapper>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`管理ユーザー | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`管理ユーザー | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <LayoutWrapper Sider={Sider} Header={Header} Title={Title}>
        <Edit
          resource="managementUsers"
          title="パスワード変更"
          isLoading={queryResult?.isFetching}
          saveButtonProps={{ ...saveButtonProps, disabled: submitted }}
          canDelete={false}
          pageHeaderProps={{
            onBack: () => edit('managementUsers', resourceId),
            extra: (
              <>
                <Button onClick={() => edit('managementUsers', resourceId)}>編集画面に戻る</Button>
                <Button onClick={() => list('managementUsers')}>一覧画面に戻る</Button>
              </>
            ),
          }}
        >
          <Form
            {...formProps}
            layout="horizontal"
            labelCol={{ xxl: 5, xl: 5, lg: 6, md: 6, sm: 9 }}
            wrapperCol={{ xxl: 6, xl: 10, lg: 18, md: 18, sm: 15 }}
            colon={false}
            onFinish={onFinish}
          >
            <Form.Item label="ユーザー名">
              <Input disabled defaultValue={userName} maxLength={200} />
            </Form.Item>
            <InputPassword label="新しいパスワード" password={password} updatePassword={updatePassword} />
            <InputRequiredConfirmPassword
              label="新しいパスワード（確認）"
              confirmPassword={confirmPassword}
              updateConfirmPassword={updateConfirmPassword}
            />
            <Form.Item name={'id'} hidden initialValue={resourceId}></Form.Item>
          </Form>
        </Edit>
      </LayoutWrapper>
    </>
  )
}
