import * as gql from 'gql-query-builder'
import { GraphQLClient } from 'graphql-request'
import jwt_decode from 'jwt-decode'
import dataProvider from './DataProvider'

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT ?? ''
export const GRAPHQL_ENDPOINT = API_ENDPOINT + 'graphql'

export const isJwtExpired = (token: string) => {
  if (typeof token !== 'string' || !token) throw new Error('Invalid token provided')

  let isJwtExpired = false
  const decoded: any = jwt_decode(token)
  if (!('exp' in decoded)) {
    return isJwtExpired
  }
  const currentTime = new Date().getTime() / 1000

  if (currentTime > decoded.exp) isJwtExpired = true

  return isJwtExpired
}

export const getClient = async () => {
  let accessToken = localStorage.getItem('accessToken')
  if (!accessToken) return Promise.reject()

  // アクセストークンを検証
  let expired = false
  try {
    expired = isJwtExpired(accessToken)
  } catch {
    return Promise.reject()
  }

  // 有効な場合はヘッダに付与して返却
  if (!expired) return new GraphQLClient(GRAPHQL_ENDPOINT, { headers: { Authorization: `Bearer ${accessToken}` } })

  // 期限切れの場合はトークンをリフレッシュする
  const refreshToken = localStorage.getItem('refreshToken')
  if (!refreshToken) return Promise.reject()

  try {
    const { query, variables } = gql.mutation({
      operation: 'refreshTokenManagementUser',
      variables: {},
      fields: ['accessToken'],
    })

    const client = new GraphQLClient(GRAPHQL_ENDPOINT, { headers: { Authorization: `Bearer ${refreshToken}` } })
    const response = await client.request(query, variables)

    // 新しいトークンをセットして返却
    const newToken = response.refreshTokenManagementUser?.accessToken
    localStorage.setItem('accessToken', newToken)
    return new GraphQLClient(GRAPHQL_ENDPOINT, { headers: { Authorization: `Bearer ${newToken}` } })
  } catch (e) {
    localStorage.setItem('authError', '再ログインしてください')
    return Promise.reject()
  }
}

export const gqlDataProvider = dataProvider()
