import {
  Button,
  Card,
  Col,
  DateField,
  DatePicker,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  useSelect,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, CrudSort, HttpError, useCustom, usePermissions } from '@pankod/refine-core'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { ErrorComponent } from 'components/404/ErrorComponent'
import dayjs from 'dayjs'
import { IBrand, ICampaign, ICampaignFilterVariables, IClient, IDepartment } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  convertOrderKey,
  ellipsis,
  getCampaignStatusLabel,
  getMaterialBrandName,
  is,
  validatePasteValueLength,
} from 'utils'

const { Option } = Select

export const CampaignList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    ICampaign,
    HttpError,
    ICampaignFilterVariables
  >({
    permanentSorter: [sortCondition],
    metaData: {
      current: currentPage,
      pageSize: 10,
      fields: [
        {
          basicInfo: [
            'id',
            'campaignId', // キャンペーンID
            'campaignName', // キャンペーン名
            'campaignFrom', // CP期間From
            'campaignTo', // CP期間To
            'isActive', // CPの状態
            'confirmationStatus', // 情報登録
            {
              client: [
                'id',
                'clientName', // 依頼会社名
              ],
            },
            {
              sponsor: [
                'id',
                'sponsorName', // スポンサー
              ],
            },
          ],
          tvCmInfo: [
            {
              cmMaterials: [
                'id',
                {
                  brand: [
                    'id',
                    'brandName', // 銘柄名
                  ],
                },
              ],
            },
          ],
        },
        'isIndexErrorOccurred',
        'isEstimateExecuted',
        'isIndexDisplayable',
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { campaignName, campaignFrom, campaignTo, brand, client, department } = params

      filters.push({
        field: 'campaignName',
        operator: 'contains',
        value: campaignName ? campaignName : null,
      })

      filters.push({
        field: 'campaignFrom',
        operator: 'gte',
        value: campaignFrom ? dayjs(campaignFrom).format('YYYY/MM/DD 00:00:00') : undefined,
      })

      filters.push({
        field: 'campaignTo',
        operator: 'lte',
        value: campaignTo ? dayjs(campaignTo).format('YYYY/MM/DD 00:00:00') : undefined,
      })

      filters.push({
        field: 'brand.id',
        operator: 'eq',
        value: brand,
      })

      filters.push({
        field: 'client.id',
        operator: 'eq',
        value: client,
      })

      filters.push({
        field: 'department.id',
        operator: 'eq',
        value: department,
      })

      return filters
    },
  })

  useEffect(() => {
    setSortCondition({ field: 'campaignId', order: 'desc' } as CrudSort)
    tableQueryResult.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('CAMPAIGN')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`キャンペーン | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`キャンペーン | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} title={'フィルター'}>
            <Filter formProps={searchFormProps} filters={filters || []} setCurrentPage={setCurrentPage} />
          </Card>
        </Col>

        <Col xl={18} xs={24}>
          <List>
            <Table
              {...tableProps}
              rowKey="id"
              pagination={false}
              onChange={(_pagination, _filters, sorter: any) => {
                let field = ''
                // デフォルトソートID降順
                if (sorter.order === undefined) {
                  field = 'campaignId'
                  sorter.order = 'desc'
                } else if (is('String', sorter.field)) {
                  field = sorter.field
                } else if (sorter?.column?.title === '依頼会社名') {
                  field = 'client.clientName'
                } else {
                  field = sorter.field.slice(-1)[0]
                }

                setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
                tableQueryResult.refetch()
              }}
            >
              <Table.Column dataIndex={['basicInfo', 'campaignId']} title="キャンペーンID" ellipsis sorter />
              <Table.Column
                dataIndex={['basicInfo', 'campaignName']}
                title="キャンペーン名"
                ellipsis={{
                  showTitle: false,
                }}
                render={(value) => {
                  return (
                    <Tooltip placement="top" title={value}>
                      {ellipsis(value, 20)}
                    </Tooltip>
                  )
                }}
              />
              <Table.Column
                dataIndex={['basicInfo', 'client', 'clientName']}
                title="依頼会社名"
                ellipsis={{
                  showTitle: false,
                }}
                sorter
                render={(value) => {
                  return (
                    <Tooltip placement="top" title={value}>
                      {ellipsis(value, 20)}
                    </Tooltip>
                  )
                }}
              />
              <Table.Column
                dataIndex={['basicInfo', 'campaignFrom']}
                title="開始日"
                render={(value) => (value ? <DateField format="YYYY/MM/DD" value={value} /> : '')}
                sorter
                ellipsis
              />
              <Table.Column
                dataIndex={['basicInfo', 'campaignTo']}
                title="終了日"
                render={(value) => (value ? <DateField format="YYYY/MM/DD" value={value} /> : '')}
                sorter
                ellipsis
              />
              <Table.Column
                dataIndex={['basicInfo', 'sponsor', 'sponsorName']}
                title="広告主名"
                ellipsis={{
                  showTitle: false,
                }}
                render={(value) => {
                  return (
                    <Tooltip placement="top" title={value}>
                      {ellipsis(value, 20)}
                    </Tooltip>
                  )
                }}
              />
              <Table.Column
                dataIndex={['tvCmInfo', 'cmMaterials']}
                title="銘柄名"
                ellipsis={{
                  showTitle: false,
                }}
                render={(cmMaterials) => {
                  if (cmMaterials?.length) {
                    // 最新CM素材銘柄名を取得
                    const brandName = getMaterialBrandName(cmMaterials)
                    if (!brandName) return
                    return (
                      <Tooltip placement="top" title={brandName}>
                        {ellipsis(brandName, 20)}
                      </Tooltip>
                    )
                  }
                }}
              />
              <Table.Column
                dataIndex={['basicInfo', 'confirmationStatus']}
                title="情報登録"
                ellipsis
                render={(value) => {
                  if (value === 'DRAFT') {
                    return <Tag color="purple">起票済</Tag>
                  } else if (value === 'ACQUIRED') {
                    return <Tag color="blue">顧客情報取得済</Tag>
                  } else if (value === 'CONFIRMING') {
                    return <Tag color="magenta">顧客確認中</Tag>
                  } else if (value === 'COMPLETE') {
                    return <Tag color="green">完了</Tag>
                  }
                }}
              />
              <Table.Column
                dataIndex={['basicInfo']}
                title="CPの状態"
                ellipsis
                render={(basicInfo) => {
                  const statusLabel: { color: string; label: string } | null = getCampaignStatusLabel(
                    basicInfo.campaignFrom,
                    basicInfo.campaignTo,
                  )
                  return statusLabel && <Tag color={statusLabel.color}>{statusLabel.label}</Tag>
                }}
              />
              <Table.Column<ICampaign>
                dataIndex={'isIndexErrorOccurred'}
                title="指標算出"
                ellipsis
                render={(_, record) => {
                  if (!record?.isEstimateExecuted) return <Tag>未実行</Tag>
                  if (record?.isIndexErrorOccurred) {
                    return (
                      <Tag icon={<Icons.CloseCircleFilled />} color="error">
                        エラー
                      </Tag>
                    )
                  }
                  // 実行済、エラーなし、表示可能である場合に成功とみなす
                  if (record?.isIndexDisplayable) {
                    return (
                      <Tag icon={<Icons.CheckCircleFilled />} color="cyan">
                        成功
                      </Tag>
                    )
                  }
                  return <Tag>未実行</Tag>
                }}
              />
              <Table.Column<ICampaign>
                title="詳細"
                dataIndex="actions"
                fixed="right"
                ellipsis
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" type={'primary'} recordItemId={record.basicInfo?.id} icon={false}>
                        確認
                      </EditButton>
                    </Space>
                  )
                }}
              />
            </Table>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                current={currentPage}
                onChange={onChangePage}
                total={tableQueryResult?.data?.total}
                showSizeChanger={false}
              />
            </div>
          </List>
        </Col>
      </Row>
    </>
  )
}

const Filter: React.FC<{
  formProps: FormProps
  filters: CrudFilters
  setCurrentPage: (currentPage: number) => void
}> = (props) => {
  const { formProps, setCurrentPage } = props
  // 銘柄を取得
  const { selectProps: brandSelectProps } = useSelect<IBrand>({
    resource: 'brands',
    optionLabel: 'brandName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'brandName', 'brandCd'],
      pageSize: 9999999,
    },
  })

  // 依頼会社を取得
  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: 'clients',
    optionLabel: 'clientName',
    optionValue: 'id',
    filters: [{ field: 'id', operator: 'ne', value: 'Q2xpZW50OjE=' }], // 事務局除外
    metaData: {
      fields: ['id', 'clientName', 'clientCd'],
      pageSize: 9999999,
    },
  })

  // 部署を取得
  const { data: departments } = useCustom<[IDepartment]>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'departments',
    },
  })

  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
            <Form.Item label={'依頼会社'} name="client">
              <Select
                {...clientSelectProps}
                showArrow
                allowClear
                onSearch={undefined}
                filterOption={true}
                optionFilterProp="label"
                mode="multiple"
                placeholder={'部分一致/選択'}
              ></Select>
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
            <Form.Item label={'銘柄'} name="brand">
              <Select
                {...brandSelectProps}
                showArrow
                allowClear
                onSearch={undefined}
                filterOption={true}
                optionFilterProp="label"
                placeholder="部分一致/選択"
                mode="multiple"
              ></Select>
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'キャンペーン名'} name="campaignName">
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={200} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'VR営業担当部署'} name="department">
            <Select placeholder={'選択'} showArrow allowClear mode="multiple" showSearch={false}>
              {departments &&
                departments.data.map((department: any) => {
                  return (
                    <Option key={department.id} value={department.id}>
                      {department.department_name}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label={'CPの開始日'} name="campaignFrom">
              <DatePicker locale={locale} format="YYYY/MM/DD 以降" placeholder="日付を選択" style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label={'CPの終了日'} name="campaignTo">
              <DatePicker locale={locale} format="YYYY/MM/DD 以前" placeholder="日付を選択" style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button
              data-testid={'btn-test-search'}
              htmlType="submit"
              type="primary"
              size="large"
              block
              onClick={() => setCurrentPage(1)}
            >
              {'絞り込み'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
