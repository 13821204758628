import { Form, Input } from '@pankod/refine-antd'
import React from 'react'

type InputRequiredConfirmPasswordProps = {
  label: string | undefined
  confirmPassword: string | undefined
  updateConfirmPassword: (password: string) => void
}

export const InputRequiredConfirmPassword: React.FC<InputRequiredConfirmPasswordProps> = (props) => {
  const { label, confirmPassword, updateConfirmPassword } = props

  return (
    <Form.Item
      label={label}
      name={'confirmPassword'}
      dependencies={['password']}
      rules={[
        {
          required: true,
          message: <span style={{ color: 'red' }}>入力してください</span>,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(<span style={{ color: 'red' }}>パスワードが一致しません</span>)
          },
        }),
      ]}
    >
      <Input.Password
        value={confirmPassword}
        minLength={8}
        maxLength={16}
        placeholder="●●●●●●●●"
        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => updateConfirmPassword(event.target.value)}
      />
    </Form.Item>
  )
}
