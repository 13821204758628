import { Modal, Spin } from '@pankod/refine-antd'
import { useNotification } from '@pankod/refine-core'
import { gqlDataProvider } from 'auth'
import { Buffer } from 'buffer'
import { ICampaign } from 'interfaces'
import datadogLogs from 'logger'
import React, { useState } from 'react'

interface Props {
  record: ICampaign
  retryEstimateModalVisible: boolean
  setRetryEstimateModalVisible: (v: boolean) => void
}

export const RetryEstimateModal = React.memo((props: Props) => {
  const { record, retryEstimateModalVisible, setRetryEstimateModalVisible } = props
  const { open } = useNotification()
  const [isModalLoading, setIsModalLoading] = useState(false)

  const onClickEstimateRequest = async () => {
    setIsModalLoading(true)
    try {
      const { data: requestedCampaignId } = await gqlDataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'updateIsEstimateExecutedToFalse',
          variables: {
            input: {
              value: {
                id: record?.basicInfo?.id,
              },
              type: 'UpdateFlagCampaignInput!',
            },
          },
        },
      })

      if (!requestedCampaignId) throw Error
      const decodedCampaignId = Buffer.from(requestedCampaignId.toString(), 'base64').toString('utf-8')
      datadogLogs.logger.info(`[requestRetryEstimate] campaignId: ${decodedCampaignId}`)

      open({
        message: '指標算出ステータスを未実行に更新しました',
        description: '成功',
        key: 'estimateSuccess',
        type: 'success',
      })

      // リクエスト成功時に未実行ステータス表示に切り替える
      if (record) record.isEstimateExecuted = false

      closeModal()
      return Promise.resolve()
    } catch (error) {
      open({
        message: '指標算出ステータス変更に失敗しました',
        description: '更新エラー',
        key: 'estimateError',
        type: 'error',
      })
      datadogLogs.logger.error(
        `[requestRetryEstimateFailed] campaignId: ${record?.basicInfo?.campaignId ?? 'unknown'} ${error}`,
      )

      closeModal()
      return Promise.reject()
    }
  }

  const closeModal = () => {
    setRetryEstimateModalVisible(false)
    setIsModalLoading(false)
  }

  return (
    <Modal
      title="指標算出の再実行"
      centered
      visible={retryEstimateModalVisible}
      onOk={() => onClickEstimateRequest()}
      onCancel={() => setRetryEstimateModalVisible(false)}
    >
      <p>指標算出ステータスを未実行に戻し、今後の実行対象に含めますか？</p>
      {isModalLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      ) : null}
    </Modal>
  )
})
