import { Form, Input } from '@pankod/refine-antd'
import React from 'react'
import { PASSWORD_REGEX } from 'utils/regex'
import { required, pattern, frontBlankTrim, rearBlankTrim } from 'utils'

type SelectInputPasswordProps = {
  label: string | undefined
  password: string | undefined
  updatePassword: (password: string) => void
}

export const InputPassword: React.FC<SelectInputPasswordProps> = (props) => {
  const { label, password, updatePassword } = props

  return (
    <Form.Item
      label={label}
      name="password"
      rules={[
        required(),
        frontBlankTrim(),
        rearBlankTrim(),
        pattern(PASSWORD_REGEX, '8〜16文字で必ず1つ以上の英字と1つ以上の数字を含めてください'),
      ]}
    >
      <Input.Password
        autoComplete="new-password"
        minLength={8}
        maxLength={16}
        value={password}
        placeholder="●●●●●●●●"
        onChange={(e) => updatePassword(e.target.value)}
      />
    </Form.Item>
  )
}
