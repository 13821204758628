import { Spin, AntdLayout, Button, Card, Col, Form, Input, Row, Typography } from '@pankod/refine-antd'
import { useLogin, useRouterContext, useNotification } from '@pankod/refine-core'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import './styles.css'
import { maxLength, required, pattern, lowerCaseOnly, frontBlankTrim, rearBlankTrim } from '../../utils/rules'
import { EMAIL_RFC5321_REGEX } from 'utils'

const { Title } = Typography

export interface ILoginForm {
  email: string
  password: string
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>()
  const { mutate: login, isLoading } = useLogin<ILoginForm>()
  const { useLocation, useHistory } = useRouterContext()
  const location = useLocation()
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)

  useEffect(() => {
    // OTP送信の際にOTP入力画面への遷移ができないのでリダイレクト情報を破棄
    // https://github.com/rossoinc/VR-TotalReachEstimation-ManagementUI/issues/45
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('to')
    history.replace({
      search: '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authErrorMessage = localStorage.getItem('authError')
  const { open } = useNotification()
  if (authErrorMessage) {
    open!({ type: 'error', message: authErrorMessage })
    localStorage.removeItem('authError')
  }

  const CardTitle = (
    <Title level={3} className="title">
      管理画面ログイン
    </Title>
  )

  const doLogin = async (values: any) => {
    login(values, {
      onError: () => {
        setIsPasswordChanged(false)
        setPassword('')
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{`ログイン | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`ログイン | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <AntdLayout className="layout">
        <Row
          justify="center"
          align="middle"
          style={{
            height: '100vh',
          }}
        >
          <Col xs={22}>
            <div className="container">
              <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                {isLoading ? (
                  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Spin />
                  </div>
                ) : (
                  <></>
                )}
                <Form<ILoginForm>
                  layout="vertical"
                  form={form}
                  onFinish={(values: any) => {
                    const ipass = values
                    ipass.password = values.password.target.value
                    doLogin(ipass)
                  }}
                  requiredMark={false}
                >
                  <Form.Item
                    name="email"
                    label="メールアドレス"
                    rules={[
                      required('メールアドレスを入力してください'),
                      pattern(EMAIL_RFC5321_REGEX, '有効なメールアドレスを入力してください'),
                      maxLength(200),
                      frontBlankTrim(),
                      rearBlankTrim(),
                      lowerCaseOnly(),
                    ]}
                  >
                    <Input size="large" placeholder="user@example.com" maxLength={200} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="パスワード"
                    rules={[
                      {
                        required: true,
                        message: <span style={{ color: 'red' }}>パスワードを入力してください</span>,
                      },
                    ]}
                    style={{ marginBottom: '12px' }}
                    valuePropName="password"
                    help={
                      isPasswordChanged && password === '' ? (
                        <span style={{ color: 'red' }}>パスワードを入力してください</span>
                      ) : (
                        ''
                      )
                    }
                    validateStatus={isPasswordChanged && password === '' ? 'error' : 'success'}
                  >
                    <Input
                      type="password"
                      placeholder="●●●●●●●●"
                      size="large"
                      maxLength={200}
                      value={password}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIsPasswordChanged(true)
                        setPassword(event.target.value)
                      }}
                    />
                  </Form.Item>
                  <Button type="primary" size="large" htmlType="submit" block>
                    ログイン
                  </Button>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
      </AntdLayout>
    </>
  )
}
