import { Create, Form, Input, ListButton, Switch, useForm } from '@pankod/refine-antd'
import { IResourceComponentsProps, useNotification, usePermissions } from '@pankod/refine-core'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { IInformation } from 'interfaces'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  frontBlankTrim,
  maxLength,
  oneCharacterRequired,
  rearBlankTrim,
  required,
  validatePasteValueLength,
} from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

const { RangePicker } = DateTimePicker

export const InformationCreate: React.FC<IResourceComponentsProps> = () => {
  const { open } = useNotification()
  const { formProps, saveButtonProps } = useForm<IInformation>()
  const [publishRangeInput, setPublishRangeInput] = useState<[Dayjs, Dayjs]>()
  const [maintenanceRangeInput, setMaintenanceRangeInput] = useState<[Dayjs, Dayjs]>()
  const [hasDatetimeError, setHasDatetimeError] = useState(false)
  const [isMaintenanceMode, setMaintenanceMode] = useState(false)
  const { data: permissions } = usePermissions()

  if (!permissions?.includes('INFORMATION')) {
    return <ErrorComponent />
  }

  const { form } = formProps
  const onClick = () => {
    if (isMaintenanceMode && !maintenanceRangeInput) {
      open({
        message: 'ページをリロードして入力し直してください',
        key: 'error',
        type: 'error',
      })
      return
    }

    if (document.getElementById('validationError') != null || !publishRangeInput) {
      if (!publishRangeInput) setHasDatetimeError(true)
      open({
        message: '必須項目が未入力または入力に誤りがあります',
        key: 'validateError',
        type: 'error',
      })
      return
    }

    form?.setFieldsValue({
      publishStartAt: publishRangeInput[0].startOf('m'),
      publishEndAt: publishRangeInput[1].startOf('m'),
    })

    if (isMaintenanceMode && maintenanceRangeInput) {
      form?.setFieldsValue({
        maintenanceStartAt: maintenanceRangeInput[0].startOf('m'),
        maintenanceEndAt: maintenanceRangeInput[1].startOf('m'),
        isMaintenanceMode: true,
      })
    } else {
      form?.setFieldsValue({
        maintenanceStartAt: null,
        maintenanceEndAt: null,
        isMaintenanceMode: false,
      })
    }
    form?.submit()
  }
  return (
    <>
      <Helmet>
        <title>{`お知らせ | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`お知らせ | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={{ ...saveButtonProps, onClick: onClick }}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="informations" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          colon={false}
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 18, xl: 18, lg: 18, md: 18, sm: 18 }}
          initialValues={{
            isMaintenanceMode: false,
          }}
        >
          <Form.Item
            label="見出し"
            name="title"
            rules={[required(), maxLength(200), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={200} showCount placeholder="見出し" />
          </Form.Item>

          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 1000)}>
            <Form.Item label="内容" name="content" rules={[required(), maxLength(1000), oneCharacterRequired()]}>
              <Input.TextArea rows={10} showCount maxLength={1000} placeholder="内容" />
            </Form.Item>
          </div>

          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item
              label="掲載日時"
              rules={[required()]}
              required
              help={hasDatetimeError ? <span style={{ color: 'red' }}>入力してください</span> : null}
            >
              <RangePicker
                locale={locale}
                format="YYYY/MM/DD HH:mm"
                allowClear={false}
                style={hasDatetimeError ? { border: '1px solid red', borderRadius: '6px' } : {}}
                placeholder={['開始日時', '終了日時']}
                showTime={{ defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                value={publishRangeInput}
                ranges={{ 現在時刻: [dayjs(), dayjs()] }}
                onChange={(value: any) => {
                  setHasDatetimeError(false)
                  setPublishRangeInput(value)
                }}
              />
            </Form.Item>
          </div>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label="メンテナンス日時" help="メンテナンス不要の場合は入力しないでください">
              <RangePicker
                locale={locale}
                format="YYYY/MM/DD HH:mm"
                allowClear
                placeholder={['開始日時', '終了日時']}
                showTime={{ defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                value={maintenanceRangeInput}
                ranges={{ 現在時刻: [dayjs(), dayjs()] }}
                onChange={(value: any) => {
                  setMaintenanceRangeInput(value)
                  if (value) setMaintenanceMode(true)
                  else setMaintenanceMode(false)
                }}
              />
            </Form.Item>
          </div>
          <Form.Item name="publishStartAt" hidden></Form.Item>
          <Form.Item name="publishEndAt" hidden></Form.Item>
          <Form.Item name="maintenanceStartAt" hidden></Form.Item>
          <Form.Item name="maintenanceEndAt" hidden></Form.Item>
          <Form.Item name="isMaintenanceMode" hidden>
            <Switch checked={isMaintenanceMode} />
          </Form.Item>
        </Form>
      </Create>
    </>
  )
}
