import {
  Button,
  Card,
  Col,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  useSelect,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, CrudSort, HttpError, usePermissions } from '@pankod/refine-core'
import { IBrand, IBrandFilterVariables, ISponsor } from 'interfaces'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { is, convertOrderKey, validatePasteValueLength } from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const BrandList: React.FC = () => {
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const [currentPage, setCurrentPage] = useState(1)
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<IBrand, HttpError, IBrandFilterVariables>(
    {
      permanentSorter: [sortCondition],
      metaData: {
        fields: ['id', 'brandCd', 'brandName', { sponsor: ['id', 'sponsorName'] }],
        current: currentPage,
        pageSize: 10,
      },
      onSearch: (params) => {
        const filters: CrudFilters = []
        const { brandCd, brandName, sponsor } = params

        filters.push({
          field: 'brandCd',
          operator: 'contains',
          value: brandCd ? brandCd : null,
        })

        filters.push({
          field: 'brandName',
          operator: 'contains',
          value: brandName ? brandName : null,
        })

        filters.push({
          field: 'sponsor.id',
          operator: 'eq',
          value: sponsor,
        })

        return filters
      },
    },
  )

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('BRAND')) {
    return <ErrorComponent />
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <Helmet>
        <title>{`銘柄 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`銘柄 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card bordered={false} title={'フィルター'}>
            <Filter formProps={searchFormProps} filters={filters || []} setCurrentPage={setCurrentPage} />
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <List>
            <Table
              {...tableProps}
              rowKey="id"
              pagination={false}
              onChange={(_pagination, _filters, sorter: any) => {
                let field = ''
                if (is('String', sorter.field)) {
                  field = sorter.field
                } else if (sorter?.column?.title === '広告主') {
                  field = 'sponsor.sponsorName'
                } else {
                  field = sorter.field.slice(-1)[0]
                }
                setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
                tableQueryResult.refetch()
              }}
            >
              <Table.Column dataIndex="brandCd" title="銘柄コード" sorter ellipsis />
              <Table.Column dataIndex="brandName" title="銘柄" sorter ellipsis />
              <Table.Column dataIndex={['sponsor', 'sponsorName']} title="広告主" sorter ellipsis />
              <Table.Column<IBrand>
                title="操作"
                dataIndex="actions"
                fixed="right"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" recordItemId={record.id} icon={false} />
                    </Space>
                  )
                }}
              />
            </Table>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                current={currentPage}
                onChange={onChangePage}
                total={tableQueryResult?.data?.total}
                showSizeChanger={false}
              />
            </div>
          </List>
        </Col>
      </Row>
    </>
  )
}

const Filter: React.FC<{
  formProps: FormProps
  filters: CrudFilters
  setCurrentPage: (currentPage: number) => void
}> = (props) => {
  const { formProps, setCurrentPage } = props

  const { selectProps: sponsorSelectProps } = useSelect<ISponsor>({
    resource: 'sponsors',
    optionLabel: 'sponsorName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'sponsorName'],
      pageSize: 9999999,
    },
  })

  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'銘柄コード'} name="brandCd">
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={8} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={'銘柄'} name="brandName">
            <Input placeholder={'部分一致検索'} prefix={<Icons.SearchOutlined />} maxLength={100} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}>
            <Form.Item label={'広告主'} name="sponsor">
              <Select
                {...sponsorSelectProps}
                showArrow
                allowClear
                onSearch={undefined}
                filterOption={true}
                optionFilterProp="label"
                mode="multiple"
                placeholder={'部分一致/選択'}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block onClick={() => setCurrentPage(1)}>
              {'絞り込み'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
