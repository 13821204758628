import {
  Icons,
  Button,
  Card,
  Col,
  Descriptions,
  EditButton,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  useSelect,
} from '@pankod/refine-antd'
import { FormInstance } from 'antd'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import UUID from 'uuidjs'
import dayjs from 'dayjs'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { ExtendImportAdhInfo, ICampaign, IService, IAdMaterialInfo, IAutoAdhInfo } from 'interfaces'
import React, { useEffect, useRef, useState } from 'react'
import { frontBlankTrim, hasError, rearBlankTrim, required, validatePasteValueLength } from 'utils'
const { Title } = Typography
const { RangePicker } = DateTimePicker

interface Props {
  record: ICampaign | undefined
  input: {
    inputCampaign: any
    updateInputCampaign: any
  }
  adhInfos: {
    importAdhInfos: ExtendImportAdhInfo[]
    setImportAdhInfos: (v: ExtendImportAdhInfo[]) => void
  }
  errors: any
}

export const DigitalPlacementInfo: React.FC<Props> = (props) => {
  const { record, input, adhInfos, errors } = props
  const { inputCampaign, updateInputCampaign } = input
  const { importAdhInfos, setImportAdhInfos } = adhInfos
  const [isModalToCreateClientSpecifiedIdVisible, setIsModalToCreateClientSpecifiedIdVisible] = useState(false)
  const [isModalToEditClientSpecifiedIdVisible, setIsModalToEditClientSpecifiedIdVisible] = useState(false)
  const [currentImportAdhInfoRecord, setCurrentImportAdhInfoRecord] = useState<ExtendImportAdhInfo>()
  const [autoAdhInfos, setAutoAdhInfos] = useState<IAutoAdhInfo[]>([])
  const [adMaterialInfos, setAdMaterialInfos] = useState<IAdMaterialInfo[]>([])

  // サービスを取得
  const { selectProps: servicesSelectProps, queryResult: serviceQueryResult } = useSelect<IService>({
    resource: 'services',
    optionLabel: 'serviceName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'serviceName'],
      pageSize: 9999999,
    },
  })

  // プラットフォームを取得
  const { selectProps: adPlatformsSelectProps } = useSelect<IService>({
    resource: 'adPlatforms',
    optionLabel: 'adPlatformName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'adPlatformName'],
      pageSize: 9999999,
    },
  })

  // 新規作成用モーダル
  const ModalToCreateClientSpecifiedId = () => {
    const refCreateModalForm = useRef<FormInstance>(null)
    return (
      <Modal
        centered
        title="依頼主指定ID作成"
        visible={isModalToCreateClientSpecifiedIdVisible}
        maskClosable={false}
        onOk={async () => {
          const { serviceId, importAdhId, adAreaType } = refCreateModalForm.current?.getFieldsValue()
          const selectedService: IService | undefined = serviceQueryResult.data?.data.filter(
            (v) => v.id === serviceId,
          )[0]

          // 必須チェック isValidならreturnされる
          await refCreateModalForm.current?.validateFields(['importAdhId', 'serviceId', 'adAreaType'])

          const newInfo: ExtendImportAdhInfo = {
            rowId: UUID.generate(),
            imported: false,
            importAdhId: importAdhId,
            adAreaType: adAreaType,
            service: {
              id: selectedService?.id ?? '',
              serviceName: selectedService?.serviceName ?? '',
              isActive: selectedService?.isActive ?? true,
            },
          }
          const newInfos = [...importAdhInfos, newInfo]
          setImportAdhInfos(newInfos)
          setIsModalToCreateClientSpecifiedIdVisible(false)
        }}
        okText={'登録'}
        onCancel={() => setIsModalToCreateClientSpecifiedIdVisible(false)}
      >
        <Form
          labelCol={{ xxl: 6, xl: 6, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 18, xl: 18, lg: 18, md: 18, sm: 18 }}
          ref={refCreateModalForm}
          colon={false}
        >
          <Form.Item label="ID" name={'importAdhId'} rules={[frontBlankTrim(), rearBlankTrim(), required()]}>
            <Input placeholder={'ID'} maxLength={20} showCount />
          </Form.Item>
          <Form.Item label={'サービス名称'} name={'serviceId'} rules={[required()]}>
            <Select {...servicesSelectProps} showArrow placeholder={'選択'} showSearch={false}></Select>
          </Form.Item>
          <Form.Item label={'地区'} name={'adAreaType'} rules={[required()]} initialValue={'ALL_AREA'}>
            <Select showArrow placeholder={'選択'} showSearch={false} disabled>
              <option key={'ALL_AREA'} value={'ALL_AREA'}>
                全国
              </option>
              <option key={'SAME_TV_AREA'} value={'SAME_TV_AREA'}>
                TVと同一
              </option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const editModalShow = (record: ExtendImportAdhInfo) => {
    setCurrentImportAdhInfoRecord(record)
    setIsModalToEditClientSpecifiedIdVisible(true)
  }

  // 編集用モーダル
  const ModalToEditClientSpecifiedId = ({
    currentImportAdhInfoRecord,
  }: {
    currentImportAdhInfoRecord: ExtendImportAdhInfo
  }) => {
    const refEditModalForm = useRef<FormInstance>(null)
    return (
      <Modal
        centered
        title="依頼主指定ID編集"
        visible={isModalToEditClientSpecifiedIdVisible}
        maskClosable={false}
        onOk={async () => {
          const { serviceId, importAdhId, adAreaType } = refEditModalForm.current?.getFieldsValue()
          const selectedService: IService | undefined = serviceQueryResult.data?.data.filter(
            (v) => v.id === serviceId,
          )[0]

          // 必須チェック isNotValidならreturnされる
          await refEditModalForm.current?.validateFields(['importAdhId', 'serviceId', 'adAreaType'])

          const newInfos = importAdhInfos.map((importAdhInfo) => {
            if (importAdhInfo.rowId === currentImportAdhInfoRecord.rowId) {
              const newImportAdhInfo: ExtendImportAdhInfo = {
                rowId: currentImportAdhInfoRecord.rowId,
                id: currentImportAdhInfoRecord?.id,
                imported: importAdhInfo.imported,
                importAdhId: importAdhId,
                adAreaType: adAreaType,
                service: {
                  id: selectedService?.id ?? '',
                  serviceName: selectedService?.serviceName ?? '',
                  isActive: selectedService?.isActive ?? true,
                },
              }
              return newImportAdhInfo
            }
            return importAdhInfo
          })
          setImportAdhInfos(newInfos)
          setIsModalToEditClientSpecifiedIdVisible(false)
        }}
        okText={'登録'}
        onCancel={() => {
          setIsModalToEditClientSpecifiedIdVisible(false)
        }}
      >
        <Form
          title="changeForm"
          ref={refEditModalForm}
          labelCol={{ xxl: 6, xl: 6, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 18, xl: 18, lg: 18, md: 18, sm: 18 }}
          colon={false}
        >
          <Form.Item
            label="ID"
            name="importAdhId"
            initialValue={currentImportAdhInfoRecord?.importAdhId}
            rules={[frontBlankTrim(), rearBlankTrim(), required()]}
          >
            <Input placeholder="ID" maxLength={20} showCount />
          </Form.Item>
          <Form.Item
            label="サービス名称"
            name="serviceId"
            rules={[required()]}
            initialValue={currentImportAdhInfoRecord?.service?.id}
          >
            <Select {...servicesSelectProps} showArrow placeholder="選択" showSearch={false}></Select>
          </Form.Item>
          <Form.Item
            label="地区"
            name="adAreaType"
            rules={[required()]}
            initialValue={currentImportAdhInfoRecord?.adAreaType}
          >
            <Select showArrow placeholder={'選択'} showSearch={false} disabled>
              <option key={'ALL_AREA'} value={'ALL_AREA'}>
                全国
              </option>
              <option key={'SAME_TV_AREA'} value={'SAME_TV_AREA'}>
                TVと同一
              </option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  // システム連携済IDテーブル
  useEffect(() => {
    if (record?.digitalPlacementInfo.autoAdhInfos) {
      let newArray: IAutoAdhInfo[] = []
      record?.digitalPlacementInfo.autoAdhInfos.map((item: any) => {
        newArray.push({
          id: item.id,
          adhCampaignId: item.adhCampaignId, // ID
          service: item.service, // サービス名称
          adhCampaignFrom: dayjs(item.adhCampaignFrom), // 開始
          adhCampaignTo: dayjs(item.adhCampaignTo), // 終了
          adgroupType: item.adgroupType.filter((v: string) => v).join(', '), // 配信メニュー
          streamingDevice: item.streamingDevice, // 配信デバイス
        })
      })
      setAutoAdhInfos(newArray)
    }
  }, [record])

  // 広告素材テーブル
  useEffect(() => {
    if (record?.digitalPlacementInfo.adMaterialInfos) {
      let newArray: IAdMaterialInfo[] = []
      record?.digitalPlacementInfo.adMaterialInfos.map((item: any) => {
        newArray.push({
          id: item.id,
          adMaterialName: item.adMaterialName, // 広告素材名
          adMaterialVideoUrl: item.adMaterialVideoUrl, // 動画URL
          movieSeconds: item.movieSeconds, // 動画の秒数
          adhCampaignIdAdhGroupId: item.adhCampaignIdAdhGroupId.filter((v: string) => v).join(', '), // キャンペーンID_広告グループ
        })
      })
      setAdMaterialInfos(newArray)
    }
  }, [record])

  return (
    <>
      {/* ********** デジタル出稿情報 ********** */}
      <Card
        size="small"
        title={
          <Title level={5} style={{ color: 'white' }}>
            デジタル出稿情報
          </Title>
        }
        style={{ marginTop: '20px' }}
        headStyle={{ backgroundColor: '#7c4a85' }}
      >
        <div style={{ overflowX: 'scroll' }}>
          {/* Descriptionsタグ内のForm項目は無効となる。表示用として使用する */}
          <Descriptions bordered size="small" column={3} style={{ minWidth: '860px' }}>
            <Form.Item
              label={'プラットフォーム名'}
              name={['digitalPlacementInfo', 'adPlatformId']}
              initialValue={inputCampaign?.adPlatformId}
            >
              <Select
                {...adPlatformsSelectProps}
                style={
                  hasError(errors.adPlatformId)
                    ? { border: '1px solid red', borderRadius: '6px', width: '100%' }
                    : { width: '100%' }
                }
                showArrow
                showSearch={false}
                placeholder={'選択'}
                value={inputCampaign.adPlatformId}
                onChange={(e) => updateInputCampaign('adPlatformId', e)}
              ></Select>
              {errors.adPlatformId.required && <span style={{ color: 'red', width: '100%' }}>選択してください</span>}
            </Form.Item>

            <Descriptions.Item span={2} label="期間">
              <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
                <RangePicker
                  locale={locale}
                  format="YYYY/MM/DD"
                  placeholder={['開始日時', '終了日時']}
                  style={hasError(errors.digitalPlacingPeriod) ? { border: '1px solid red', borderRadius: '6px' } : {}}
                  value={
                    inputCampaign.digitalPlacingPeriod
                      ? [dayjs(inputCampaign.digitalPlacingPeriod[0]), dayjs(inputCampaign.digitalPlacingPeriod[1])]
                      : null
                  }
                  onChange={(value: any) => {
                    if (!value) {
                      updateInputCampaign('digitalPlacingPeriod', null)
                    } else {
                      updateInputCampaign('digitalPlacingPeriod', [
                        value[0].format('YYYY-MM-DDT00:00:00'),
                        value[1].format('YYYY-MM-DDT00:00:00'),
                      ])
                    }
                  }}
                />
              </div>
              {errors.digitalPlacingPeriod.required && (
                <span style={{ color: 'red', marginLeft: '10px', width: '100%' }}>入力してください</span>
              )}
            </Descriptions.Item>

            <Form.Item
              label="ターゲット属性"
              name={['digitalPlacementInfo', 'digitalTargetAttribute']}
              initialValue={inputCampaign.digitalTargetAttribute}
            >
              <Input
                placeholder={'ターゲット属性'}
                style={
                  hasError(errors.digitalTargetAttribute)
                    ? { border: '1px solid red', width: '100%' }
                    : { width: '100%' }
                }
                maxLength={200}
                showCount
                value={inputCampaign.digitalTargetAttribute}
                onChange={(e) => updateInputCampaign('digitalTargetAttribute', e)}
              />
              {errors.digitalTargetAttribute?.requiredFrontTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行頭に空白は使えません</span>
                  <br />
                </div>
              )}
              {errors.digitalTargetAttribute?.requiredRearTrim && (
                <div style={{ width: '100%' }}>
                  <span style={{ color: 'red' }}>行末に空白は使えません</span>
                  <br />
                </div>
              )}
            </Form.Item>
          </Descriptions>
        </div>

        <Row gutter={[8, 8]}>
          <Col span={2} xxl={10} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Space align="baseline">
              <Title level={5}>依頼主指定ID</Title>
              <Button
                size="small"
                type="primary"
                style={{ margin: '8px 8px 8px 8px' }}
                onClick={() => setIsModalToCreateClientSpecifiedIdVisible(true)}
              >
                追加
              </Button>
            </Space>
            <ModalToCreateClientSpecifiedId />

            <Table
              bordered
              size="small"
              columns={[
                { title: 'ID', dataIndex: 'importAdhId' },
                {
                  title: 'サービス名称',
                  dataIndex: ['service', 'serviceName'],
                },
                {
                  title: '地区',
                  dataIndex: 'adAreaType',
                  render: (value) => (value === 'ALL_AREA' ? '全国' : 'TVと同一'),
                },
                {
                  title: 'アクション',
                  dataIndex: 'button',
                  fixed: 'right',
                  width: '102px',
                  align: 'center',
                  render: (_, record) => (
                    <Space>
                      <EditButton
                        data-testId={'test-edit-btn'}
                        size="small"
                        onClick={() => editModalShow(record)}
                        icon={false}
                      />
                      {/* 削除ボタン */}
                      <Button
                        size="small"
                        style={{ borderColor: '#e32020' }}
                        onClick={() => {
                          // 未登録の行は固有IDを持っていないので行作成時に付与したrowIdで対象行を特定
                          if ('rowId' in record) {
                            setImportAdhInfos(importAdhInfos.filter((adhInfo) => adhInfo.rowId !== record.rowId))
                          } else {
                            setImportAdhInfos(importAdhInfos.filter((adhInfo) => adhInfo.id !== record.id))
                          }
                        }}
                      >
                        <Icons.DeleteTwoTone twoToneColor={'#e32020'} />
                      </Button>
                    </Space>
                  ),
                },
              ]}
              dataSource={importAdhInfos}
              pagination={false}
              scroll={{ x: 500 }}
            />
            {currentImportAdhInfoRecord && (
              <ModalToEditClientSpecifiedId currentImportAdhInfoRecord={currentImportAdhInfoRecord} />
            )}
          </Col>

          <Col span={2} xxl={14} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div style={{ margin: '8px 8px 0px 8px', height: '32px', padding: '4px 0px' }}>
              <Title level={5}>システム連携済ID</Title>
            </div>
            <Table
              bordered
              size="small"
              columns={[
                { title: 'ID', dataIndex: 'adhCampaignId' },
                {
                  title: 'サービス名称',
                  dataIndex: 'service',
                  render(value) {
                    return value?.serviceName
                  },
                },
                {
                  title: '開始',
                  dataIndex: 'adhCampaignFrom',
                  render(value) {
                    if (!value.isValid()) return ''
                    return value.format('YYYY/MM/DD HH:mm')
                  },
                },
                {
                  title: '終了',
                  dataIndex: 'adhCampaignTo',
                  render(value) {
                    if (!value.isValid()) return ''
                    return value.format('YYYY/MM/DD HH:mm')
                  },
                },
                { title: '配信メニュー', dataIndex: 'adgroupType' },
                { title: '配信デバイス', dataIndex: 'streamingDevice' },
              ]}
              dataSource={autoAdhInfos}
              pagination={false}
              scroll={{ x: 700 }}
            ></Table>
          </Col>
        </Row>
        <div style={{ margin: '38px 8px 8px 8px', height: '32px', padding: '4px 0px' }}>
          <Title level={5}>広告素材</Title>
        </div>
        <Table
          bordered
          size="small"
          columns={[
            { title: '広告素材名', dataIndex: 'adMaterialName' },
            {
              title: '動画URL',
              dataIndex: 'adMaterialVideoUrl',
              render(value, record) {
                if (!record.adMaterialVideoUrl) return ''
                const link = `https://www.youtube.com/watch?v=${record.adMaterialVideoUrl}`
                return (
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                )
              },
            },
            { title: '動画の秒数', dataIndex: 'movieSeconds' },
            { title: 'キャンペーンID_広告グループ', dataIndex: 'adhCampaignIdAdhGroupId' },
          ]}
          dataSource={adMaterialInfos}
          pagination={false}
          scroll={{ x: 500 }}
        ></Table>

        {/* APIリクエストに使われるForm項目 */}
        <Form.Item name={['digitalPlacementInfo', 'adPlatformId']} hidden />
        <Form.Item name={['digitalPlacementInfo', 'digitalPlacingFrom']} hidden />
        <Form.Item name={['digitalPlacementInfo', 'digitalPlacingTo']} hidden />
        <Form.Item name={['digitalPlacementInfo', 'digitalTargetAttribute']} hidden />
        <Form.Item name={['digitalPlacementInfo', 'importAdhInfos']} hidden />
      </Card>
    </>
  )
}
