export const CampaignFields = [
  {
    basicInfo: [
      'id',
      'campaignId',
      'campaignName',
      'campaignFrom',
      'campaignTo',
      'isActive',
      'confirmationStatus',
      'clientDepartment',
      'productName',
      'sponsorNameFromCustomer',
      'subAccountId',
      'vcid',
      {
        client: [
          'id',
          'clientName',
          'clientCd',
          'isActive',
          'subAccountId',
          'vcid',
          {
            clientLicenses: [
              {
                operations: ['operationKey', 'operationName'],
                sponsor: ['id', 'isActive', 'sponsorCd', 'sponsorName'],
              },
            ],
          },
        ],
      },
      'googleAdsAccountIds',
      {
        salesDepartment: ['departmentName', 'id'],
      },
      {
        sponsor: ['id', 'isActive', 'sponsorCd', 'sponsorName'],
      },
    ],
    tvCmInfo: [
      'cmMaterialNumber',
      'tvCmFrom',
      'tvCmTo',
      'tvTargetAttribute',
      {
        cmMaterials: [
          {
            brand: [
              'id',
              'brandName',
              'brandCd',
              'isActive',
              { sponsor: ['id', 'isActive', 'sponsorCd', 'sponsorName'] },
            ],
            adAreas: ['adAreaName', 'id', 'isActive'],
          },
          'cmMaterialCd',
          'cmMaterialDetail',
          'cmMaterialFirstPlacing',
          'cmMaterialFrom',
          'cmMaterialName',
          'cmMaterialTo',
          'cmSeconds',
          'hasAttachment',
          'id',
          'cmMaterialEditName',
        ],
        tvBroadcastWave: ['id', 'isActive', 'tvBroadcastWaveName'],
      },
    ],
    digitalPlacementInfo: [
      'digitalPlacingFrom',
      'digitalPlacingTo',
      'digitalTargetAttribute',
      {
        importAdhInfos: [
          'id',
          'importAdhId',
          'adAreaType',
          {
            service: ['id', 'isActive', 'serviceName'],
          },
        ],
      },
      { adPlatform: ['adPlatformName', 'id', 'isActive'] },
      {
        autoAdhInfos: [
          'adhCampaignFrom',
          'adhCampaignTo',
          'adhCampaignId',
          'id',
          { service: ['id', 'isActive', 'serviceName'] },
          'streamingDevice',
          'adgroupType',
        ],
      },
      {
        adMaterialInfos: ['id', 'adMaterialName', 'adMaterialVideoUrl', 'movieSeconds', 'adhCampaignIdAdhGroupId'],
      },
    ],
  },
  'isIndexErrorOccurred',
  'isEstimateExecuted',
  'isIndexDisplayable',
]

export const importCustomerInputFileFields = [
  'campaignName',
  'campaignFrom',
  'campaignTo',
  'clientDepartment',
  'productName',
  'sponsorNameFromCustomer',
  'clientId',
  {
    tvCm: [
      'cmMaterialNumber',
      'tvCmFrom',
      'tvCmTo',
      'tvTargetAttribute',
      'tvBroadcastWaveId',
      {
        cmMaterials: ['cmMaterialDetail', 'cmMaterialFirstPlacing', 'cmMaterialName', 'cmMaterialId', 'cmSeconds'],
      },
    ],
  },
  {
    digitalPlacement: [
      'adPlatformId',
      'adPlatformName',
      'digitalPlacingFrom',
      'digitalPlacingTo',
      'digitalTargetAttribute',
      {
        adhs: ['id', 'adhImportId', 'adAreaType', 'serviceId', 'serviceName'],
      },
    ],
  },
]
