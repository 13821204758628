import { ListButton, Create, Form, Input, useForm } from '@pankod/refine-antd'
import { usePermissions, IResourceComponentsProps, useList } from '@pankod/refine-core'
import { IClient } from 'interfaces'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { required, maxLength, pattern, frontBlankTrim, rearBlankTrim } from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const ClientCreate: React.FC<IResourceComponentsProps> = () => {
  /**
   * サーバサイドから取得したclient.clientCdを重複チェック用の変数に変換したかの判定有無に利用
   * 低スペックマシン対策で、変換が終わるまでローディング状態にする
   */
  const [isLoadedClientCd, setIsLoadedClientCd] = useState(false)
  const [duplicatedClientCd, setDuplicatedClientCd] = useState(false)
  const [existsClientCd, setExistsClientCd] = useState<string[]>([])
  const { form, formProps, saveButtonProps } = useForm<IClient>()
  const { data: permissions } = usePermissions()
  const clientQueryResult = useList<IClient>({
    resource: 'clients',
    metaData: {
      fields: ['clientCd'],
      pageSize: 9999999,
    },
  })
  const clients = clientQueryResult.data?.data

  useEffect(() => {
    setIsLoadedClientCd(false)
    if (!clients) return
    setExistsClientCd(clients.map((client) => client.clientCd.toLowerCase()))
    setIsLoadedClientCd(true)
  }, [clients])

  if (!permissions?.includes('CLIENT')) {
    return <ErrorComponent />
  }

  const onClickSave = () => {
    if (duplicatedClientCd) {
      return
    }
    form?.submit()
  }

  return (
    <>
      <Helmet>
        <title>{`依頼主 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`依頼主 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={{ ...saveButtonProps, onClick: onClickSave }}
        pageHeaderProps={{
          onBack: () => {
            window.history.length === 1 ? window.close() : window.history.back()
          },
          extra: <ListButton data-testid="show-list-button" resourceName="clients" />,
        }}
        isLoading={clientQueryResult?.isFetching || !isLoadedClientCd}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 2, xl: 3, lg: 4, md: 4, sm: 4 }}
          wrapperCol={{ xxl: 8, xl: 10, lg: 20, md: 20, sm: 20 }}
          colon={false}
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item
            label="依頼主コード"
            name="clientCd"
            validateStatus={duplicatedClientCd ? 'error' : 'success'}
            help={duplicatedClientCd ? <span style={{ color: 'red' }}>この依頼主コードは登録済です</span> : null}
            rules={[
              required(),
              pattern(/^[a-zA-Z0-9]{6}$/, '6文字の半角英数字で入力してください'),
              frontBlankTrim(),
              rearBlankTrim(),
            ]}
          >
            <Input
              maxLength={6}
              placeholder="依頼主コード"
              onChange={(e: any) => {
                if (e.target.value !== '' && existsClientCd?.includes(e.target.value.toLowerCase())) {
                  setDuplicatedClientCd(true)
                } else {
                  setDuplicatedClientCd(false)
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="依頼主"
            name="clientName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder="依頼主" maxLength={100} showCount />
          </Form.Item>

          <Form.Item name="isActive" hidden></Form.Item>
        </Form>
      </Create>
    </>
  )
}
