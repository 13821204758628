import { Edit, Form, Input, ListButton, Switch, useForm } from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useNavigation,
  useNotification,
  usePermissions,
  useRouterContext,
} from '@pankod/refine-core'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { IInformation } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  frontBlankTrim,
  maxLength,
  oneCharacterRequired,
  rearBlankTrim,
  required,
  validatePasteValueLength,
} from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

const { RangePicker } = DateTimePicker

export const InformationEdit: React.FC<IResourceComponentsProps> = () => {
  const { open } = useNotification()
  const { list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  const { formProps, saveButtonProps, queryResult } = useForm<IInformation>({
    metaData: {
      fields: [
        'id',
        'title',
        'content',
        'publishStartAt',
        'publishEndAt',
        'isMaintenanceMode',
        'maintenanceStartAt',
        'maintenanceEndAt',
      ],
    },
  })

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('informations')
  const record = queryResult?.data?.data

  const [isMaintenanceMode, setMaintenanceMode] = useState(record?.isMaintenanceMode)
  const [publishRangeInput, setPublishRangeInput] = useState<[Dayjs, Dayjs]>()
  const [maintenanceRangeInput, setMaintenanceRangeInput] = useState<[Dayjs, Dayjs]>()

  useEffect(() => {
    setMaintenanceMode(record?.isMaintenanceMode)
    setPublishRangeInput([dayjs(record?.publishStartAt), dayjs(record?.publishEndAt)])
    if (record?.isMaintenanceMode) {
      setMaintenanceRangeInput([dayjs(record?.maintenanceStartAt), dayjs(record?.maintenanceEndAt)])
    }
  }, [record])

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('INFORMATION') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  const { form } = formProps
  const onClick = () => {
    if (!publishRangeInput || (isMaintenanceMode && !maintenanceRangeInput)) {
      open({
        message: 'ページをリロードして入力し直してください',
        description: 'フォームエラー',
        key: 'error',
        type: 'error',
      })
      return
    }

    if (document.getElementById('validationError') != null) {
      open({
        message: '必須項目が未入力または入力に誤りがあります',
        key: 'validateError',
        type: 'error',
      })
      return
    }

    form?.setFieldsValue({
      publishStartAt: publishRangeInput[0].startOf('m'),
      publishEndAt: publishRangeInput[1].startOf('m'),
    })

    if (isMaintenanceMode && maintenanceRangeInput) {
      form?.setFieldsValue({
        maintenanceStartAt: maintenanceRangeInput[0].startOf('m'),
        maintenanceEndAt: maintenanceRangeInput[1].startOf('m'),
        isMaintenanceMode: true,
      })
    } else {
      form?.setFieldsValue({
        maintenanceStartAt: null,
        maintenanceEndAt: null,
        isMaintenanceMode: false,
      })
    }
    form?.submit()
  }

  return (
    <>
      <Helmet>
        <title>お知らせ | {process.env.REACT_APP_SERVICE_NAME}</title>
        <meta name="description" content={`お知らせ | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={{ ...saveButtonProps, onClick: onClick }}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="informations" />,
        }}
      >
        <Form
          colon={false}
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 18, xl: 18, lg: 18, md: 18, sm: 18 }}
        >
          <Form.Item
            label="見出し"
            name="title"
            rules={[required(), maxLength(200), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={200} showCount placeholder="見出し" />
          </Form.Item>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 1000)}>
            <Form.Item label="内容" name="content" rules={[required(), maxLength(1000), oneCharacterRequired()]}>
              <Input.TextArea rows={10} showCount maxLength={1000} placeholder="内容" />
            </Form.Item>
          </div>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label="掲載日時" required>
              <RangePicker
                locale={locale}
                format="YYYY/MM/DD HH:mm"
                allowClear={false}
                placeholder={['開始日時', '終了日時']}
                showTime={{ defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                value={publishRangeInput}
                ranges={{ 現在時刻: [dayjs(), dayjs()] }}
                onChange={(value: any) => setPublishRangeInput(value)}
              />
            </Form.Item>
          </div>
          <Form.Item name="publishStartAt" hidden></Form.Item>
          <Form.Item name="publishEndAt" hidden></Form.Item>
          <div onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}>
            <Form.Item label="メンテナンス日時" help={'メンテナンス不要の場合は入力しないでください'}>
              <RangePicker
                locale={locale}
                format="YYYY/MM/DD HH:mm"
                allowClear
                placeholder={['開始日時', '終了日時']}
                showTime={{ defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')] }}
                value={maintenanceRangeInput}
                ranges={{ 現在時刻: [dayjs(), dayjs()] }}
                onChange={(value: any) => {
                  setMaintenanceRangeInput(value)
                  if (value) setMaintenanceMode(true)
                  else setMaintenanceMode(false)
                }}
              />
            </Form.Item>
          </div>
          <Form.Item name="maintenanceStartAt" hidden></Form.Item>
          <Form.Item name="maintenanceEndAt" hidden></Form.Item>
          <Form.Item name="isMaintenanceMode" hidden>
            <Switch checked={isMaintenanceMode} />
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}
