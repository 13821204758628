import { Checkbox, Table } from '@pankod/refine-antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import React from 'react'
import { ClientLicense, LicenseNS, Licenses, ClientLicenseTableData } from 'types'

type ClientLicenseMatrixProps = {
  checkedLicenses: ClientLicense[] | undefined
  updateCheckedLicenses: (licenses: ClientLicense[]) => void
  checkedAllRows: Licenses
  updateCheckedAllRows: (rows: Licenses) => void
  tableData: ClientLicenseTableData[]
}

export const ClientLicenseMatrix: React.FC<ClientLicenseMatrixProps> = (props) => {
  const { checkedLicenses, updateCheckedLicenses, checkedAllRows, updateCheckedAllRows, tableData } = props

  /**
   * 権限マトリクスのチェックボックスが押下された際にステートを更新する
   * @param checked チェック後の真偽値
   * @param sponsorId チェック対象の広告主ID
   * @param licenseType チェック対象の権限
   */
  const onChangeLicense = (checked: boolean, sponsorId: string, licenseType: keyof Licenses) => {
    const newLicenses = checkedLicenses
      ?.filter((checkedLicense) => checkedLicense)
      ?.map((record: ClientLicense) => {
        let new_record: ClientLicense = JSON.parse(JSON.stringify(record))
        if (record.sponsorId === sponsorId) {
          if (checked) {
            if (licenseType === LicenseNS.ALL) {
              // 全てにチェック
              new_record.licenses = {
                ALL: true,
                CAMPAIGN_SUMMARY: true,
                CAMPAIGN_ATTRIBUTE_COMPARATION: true,
                CAMPAIGN_CROSS_FREQUENCY: true,
                CAMPAIGN_COMPARATION: true,
                YOUTUBE_ATTRIBUTE_COMPARATION: true,
              }
            } else {
              // 権限いずれかにチェック
              new_record.licenses[licenseType] = true
            }
          } else {
            if (licenseType === LicenseNS.ALL) {
              // 全てのチェック解除（権限に対する「全て」がチェックされている場合はfalseにしない）
              new_record.licenses = {
                ALL: false,
                CAMPAIGN_SUMMARY: checkedAllRows[LicenseNS.CAMPAIGN_SUMMARY],
                CAMPAIGN_ATTRIBUTE_COMPARATION: checkedAllRows[LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION],
                CAMPAIGN_CROSS_FREQUENCY: checkedAllRows[LicenseNS.CAMPAIGN_CROSS_FREQUENCY],
                CAMPAIGN_COMPARATION: checkedAllRows[LicenseNS.CAMPAIGN_COMPARATION],
                YOUTUBE_ATTRIBUTE_COMPARATION: checkedAllRows[LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION],
              }
            } else {
              // 権限いずれかのチェック解除
              new_record.licenses[licenseType] = false
            }
          }
        }
        return new_record
      })
    if (newLicenses) updateCheckedLicenses(newLicenses)
  }

  /**
   * 権限マトリクスの列（権限）に対する「全て」チェックボックスが押下された際にステートを更新する
   * @param checked チェック後の真偽値
   * @param licenseType チェック対象の権限
   */
  const onChangeAllRows = (checked: boolean, licenseType: keyof Licenses) => {
    // 対象権限の「全て」のステートを更新
    const newCheckedAllRows = JSON.parse(JSON.stringify(checkedAllRows))
    newCheckedAllRows[licenseType] = checked
    updateCheckedAllRows(newCheckedAllRows)

    // 対象権限の各行のステートを更新
    const newLicenses = checkedLicenses?.map((record: ClientLicense) => {
      let new_record: ClientLicense = record
      if (checked) {
        if (licenseType === LicenseNS.ALL) {
          // 広告主×権限全てにチェック
          updateCheckedAllRows({
            ALL: true,
            CAMPAIGN_SUMMARY: true,
            CAMPAIGN_ATTRIBUTE_COMPARATION: true,
            CAMPAIGN_CROSS_FREQUENCY: true,
            CAMPAIGN_COMPARATION: true,
            YOUTUBE_ATTRIBUTE_COMPARATION: true,
          })
          new_record.licenses = {
            ALL: true,
            CAMPAIGN_SUMMARY: true,
            CAMPAIGN_ATTRIBUTE_COMPARATION: true,
            CAMPAIGN_CROSS_FREQUENCY: true,
            CAMPAIGN_COMPARATION: true,
            YOUTUBE_ATTRIBUTE_COMPARATION: true,
          }
        } else {
          // 権限いずれかにチェック
          new_record.licenses[licenseType] = true
        }
      } else {
        if (licenseType === LicenseNS.ALL) {
          // 全てのチェック解除
          updateCheckedAllRows({
            ALL: false,
            CAMPAIGN_SUMMARY: false,
            CAMPAIGN_ATTRIBUTE_COMPARATION: false,
            CAMPAIGN_CROSS_FREQUENCY: false,
            CAMPAIGN_COMPARATION: false,
            YOUTUBE_ATTRIBUTE_COMPARATION: false,
          })
          new_record.licenses = {
            ALL: false,
            CAMPAIGN_SUMMARY: false,
            CAMPAIGN_ATTRIBUTE_COMPARATION: false,
            CAMPAIGN_CROSS_FREQUENCY: false,
            CAMPAIGN_COMPARATION: false,
            YOUTUBE_ATTRIBUTE_COMPARATION: false,
          }
        } else {
          // 権限いずれかのチェック解除（広告主に対する「全て」がチェックされている場合はfalseにしない）
          const allColumnChecked = checkedLicenses.filter((r) => r.sponsorId === record.sponsorId)[0].licenses.ALL
          if (!allColumnChecked) {
            new_record.licenses[licenseType] = false
          }
        }
      }
      return new_record
    })
    if (newLicenses) updateCheckedLicenses(newLicenses)
  }

  const isChecked = (sponsorId: string, licenseType: keyof Licenses) => {
    const filtered = checkedLicenses?.filter((record) => record && record.sponsorId === sponsorId)
    if (filtered) return filtered[0].licenses[licenseType]
    return false
  }

  const isDisabledRow = (sponsorId: string, licenseType: keyof Licenses) => {
    const filtered = checkedLicenses?.filter((record) => record && record.sponsorId === sponsorId)
    if (!filtered) return false
    const allColumnChecked = filtered[0].licenses[LicenseNS.ALL]
    const allRowChecked = checkedAllRows[licenseType]
    return allColumnChecked || allRowChecked
  }

  const columns = [
    {
      title: '広告主',
      dataIndex: 'sponsorId',
      key: 'sponsorId',
      render: (_: any, record: { sponsorName: string }) => {
        return <b>{record.sponsorName}</b>
      },
    },
    {
      title: '集計メニュー',
      children: [
        {
          title: (
            <Checkbox
              onChange={(event: CheckboxChangeEvent) => {
                onChangeAllRows(event.target.checked, LicenseNS.ALL)
              }}
            >
              全て
            </Checkbox>
          ),
          dataIndex: LicenseNS.ALL,
          key: LicenseNS.ALL,
          render: (_: any, record: { key: React.Key; sponsorId: string }) => (
            <Checkbox
              key={record.key}
              disabled={checkedAllRows[LicenseNS.ALL]}
              checked={isChecked(record.sponsorId, LicenseNS.ALL)}
              onChange={(event: CheckboxChangeEvent) => {
                onChangeLicense(event.target.checked, record.sponsorId, LicenseNS.ALL)
              }}
            >
              全て
            </Checkbox>
          ),
        },
        {
          title: (
            <Checkbox
              disabled={checkedAllRows[LicenseNS.ALL]}
              checked={checkedAllRows[LicenseNS.ALL] || checkedAllRows[LicenseNS.CAMPAIGN_SUMMARY]}
              onChange={(event: CheckboxChangeEvent) => {
                onChangeAllRows(event.target.checked, LicenseNS.CAMPAIGN_SUMMARY)
              }}
            >
              全て
            </Checkbox>
          ),
          dataIndex: LicenseNS.CAMPAIGN_SUMMARY,
          key: LicenseNS.CAMPAIGN_SUMMARY,
          render: (_: any, record: { key: React.Key; sponsorId: string }) => (
            <Checkbox
              disabled={isDisabledRow(record.sponsorId, LicenseNS.CAMPAIGN_SUMMARY)}
              checked={isChecked(record.sponsorId, LicenseNS.CAMPAIGN_SUMMARY)}
              key={record.key}
              onChange={(event: CheckboxChangeEvent) =>
                onChangeLicense(event.target.checked, record.sponsorId, LicenseNS.CAMPAIGN_SUMMARY)
              }
            >
              キャンペーンサマリ
            </Checkbox>
          ),
        },
        {
          title: (
            <Checkbox
              disabled={checkedAllRows[LicenseNS.ALL]}
              checked={checkedAllRows[LicenseNS.ALL] || checkedAllRows[LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION]}
              onChange={(event: CheckboxChangeEvent) => {
                onChangeAllRows(event.target.checked, LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION)
              }}
            >
              全て
            </Checkbox>
          ),
          dataIndex: LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION,
          key: LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION,
          render: (_: any, record: { key: React.Key; sponsorId: string }) => (
            <Checkbox
              disabled={isDisabledRow(record.sponsorId, LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION)}
              checked={isChecked(record.sponsorId, LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION)}
              key={record.key}
              onChange={(event: CheckboxChangeEvent) =>
                onChangeLicense(event.target.checked, record.sponsorId, LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION)
              }
            >
              属性比較
            </Checkbox>
          ),
        },
        {
          title: (
            <Checkbox
              disabled={checkedAllRows[LicenseNS.ALL]}
              checked={checkedAllRows[LicenseNS.ALL] || checkedAllRows[LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION]}
              onChange={(event: CheckboxChangeEvent) => {
                onChangeAllRows(event.target.checked, LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION)
              }}
            >
              全て
            </Checkbox>
          ),
          dataIndex: LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION,
          key: LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION,
          render: (_: any, record: { key: React.Key; sponsorId: string }) => (
            <Checkbox
              disabled={isDisabledRow(record.sponsorId, LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION)}
              checked={isChecked(record.sponsorId, LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION)}
              key={record.key}
              onChange={(event: CheckboxChangeEvent) =>
                onChangeLicense(event.target.checked, record.sponsorId, LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION)
              }
            >
              YouTube属性比較
            </Checkbox>
          ),
        },
        {
          title: (
            <Checkbox
              disabled={checkedAllRows[LicenseNS.ALL]}
              checked={checkedAllRows[LicenseNS.ALL] || checkedAllRows[LicenseNS.CAMPAIGN_CROSS_FREQUENCY]}
              onChange={(event: CheckboxChangeEvent) => {
                onChangeAllRows(event.target.checked, LicenseNS.CAMPAIGN_CROSS_FREQUENCY)
              }}
            >
              全て
            </Checkbox>
          ),
          dataIndex: LicenseNS.CAMPAIGN_CROSS_FREQUENCY,
          key: LicenseNS.CAMPAIGN_CROSS_FREQUENCY,
          render: (_: any, record: { key: React.Key; sponsorId: string }) => (
            <Checkbox
              disabled={isDisabledRow(record.sponsorId, LicenseNS.CAMPAIGN_CROSS_FREQUENCY)}
              checked={isChecked(record.sponsorId, LicenseNS.CAMPAIGN_CROSS_FREQUENCY)}
              key={record.key}
              onChange={(event: CheckboxChangeEvent) =>
                onChangeLicense(event.target.checked, record.sponsorId, LicenseNS.CAMPAIGN_CROSS_FREQUENCY)
              }
            >
              クロスフリークエンシー
            </Checkbox>
          ),
        },
        {
          title: (
            <Checkbox
              disabled={checkedAllRows[LicenseNS.ALL]}
              checked={checkedAllRows[LicenseNS.ALL] || checkedAllRows[LicenseNS.CAMPAIGN_COMPARATION]}
              onChange={(event: CheckboxChangeEvent) => {
                onChangeAllRows(event.target.checked, LicenseNS.CAMPAIGN_COMPARATION)
              }}
            >
              全て
            </Checkbox>
          ),
          dataIndex: LicenseNS.CAMPAIGN_COMPARATION,
          key: LicenseNS.CAMPAIGN_COMPARATION,
          render: (_: any, record: { key: React.Key; sponsorId: string }) => (
            <Checkbox
              disabled={isDisabledRow(record.sponsorId, LicenseNS.CAMPAIGN_COMPARATION)}
              checked={isChecked(record.sponsorId, LicenseNS.CAMPAIGN_COMPARATION)}
              key={record.key}
              onChange={(event: CheckboxChangeEvent) =>
                onChangeLicense(event.target.checked, record.sponsorId, LicenseNS.CAMPAIGN_COMPARATION)
              }
            >
              キャンペーン比較
            </Checkbox>
          ),
        },
      ],
    },
  ]

  return (
    <>
      <p style={{ marginTop: '5px' }}>広告主ごとに利用可能とする集計メニュー種別を設定してください</p>
      <Table bordered pagination={false} size="small" columns={columns} dataSource={tableData} />
    </>
  )
}
