import {
  Button,
  Col,
  Form,
  FormProps,
  Input,
  List,
  Row,
  Select,
  Space,
  Table,
  Typography,
  useSelect,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, HttpError, usePermissions } from '@pankod/refine-core'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import { ErrorComponent } from 'components/404/ErrorComponent'
import { Conditions } from 'components/download'
import { DateTimePicker } from 'components/parts/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import { ICampaign, ICampaignFilterVariables, ISponsor } from 'interfaces'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { getFilterToDateForDownload, validatePasteValueLength } from 'utils'
import './tableStyles.css'

const { Title, Text } = Typography
const { RangePicker } = DateTimePicker

export const CampaignDownload: React.FC = () => {
  const [rangeDateInput, setRangeDateInput] = useState<[Dayjs, Dayjs]>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const {
    tableProps,
    searchFormProps,
    filters,
    tableQueryResult: campaignQueryResult,
  } = useTable<ICampaign, HttpError, ICampaignFilterVariables>({
    resource: 'campaigns',
    initialSorter: [
      {
        field: 'campaignId',
        order: 'asc',
      },
    ],
    permanentFilter: [
      {
        field: 'campaign.isActive',
        operator: 'eq',
        value: 'true',
      },
      {
        field: 'campaign.confirmationStatus',
        operator: 'eq',
        value: '4',
      },
      {
        field: 'campaign.isIndexDisplayable',
        operator: 'eq',
        value: 'true',
      },
    ],
    metaData: {
      pageSize: 9999999,
      fields: [
        {
          basicInfo: [
            'id',
            'campaignId',
            'campaignName',
            'campaignFrom',
            'campaignTo',
            'confirmationStatus',
            'isActive',
            {
              client: ['id', 'clientName'],
            },
            {
              sponsor: ['id', 'sponsorName'],
            },
          ],
          tvCmInfo: [
            'cost',
            {
              cmMaterials: [
                {
                  brand: ['id', 'brandName'],
                },
                'cmMaterialEditName',
              ],
            },
          ],
          digitalPlacementInfo: ['cost'],
        },
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { sponsor, productName } = params

      filters.push({
        field: 'campaign.campaignFrom',
        operator: 'gte',
        value: rangeDateInput ? rangeDateInput[0]?.startOf('d').format('YYYY-MM-DDTHH:mm:ss') : null,
      })

      filters.push({
        field: 'campaign.campaignTo',
        operator: 'lte',
        value: rangeDateInput
          ? getFilterToDateForDownload(rangeDateInput[1]?.startOf('d'))
          : getFilterToDateForDownload(),
      })

      filters.push({
        field: 'sponsor.sponsorId',
        operator: 'eq',
        value: sponsor ?? null,
      })

      filters.push({
        field: 'campaign.productName',
        operator: 'contains',
        value: productName === '' ? null : productName,
      })

      return filters
    },
  })

  const { data: permissions } = usePermissions()
  if (!permissions?.includes('CAMPAIGN_DOWNLOAD')) {
    return <ErrorComponent />
  }

  return (
    <>
      <Helmet>
        <title>{`ダウンロード | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`ダウンロード | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Title level={4}>ダウンロード</Title>

        <List title={false}>
          <Title level={5}>キャンペーン選択</Title>
          <CampaignFilter
            formProps={searchFormProps}
            filters={filters || []}
            dateInput={{ rangeDateInput: rangeDateInput, setRangeDateInput: setRangeDateInput }}
            length={campaignQueryResult.data?.data?.length ?? 0}
          />

          <Space direction="vertical" style={{ display: 'flex' }}>
            <Table
              {...tableProps}
              bordered
              size="small"
              scroll={{ x: true, y: '60vh' }}
              pagination={false}
              className="download-table"
              rowKey={(row: any) => row.basicInfo.id}
              rowSelection={{
                onChange: (selectedRowKeys: React.Key[]) => setSelectedRowKeys(selectedRowKeys),
              }}
            >
              <Table.Column dataIndex={['basicInfo', 'campaignName']} title="キャンペーン名" ellipsis />
              <Table.Column
                dataIndex={['basicInfo', 'campaignFrom']}
                title="開始日"
                ellipsis
                render={(_, record: ICampaign) => {
                  return record?.basicInfo?.campaignFrom
                    ? dayjs(record?.basicInfo?.campaignFrom).format('YYYY/MM/DD')
                    : ''
                }}
              />
              <Table.Column
                dataIndex={['basicInfo', 'campaignTo']}
                title="終了日"
                ellipsis
                render={(_, record: ICampaign) => {
                  return record?.basicInfo?.campaignTo ? dayjs(record?.basicInfo?.campaignTo).format('YYYY/MM/DD') : ''
                }}
              />
              <Table.Column
                dataIndex={['basicInfo', 'campaignFrom']}
                title="費用"
                ellipsis
                align="right"
                render={(_, record: ICampaign) => {
                  const tvCost = record?.tvCmInfo?.cost ? parseInt(record?.tvCmInfo?.cost) : 0
                  const digitalCost = record?.digitalPlacementInfo?.cost
                    ? parseInt(record?.digitalPlacementInfo?.cost)
                    : 0
                  const totalCost = tvCost + digitalCost
                  return `￥${totalCost.toLocaleString()}`
                }}
              />
              <Table.Column
                dataIndex={['basicInfo', 'sponsor', 'sponsorName']}
                title="TV素材名"
                ellipsis
                render={(_, record: ICampaign) => {
                  const materialNames = record.tvCmInfo?.cmMaterials
                    .map((cmMaterial) => cmMaterial.cmMaterialEditName)
                    .sort()
                    .join(', ')
                  return materialNames
                }}
              />
            </Table>
          </Space>
        </List>
        <Conditions selectedCampaignIds={selectedRowKeys as string[]} />
      </Space>
    </>
  )
}

const CampaignFilter: React.FC<{
  formProps: FormProps
  filters: CrudFilters
  dateInput: { rangeDateInput: [Dayjs, Dayjs] | undefined; setRangeDateInput: (d: [Dayjs, Dayjs]) => void }
  length: number
}> = (props) => {
  const { formProps, dateInput, length } = props
  const { rangeDateInput, setRangeDateInput } = dateInput

  const { selectProps: sponsorSelectProps } = useSelect<ISponsor>({
    resource: 'sponsors',
    optionLabel: 'sponsorName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'sponsorName'],
      pageSize: 9999999,
    },
  })

  return (
    <>
      <Form {...formProps} size="small">
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <div
              data-testId={'test-filterPaste'}
              onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}
            >
              <Text strong={true}>広告主</Text>
              <Form.Item name="sponsor" colon={false} style={{ marginTop: '2px', marginBottom: '14px' }}>
                <Select
                  {...sponsorSelectProps}
                  showArrow
                  allowClear
                  onSearch={undefined}
                  filterOption={true}
                  optionFilterProp="label"
                  mode="multiple"
                  placeholder={'部分一致/選択'}
                ></Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={6}>
            <Text strong={true}>商品名</Text>
            <Form.Item name="productName" colon={false} style={{ marginTop: '2px', marginBottom: '14px' }}>
              <Input maxLength={100} placeholder={'部分一致'} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <div
              data-testId={'test-filterPaste'}
              onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 30)}
            >
              <Text strong={true}>期間</Text>
              <Form.Item name="campaignFromTo" colon={false} style={{ marginTop: '2px', marginBottom: '14px' }}>
                <RangePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format="YYYY/MM/DD"
                  allowClear
                  allowEmpty={[true, true]}
                  placeholder={['開始日付', '終了日付']}
                  value={rangeDateInput}
                  onChange={(value: any) => {
                    setRangeDateInput(value)
                  }}
                  size="small"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Form.Item style={{ marginTop: '23px', marginBottom: '16px' }}>
                <Button htmlType="submit" type="primary">
                  {'絞り込み'}
                </Button>
              </Form.Item>
              <span>検索結果 {length} 件</span>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  )
}
