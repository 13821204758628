export const makeToastProps = (message: any, description: any, key: any, type: any) => {
  // @todo Graphqlからエラーレスポンスを受け取った場合、messageに以下の文字列が保持されている
  //       そのためdescriptionに保持されている文字列を表示対象とする。
  //       message: Error (status code: undefined)
  if (type === 'error' && message.includes('Error (status code:')) return [key, description]
  const isMessage = message && message !== 'notifications.error'
  return [
    `${isMessage ? message : ''}${description ? description : ''}`,
    <div>
      {isMessage && <span>{message}</span>}
      {isMessage && description && <br />}
      {description && <span>{description}</span>}
    </div>,
  ]
}
