import {
  Card,
  DeleteButton,
  EditButton,
  Form,
  Grid,
  Input,
  InputNumber,
  List,
  Modal,
  Pagination,
  Space,
  Table,
  Typography,
  useTable,
} from '@pankod/refine-antd'
import { CrudSort, HttpError, useCreate, useUpdate } from '@pankod/refine-core'
import { FormInstance } from 'antd'
import { IFaq } from 'interfaces'
import React, { useEffect, useRef, useState } from 'react'
import {
  convertOrderKey,
  frontBlankTrim,
  is,
  maxLength,
  pattern,
  rearBlankTrim,
  required,
  validatePasteValueLength,
} from 'utils'

const { Text } = Typography

type Props = {
  faqGroupId: string
  faqs: IFaq[]
  refetch: () => void
}

type FaqMutationResult = {
  id: string
}

export const FaqEdit: React.FC<Props> = (props) => {
  const { faqGroupId } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
  const [currentRecord, setCurrentRecord] = useState<IFaq>()
  const refEditModalForm = useRef<FormInstance>(null)
  const refCreateModalForm = useRef<FormInstance>(null)
  const [sortCondition, setSortCondition] = useState({} as CrudSort)
  const { mutate: updateFaqMutate, isSuccess: isUpdateSuccess } = useUpdate<FaqMutationResult>()
  const { mutate: createFaqMutate, isSuccess: isCreateSuccess } = useCreate<FaqMutationResult>()

  useEffect(() => {
    setSortCondition({ field: 'displayOrder', order: 'asc' } as CrudSort)
    tableQueryResult.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      tableQueryResult.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSuccess, isUpdateSuccess])

  const breakpoint = Grid.useBreakpoint()
  const { tableProps, tableQueryResult } = useTable<IFaq, HttpError>({
    resource: 'faqs',
    permanentSorter: [sortCondition],
    metaData: {
      fields: ['id', 'question', 'answer', 'displayOrder'],
      current: currentPage,
      pageSize: 6,
    },
    permanentFilter: [
      {
        field: 'faqGroup.id',
        operator: 'eq',
        value: faqGroupId,
      },
    ],
    syncWithLocation: false,
  })

  const editModalShow = (record: IFaq) => {
    setCurrentRecord(record)
    setIsEditModalVisible(true)
  }

  const handleCancel = () => {
    setIsEditModalVisible(false)
  }

  const handleEditOk = () => {
    if (currentRecord && refEditModalForm.current) {
      const { question, answer, displayOrder } = refEditModalForm.current.getFieldsValue()

      // FIXME:バリデーションチェックを整理
      const errors = refEditModalForm.current.getFieldsError(['question', 'answer', 'displayOrder'])
      const hasError = !!errors.filter((v: any) => v.errors.length > 0).length
      if (hasError) return false

      refEditModalForm.current.validateFields(['question', 'answer', 'displayOrder'])
      if (
        !question ||
        !answer ||
        !question.trim() ||
        !answer.trim() ||
        !displayOrder ||
        !Number.isInteger(displayOrder)
      ) {
        return false
      }
      updateFaqMutate({
        resource: 'faq',
        id: currentRecord.id,
        values: {
          question: question,
          answer: answer,
          displayOrder: displayOrder,
        },
        successNotification: {
          type: 'success',
          message: 'FAQ を更新しました',
        },
        errorNotification: {
          type: 'error',
          message: 'FAQ の更新に失敗しました',
        },
      })
    }
    setIsEditModalVisible(false)
  }

  const handleCreateCancel = () => {
    setIsCreateModalVisible(false)
  }

  const handleCreateOk = () => {
    if (refCreateModalForm.current) {
      const { question, answer, displayOrder } = refCreateModalForm.current.getFieldsValue()

      // FIXME:バリデーションチェックを整理
      const errors = refCreateModalForm.current.getFieldsError(['question', 'answer', 'displayOrder'])
      const hasError = !!errors.filter((v: any) => v.errors.length > 0).length
      if (hasError) return false

      refCreateModalForm.current.validateFields(['question', 'answer', 'displayOrder'])
      if (
        !question ||
        !answer ||
        !question.trim() ||
        !answer.trim() ||
        !displayOrder ||
        !Number.isInteger(displayOrder)
      ) {
        return false
      }

      createFaqMutate({
        resource: 'faq',
        values: {
          question: question,
          answer: answer,
          displayOrder: displayOrder,
          faqGroupId: faqGroupId,
        },
        successNotification: {
          type: 'success',
          message: 'FAQ を作成しました',
        },
        errorNotification: {
          type: 'error',
          message: 'FAQ の作成に失敗しました',
        },
      })
    }
    setIsCreateModalVisible(false)
  }

  const EditModal: React.FC = () => {
    return (
      <Modal
        title="FAQ編集"
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleCancel}
        maskClosable={false}
        width={1000}
        okText="保存"
      >
        <Form layout="horizontal" ref={refEditModalForm} labelCol={{ span: 3 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            label="質問"
            name="question"
            initialValue={currentRecord?.question}
            rules={[required(), maxLength(1000), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={1000} showCount placeholder="質問" />
          </Form.Item>
          <div
            data-testid={'test-paste-div'}
            onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 1000)}
          >
            <Form.Item
              label="回答"
              name="answer"
              initialValue={currentRecord?.answer}
              rules={[required(), maxLength(1000), frontBlankTrim(), rearBlankTrim()]}
            >
              <Input.TextArea rows={10} maxLength={1000} showCount placeholder="回答" />
            </Form.Item>
          </div>
          <Form.Item
            label="表示順"
            name="displayOrder"
            initialValue={currentRecord?.displayOrder}
            rules={[required(), pattern('^[1-9][0-9]*$')]}
          >
            <InputNumber min={1} max={999999999} maxLength={9} placeholder="表示順" />
          </Form.Item>
          <Form.Item name={'faqGroupId'} hidden></Form.Item>
        </Form>
      </Modal>
    )
  }
  const CreateModal: React.FC = () => {
    return (
      <Modal
        title="FAQ作成"
        visible={isCreateModalVisible}
        onOk={handleCreateOk}
        maskClosable={false}
        okText="保存"
        onCancel={handleCreateCancel}
        width={1000}
      >
        <Form layout="horizontal" ref={refCreateModalForm} labelCol={{ span: 3 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            label="質問"
            name="question"
            rules={[required(), maxLength(1000), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={1000} showCount placeholder="質問" />
          </Form.Item>
          <div
            data-testid={'test-paste-div'}
            onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 1000)}
          >
            <Form.Item
              label="回答"
              name="answer"
              rules={[required(), maxLength(1000), frontBlankTrim(), rearBlankTrim()]}
            >
              <Input.TextArea rows={10} maxLength={1000} showCount placeholder="回答" />
            </Form.Item>
          </div>
          <Form.Item label="表示順" name="displayOrder" rules={[required(), pattern('^[1-9][0-9]*$')]}>
            <InputNumber min={1} max={999999999} maxLength={9} placeholder="表示順" />
          </Form.Item>
          <Form.Item name={'faqGroupId'} hidden></Form.Item>
        </Form>
      </Modal>
    )
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page)
    tableQueryResult.refetch()
  }

  return (
    <>
      <List
        title={'FAQ一覧'}
        createButtonProps={{
          onClick: () => {
            setIsCreateModalVisible(true)
          },
        }}
        pageHeaderProps={{
          style: {
            marginTop: '1em',
          },
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          pagination={false}
          expandable={{
            expandedRowRender: !breakpoint.xs ? expandedRowRender : undefined,
          }}
          onChange={(_pagination, _filters, sorter: any) => {
            let field = ''
            if (is('String', sorter.field)) field = sorter.field
            else field = sorter.field.slice(-1)[0]
            setSortCondition({ field: field, order: convertOrderKey(sorter.order) } as CrudSort)
            tableQueryResult.refetch()
          }}
        >
          <Table.Column
            key="question"
            dataIndex="question"
            title={'質問'}
            sorter
            render={(text) => {
              return (
                <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                  {text}
                </div>
              )
            }}
          />
          <Table.Column key="displayOrder" dataIndex="displayOrder" title={'表示順'} sorter ellipsis />
          <Table.Column<IFaq>
            title="操作"
            dataIndex="actions"
            fixed="right"
            render={(_, record) => (
              <Space>
                <EditButton size="small" resourceName={'faq'} onClick={() => editModalShow(record)} icon={false} />
                <DeleteButton
                  size="small"
                  hideText
                  resourceName={'faq'}
                  recordItemId={record.id}
                  onSuccess={() => window.location.reload()}
                />
              </Space>
            )}
          />
        </Table>
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Pagination
            current={currentPage}
            onChange={onChangePage}
            defaultPageSize={6}
            total={tableQueryResult?.data?.total}
            showSizeChanger={false}
          />
        </div>
      </List>
      <EditModal />
      <CreateModal key={1} />
    </>
  )
}

const expandedRowRender = (record: IFaq) => {
  return (
    <>
      <p>回答</p>
      <Card style={{ backgroundColor: '#fffaf0' }}>
        <Text style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {record?.answer}
        </Text>
      </Card>
    </>
  )
}
