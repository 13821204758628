import React, { useState } from 'react'
import pluralize from 'pluralize'
import { AntdLayout, Menu, useMenu, Grid, Icons } from '@pankod/refine-antd'
import { usePermissions, useNavigation } from '@pankod/refine-core'
import { antLayoutSider, antLayoutSiderMobile } from './styles'
import { toSnakeCase } from 'utils/functions'

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const { data: permissions } = usePermissions()
  const { menuItems, selectedKey } = useMenu()
  const breakpoint = Grid.useBreakpoint()
  const { push } = useNavigation()

  const isMobile = !breakpoint.lg

  return (
    <AntdLayout.Sider
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {/* <Title collapsed={collapsed} /> */}
      <Menu
        data-testId={'test-menu-btn'}
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={({ key }) => {
          if (!breakpoint.lg) {
            setCollapsed(true)
          }
          push(key as string)
        }}
      >
        {menuItems.map(({ name, icon, label, route }) => {
          const isSelected = route === selectedKey
          if (name === 'Dashboard') {
            label = 'ホーム'
            icon = <Icons.HomeOutlined />
          } else if (name === 'faqGroups') {
            label = 'FAQ'
          } else if (name === 'ipAddresses') {
            label = 'IPアドレス管理'
          } else if (name === 'accountUserLicenses') {
            route = '/accountUserLicenses/edit'
          } else if (name === 'campaignDownload') {
            label = 'ダウンロード'
            route = '/campaignDownload'
            icon = <Icons.DownloadOutlined />
          }

          const snake_name = toSnakeCase(name)
          const resource = pluralize.singular(snake_name).toUpperCase()
          if (!['Dashboard'].includes(name) && !permissions?.includes(resource)) {
            return null
          }

          return (
            <Menu.Item
              style={{
                fontWeight: isSelected ? 'bold' : 'normal',
              }}
              key={route}
              icon={icon}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {label}
                {!collapsed && isSelected && <Icons.RightOutlined />}
              </div>
            </Menu.Item>
          )
        })}
      </Menu>
    </AntdLayout.Sider>
  )
}
