import { Button, Edit, Form, Input, ListButton, Radio, Select, useForm, useSelect } from '@pankod/refine-antd'
import { useRouterContext, IResourceComponentsProps, useNavigation, usePermissions } from '@pankod/refine-core'
import { IAccountUser, IClient } from 'interfaces'
import React from 'react'
import { frontBlankTrim, maxLength, rearBlankTrim, required, validatePasteValueLength } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const AccountUserEdit: React.FC<IResourceComponentsProps> = () => {
  const { push, list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()

  const { formProps, saveButtonProps, queryResult } = useForm<IAccountUser>({
    metaData: {
      fields: ['id', 'loginId', { client: ['id'] }, 'department', 'isResponsible', 'isActive'],
    },
  })

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('accountUsers')
  const record = queryResult?.data?.data

  const { selectProps: clientSelectProps } = useSelect<IClient>({
    resource: 'clients',
    optionLabel: 'clientName',
    optionValue: 'id',
    metaData: {
      fields: ['id', 'clientName'],
      pageSize: 9999999,
    },
  })

  const { data: permissions } = usePermissions()
  // URL末尾にリソースIDが無い場合は404リダイレクト
  if (
    !permissions?.includes('ACCOUNT_USER') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  return (
    <>
      <Helmet>
        <title>{`アカウント管理 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`アカウント管理 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          onBack: () => list('accountUsers'),
          extra: (
            <>
              <Button onClick={() => push('edit/password/', { state: { id: record?.id, loginId: record?.loginId } })}>
                パスワード変更
              </Button>
              <ListButton data-testid="show-list-button" resourceName="accountUsers" />
            </>
          ),
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 18 }}
          colon={false}
        >
          <Form.Item label="ログインID" rules={[required(), frontBlankTrim(), rearBlankTrim()]}>
            <Input
              disabled
              value={record?.loginId}
              autoComplete="new-password"
              maxLength={200}
              placeholder="ログインID"
            />
          </Form.Item>
          <div
            data-testid={'test-paste-div'}
            onPaste={(event: React.ClipboardEvent) => validatePasteValueLength(event, 100)}
          >
            <Form.Item
              label={'依頼主'}
              name={'clientId'}
              initialValue={record?.client?.id}
              rules={[required('選択してください')]}
            >
              <Select showArrow {...clientSelectProps} placeholder={'部分一致/選択'}></Select>
            </Form.Item>
          </div>

          <Form.Item
            label="部署"
            name="department"
            initialValue={record?.department}
            rules={[maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder="部署" maxLength={100} showCount />
          </Form.Item>

          <Form.Item name="isResponsible" hidden initialValue={record?.isResponsible}></Form.Item>

          <Form.Item
            label="状態"
            name="isActive"
            initialValue={record?.isActive}
            rules={[required('選択してください')]}
          >
            <Radio.Group>
              <Radio value={true}>{'利用可'}</Radio>
              <Radio value={false}>{'利用停止'}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Edit>
    </>
  )
}
