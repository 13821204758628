import { Edit, Form, Input, ListButton, useForm } from '@pankod/refine-antd'
import { IResourceComponentsProps, useList, useNavigation, usePermissions, useRouterContext } from '@pankod/refine-core'
import { ClientLicenseMatrix } from 'components/form/ClientLicenseMatrix'
import { IClient } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ClientLicense, ClientLicenseTableData, LicenseNS, Licenses } from 'types'
import { frontBlankTrim, maxLength, pattern, rearBlankTrim, required } from 'utils'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const ClientEdit: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation()
  const { useLocation } = useRouterContext()
  const location = useLocation()
  /**
   * サーバサイドから取得したclient.clientCdを重複チェック用の変数に変換したかの判定有無に利用
   * 低スペックマシン対策で、変換が終わるまでローディング状態にする
   */
  const [isLoadedClientCd, setIsLoadedClientCd] = useState(false)
  const [existsClientCd, setExistsClientCd] = useState<string[]>([])
  const [duplicatedClientCd, setDuplicatedClientCd] = useState(false)
  const [checkedLicenses, setCheckedLicenses] = useState<ClientLicense[]>()
  const [tableData, setTableData] = useState<ClientLicenseTableData[]>()
  const [checkedAllRows, setCheckedAllRows] = useState<Licenses>({
    [LicenseNS.ALL]: false,
    [LicenseNS.CAMPAIGN_SUMMARY]: false,
    [LicenseNS.CAMPAIGN_ATTRIBUTE_COMPARATION]: false,
    [LicenseNS.CAMPAIGN_CROSS_FREQUENCY]: false,
    [LicenseNS.CAMPAIGN_COMPARATION]: false,
    [LicenseNS.YOUTUBE_ATTRIBUTE_COMPARATION]: false,
  })
  const updateCheckedAllRows = (checkedRows: Licenses): void => setCheckedAllRows(checkedRows)
  const updateCheckedLicenses = (licenses: ClientLicense[]): void => setCheckedLicenses(licenses)

  const { form, formProps, saveButtonProps, queryResult } = useForm<IClient>({
    metaData: {
      fields: [
        'id',
        'clientName',
        'clientCd',
        { sponsors: ['id', 'sponsorName'] },
        {
          clientLicenses: [{ sponsor: ['id', 'sponsorName'] }, { operations: ['operationKey', 'operationName'] }],
        },
      ],
    },
  })

  const allClientQueryResult = useList<IClient>({
    resource: 'clients',
    config: {
      filters: [{ field: 'id', operator: 'ne', value: 'Q2xpZW50OjE=' }], // 事務局除外
    },
    metaData: {
      fields: ['clientCd'],
      pageSize: 9999999,
    },
  })
  const clients = allClientQueryResult.data?.data

  useEffect(() => {
    setIsLoadedClientCd(false)
    if (!clients) return
    setExistsClientCd(
      clients.filter((client) => client.clientCd !== record?.clientCd).map((client) => client.clientCd.toLowerCase()),
    )
    setIsLoadedClientCd(true)
  }, [clients])

  if (queryResult?.isSuccess && queryResult.data.data.toString() === '') list('clients')
  const record = queryResult?.data?.data

  useEffect(() => {
    if (!record) return

    const sponsorIds = record.sponsors?.map((sponsor) => sponsor.id)
    const uniqueSponsorIds = Array.from(new Set(sponsorIds))

    let targetsForInitStateExists = JSON.parse(JSON.stringify(uniqueSponsorIds))

    // 登録済の権限ステートを作成
    const registeredIds: string[] = []
    const initialLicenseState = record.clientLicenses
      ?.map((license) => {
        const operations = license.operations.map((op) => op.operationKey)
        registeredIds.push(license.sponsor.id)
        if (targetsForInitStateExists.includes(license.sponsor.id)) {
          targetsForInitStateExists = targetsForInitStateExists.filter(
            (sponsorId: string) => sponsorId !== license.sponsor.id,
          )
          return {
            sponsorId: license.sponsor.id,
            licenses: {
              ALL: false,
              CAMPAIGN_SUMMARY: operations.includes('CAMPAIGN_SUMMARY'),
              CAMPAIGN_ATTRIBUTE_COMPARATION: operations.includes('CAMPAIGN_ATTRIBUTE_COMPARATION'),
              CAMPAIGN_CROSS_FREQUENCY: operations.includes('CAMPAIGN_CROSS_FREQUENCY'),
              CAMPAIGN_COMPARATION: operations.includes('CAMPAIGN_COMPARATION'),
              YOUTUBE_ATTRIBUTE_COMPARATION: operations.includes('YOUTUBE_ATTRIBUTE_COMPARATION'),
            },
          } as any
        }
      })
      .filter((item) => item !== undefined)

    let targetsForInitStateNotExists = JSON.parse(JSON.stringify(uniqueSponsorIds))

    // 未登録の権限ステートを追加
    for (const uniqueSponsorId of uniqueSponsorIds) {
      if (!registeredIds.includes(uniqueSponsorId)) {
        if (targetsForInitStateNotExists.includes(uniqueSponsorId)) {
          targetsForInitStateNotExists = targetsForInitStateNotExists.filter(
            (sponsorId: string) => sponsorId !== uniqueSponsorId,
          )
          initialLicenseState.push({
            sponsorId: uniqueSponsorId,
            licenses: {
              ALL: false,
              CAMPAIGN_SUMMARY: false,
              CAMPAIGN_ATTRIBUTE_COMPARATION: false,
              CAMPAIGN_CROSS_FREQUENCY: false,
              CAMPAIGN_COMPARATION: false,
              YOUTUBE_ATTRIBUTE_COMPARATION: false,
            },
          })
        }
      }
    }

    let targetsForExists = JSON.parse(JSON.stringify(uniqueSponsorIds))

    // 登録済の権限をテーブルデータに反映
    let tableData = record.clientLicenses?.map((license) => {
      const operations = license.operations.map((op) => op.operationKey)
      if (targetsForExists.includes(license.sponsor.id)) {
        targetsForExists = targetsForExists.filter((sponsorId: string) => sponsorId !== license.sponsor.id)
        return {
          key: license.sponsor.id,
          sponsorId: license.sponsor.id,
          sponsorName: license.sponsor.sponsorName,
          CAMPAIGN_SUMMARY: operations.includes('CAMPAIGN_SUMMARY'),
          CAMPAIGN_ATTRIBUTE_COMPARATION: operations.includes('CAMPAIGN_ATTRIBUTE_COMPARATION'),
          CAMPAIGN_CROSS_FREQUENCY: operations.includes('CAMPAIGN_CROSS_FREQUENCY'),
          CAMPAIGN_COMPARATION: operations.includes('CAMPAIGN_COMPARATION'),
          YOUTUBE_ATTRIBUTE_COMPARATION: operations.includes('YOUTUBE_ATTRIBUTE_COMPARATION'),
        } as any
      }
    })

    let targetsForNotExists = JSON.parse(JSON.stringify(uniqueSponsorIds))

    // 未登録の権限をテーブルデータに追加
    if (record.sponsors) {
      for (const sponsor of record.sponsors) {
        if (!registeredIds.includes(sponsor.id)) {
          if (targetsForNotExists.includes(sponsor.id)) {
            targetsForNotExists = targetsForNotExists.filter((sponsorId: string) => sponsorId !== sponsor.id)
            tableData.push({
              key: sponsor.id,
              sponsorId: sponsor.id,
              sponsorName: sponsor.sponsorName,
              CAMPAIGN_SUMMARY: false,
              CAMPAIGN_ATTRIBUTE_COMPARATION: false,
              CAMPAIGN_CROSS_FREQUENCY: false,
              CAMPAIGN_COMPARATION: false,
              YOUTUBE_ATTRIBUTE_COMPARATION: false,
            } as ClientLicenseTableData)
          }
        }
      }
    }
    tableData = tableData?.filter((v) => v)

    setTableData(tableData)
    setCheckedLicenses(initialLicenseState)
  }, [record])

  const { data: permissions } = usePermissions()
  if (
    !permissions?.includes('CLIENT') ||
    location.pathname
      .split('/')
      .filter((v: string) => v)
      .slice(-1)[0] === 'edit' ||
    (!queryResult?.isLoading && !record)
  ) {
    return <ErrorComponent />
  }

  const getRequestLicenseParams = () => {
    if (!checkedLicenses) return
    const params = checkedLicenses?.map((clientLicense) => {
      const licenses = []
      for (const [key, value] of Object.entries(clientLicense.licenses)) {
        if (key !== 'ALL' && value) licenses.push(key)
      }
      return {
        sponsorId: clientLicense.sponsorId,
        operationKey: licenses,
      }
    })
    return params
  }

  const onClickSave = () => {
    if (duplicatedClientCd) {
      return
    }
    form.setFieldsValue({
      clientLicenses: getRequestLicenseParams(),
    })
    form.submit()
  }

  return (
    <>
      <Helmet>
        <title>依頼主 | {process.env.REACT_APP_SERVICE_NAME}</title>
        <meta name="description" content={`依頼主 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Edit
        isLoading={queryResult?.isFetching || !isLoadedClientCd}
        saveButtonProps={{ ...saveButtonProps, onClick: onClickSave }}
        pageHeaderProps={{
          extra: <ListButton data-testid="show-list-button" resourceName="clients" />,
        }}
      >
        <Form {...formProps} layout="horizontal" labelCol={{ span: 3 }} colon={false}>
          <Form.Item
            label="依頼主コード"
            name="clientCd"
            wrapperCol={{ xxl: 10, xl: 10, lg: 10, md: 20, sm: 20 }}
            validateStatus={duplicatedClientCd ? 'error' : 'success'}
            help={duplicatedClientCd ? <span style={{ color: 'red' }}>この依頼主コードは登録済です</span> : null}
            rules={[
              required(),
              pattern(/^[a-zA-Z0-9]{6}$/, '6文字の半角英数字で入力してください'),
              frontBlankTrim(),
              rearBlankTrim(),
            ]}
          >
            <Input
              maxLength={6}
              placeholder="依頼主コード"
              onChange={(e: any) => {
                if (e.target.value !== '' && existsClientCd?.includes(e.target.value.toLowerCase())) {
                  setDuplicatedClientCd(true)
                } else {
                  setDuplicatedClientCd(false)
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="依頼主"
            name="clientName"
            wrapperCol={{ xxl: 10, xl: 10, lg: 10, md: 20, sm: 20 }}
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input maxLength={100} showCount placeholder="依頼主" />
          </Form.Item>

          <Form.Item label="権限">
            {checkedAllRows && tableData && (
              <ClientLicenseMatrix
                checkedLicenses={checkedLicenses}
                updateCheckedLicenses={updateCheckedLicenses}
                checkedAllRows={checkedAllRows}
                updateCheckedAllRows={updateCheckedAllRows}
                tableData={tableData}
              />
            )}
          </Form.Item>

          <Form.Item name="clientLicenses" hidden></Form.Item>
        </Form>
      </Edit>
    </>
  )
}
