import { Create, Form, Input, ListButton, useForm } from '@pankod/refine-antd'
import { IResourceComponentsProps, usePermissions, useList } from '@pankod/refine-core'
import { ISponsor } from 'interfaces'
import React, { useState } from 'react'
import { required, pattern, maxLength, frontBlankTrim, rearBlankTrim } from 'utils'
import { Helmet } from 'react-helmet-async'
import { ErrorComponent } from 'components/404/ErrorComponent'

export const SponsorCreate: React.FC<IResourceComponentsProps> = () => {
  const [duplicatedSponsorCd, setDuplicatedSponsorCd] = useState(false)
  const { form, formProps, saveButtonProps } = useForm<ISponsor>()
  const { data: permissions } = usePermissions()

  const sponsorQueryResult = useList<ISponsor>({
    resource: 'sponsors',
    metaData: {
      fields: ['sponsorCd'],
      pageSize: 9999999,
    },
  })
  const existsSponsorCd = sponsorQueryResult.data?.data.map((sponsor) => sponsor.sponsorCd)

  if (!permissions?.includes('SPONSOR')) {
    return <ErrorComponent />
  }

  const onClickSave = () => {
    if (duplicatedSponsorCd) return
    form?.submit()
  }

  return (
    <>
      <Helmet>
        <title>{`広告主 | ${process.env.REACT_APP_SERVICE_NAME}`}</title>
        <meta name="description" content={`広告主 | ${process.env.REACT_APP_SERVICE_NAME}`} />
      </Helmet>
      <Create
        saveButtonProps={{ ...saveButtonProps, onClick: onClickSave }}
        pageHeaderProps={{
          onBack: () => {
            window.history.length === 1 ? window.close() : window.history.back()
          },
          extra: <ListButton data-testid="show-list-button" resourceName="sponsors" />,
        }}
      >
        <Form
          {...formProps}
          layout="horizontal"
          labelCol={{ xxl: 3, xl: 4, lg: 6, md: 6, sm: 6 }}
          wrapperCol={{ xxl: 7, xl: 10, lg: 18, md: 18, sm: 18 }}
          colon={false}
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item
            label="広告主コード"
            name="sponsorCd"
            validateStatus={duplicatedSponsorCd ? 'error' : 'success'}
            help={duplicatedSponsorCd ? <span style={{ color: 'red' }}>この広告主コードは登録済です</span> : null}
            rules={[
              required(),
              pattern(/^[a-zA-Z0-9]{5}$/, '5文字の半角英数字で入力してください'),
              frontBlankTrim(),
              rearBlankTrim(),
            ]}
          >
            <Input
              placeholder="広告主コード"
              maxLength={5}
              onChange={(e: any) => {
                if (e.target.value !== '' && existsSponsorCd?.includes(e.target.value)) {
                  setDuplicatedSponsorCd(true)
                } else {
                  setDuplicatedSponsorCd(false)
                }
              }}
            />
          </Form.Item>

          <Form.Item
            label="広告主"
            name="sponsorName"
            rules={[required(), maxLength(100), frontBlankTrim(), rearBlankTrim()]}
          >
            <Input placeholder="広告主" maxLength={100} showCount />
          </Form.Item>

          <Form.Item name="isActive" hidden></Form.Item>
        </Form>
      </Create>
    </>
  )
}
